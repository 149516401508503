<template>
    <div class="periods-header__container">
        <div class="periods-header__content-left">
            <span class="periods-header__title"> {{ $t("CalendarsPage.Periods") }} </span>
        </div>
        <div class="periods-header__content-right">
            <div class="periods-header__dropdown-container">
                <span>{{ $t("CalendarsPage.Visualization") }}:</span>

                <sys-dropdown
                    :label="String(periods.visualization)"
                    :options="visualizationList"
                    :value="periods.visualization"
                    @input="handleDropdownInput"
                />
            </div>

            <sys-button
                size="xs"
                scheme="square"
                variant="contained"
                @click="UPDATE_MODAL_PERIODS_TOGGLE(true)"
                :full="windowWidth <= 599"
            >
                <add-icon />
                {{ $t("CalendarsPage.NewPeriod") }}
            </sys-button>
        </div>
    </div>
</template>

<script>
    import SysButton from "@/@core/components/sys-button";
    import SysDropdown from "@/@core/components/sys-dropdown";
    import { mapMutations, mapState } from "vuex";

    export default {
        components: {
            SysButton,
            SysDropdown,
            AddIcon: () => import("@/@core/assets/formatted-icons/add-icon.svg")
        },
        computed: {
            ...mapState("calendar", ["calendarSettings", "periods"]),
            ...mapState("app", ["windowWidth"])
        },
        data() {
            return {
                visualizationList: []
            };
        },
        methods: {
            ...mapMutations("calendar", [
                "UPDATE_PERIODS_VISUALIZATION",
                "UPDATE_MODAL_PERIODS_TOGGLE",
                "UPDATE_PERIODS_PAGINATION"
            ]),
            handleCreateVisualizationList(days) {
                this.UPDATE_PERIODS_VISUALIZATION(days > 7 ? 7 : days);

                return Number(days) > 0
                    ? Array.from({ length: days > 7 ? 7 : days }, (_, i) => {
                          const value = i + 1;

                          return {
                              id: value,
                              text: value
                          };
                      })
                    : [];
            },
            handleResetPagination() {
                this.UPDATE_PERIODS_PAGINATION({
                    startIndex: 0,
                    endIndex: this.periods.visualization - 1
                });
            },
            handleDropdownInput(option) {
                this.UPDATE_PERIODS_VISUALIZATION(option.id);
                this.UPDATE_PERIODS_PAGINATION({
                    startIndex: 0,
                    endIndex: option.id - 1
                });
            }
        },
        watch: {
            "calendarSettings.generalApplied.duration": {
                handler(newValue) {
                    this.visualizationList = [...this.handleCreateVisualizationList(newValue)];
                    this.handleResetPagination();
                },
                deep: true,
                immediate: true
            }
        }
    };
</script>

<style lang="scss" scoped>
    .periods-header__container {
        display: flex;
        width: 100%;
        .periods-header__content-left {
            display: flex;
            .periods-header__title {
                color: #4c4541;
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
            }
        }

        .periods-header__content-right {
            display: flex;
            gap: 8px;
            margin-left: auto;
            align-items: center;

            .periods-header__dropdown-container {
                display: flex;
                gap: 4px;
                align-items: center;
            }
        }
    }

    @media (max-width: 599px) {
        .periods-header__container {
            flex-direction: column;

            .periods-header__content-right {
                flex-direction: column;
                margin-left: initial !important;
                align-items: flex-start !important;

                .periods-header__dropdown-container {
                    display: flex;
                    gap: 4px;
                    align-items: center;
                }
            }
        }
    }
</style>
