<template>
    <div id="events-settings">
        <div
            class="block-no-items"
            v-if="eventList.length === 0"
        >
            <div class="no-items h-100">
                <EmptyBoxIcon class="empty-box" />
                <p class="title">{{ $t("CalendarsPage.NoEventTitle") }}</p>
                <p class="subtitle">
                    {{ $t("CalendarsPage.NoEventSubtitle") }}
                </p>
                <b-button
                    class="btn-new"
                    @click="newEvent()"
                >
                    <AddIcon class="add-icon" />
                    {{ $t("CalendarsPage.NewEvent") }}
                </b-button>
            </div>
        </div>
        <div
            v-else
            class="h-100 --has-events"
        >
            <div class="search-input">
                <div class="div-custom-input input__fields">
                    <div class="icon-block">
                        <SearchIcon class="search-icon" />
                    </div>
                    <b-form-tags
                        class="input-search-fields"
                        ref="formTagFields"
                        :value="helpers.eventsFilter.text"
                        duplicate-tag-text=""
                        :placeholder="
                            filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''
                        "
                        @input="(e) => updateFilter(e)"
                        remove-on-delete
                        add-on-change
                    />
                </div>
            </div>
            <div :class="['collapse-months', isModal ? '--is-modal' : '']">
                <div
                    v-for="(month, index) in filteredList"
                    :key="month.id"
                    class="collapse-block"
                >
                    <div @click="toggleCollapse(index)">
                        <div class="collapse-header">
                            <div class="event-name">
                                {{ $t(`CalendarsPage.Months.${month.name}`) }}
                            </div>
                            <div class="event-counter">
                                {{
                                    `${month.events.length} ${$t("CalendarsPage.event")}${month.events.length > 1 ? "s" : ""}`
                                }}
                                <ArrowRightIcon
                                    :class="['arrow-icon', openIndex === index ? '--is-open' : '']"
                                />
                            </div>
                        </div>
                    </div>
                    <b-collapse
                        :id="'collapse-' + index"
                        :visible="openIndex === index"
                    >
                        <div class="collapse-body">
                            <collapse-month :month="month" />
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import EmptyBoxIcon from "@core/assets/icons/empty-box-icon.svg";
    import { BButton, BFormTags, BCollapse, VBToggle } from "bootstrap-vue";
    import AddIcon from "@core/assets/icons/add-icon.svg";
    import SearchIcon from "@core/assets/icons/search-icon.svg";
    import ArrowRightIcon from "@core/assets/icons/arrow-right.svg";
    import CollapseMonth from "./CollapseMonth.vue";
    import Ripple from "vue-ripple-directive";
    import { createYearsContext } from "@/views/pages/calendar/actionCalendar/utils/index.js";

    export default {
        props: {
            eventList: {
                type: Array,
                default: []
            },
            isModal: {
                type: Boolean,
                default: false
            },
            selectedYear: {
                type: Number,
                default: 2025
            }
        },
        components: {
            AddIcon,
            SearchIcon,
            SearchIcon,
            EmptyBoxIcon,
            ArrowRightIcon,
            BButton,
            BFormTags,
            BCollapse,
            VBToggle,
            CollapseMonth
        },
        directives: {
            "b-toggle": VBToggle,
            Ripple
        },
        data() {
            return {
                formattedEventList: [],
                filteredList: [],
                filter: {
                    textFilter: []
                },
                openIndex: null
            };
        },
        mounted() {
            this.filteredList = this.groupByMonth(this.eventList);
        },
        computed: {
            ...mapState("calendar", ["helpers"])
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_EVENTS_TOGGLE",
                "UPDATE_HELPERS_EVENTS_FILTER_TEXT",
                "UPDATE_HELPERS_CONTEXT_YEARS",
                "UPDATE_HELPERS_EVENTS_FILTER_SELECTED_YEAR"
            ]),
            groupByMonth(array) {
                const months = [
                    "january",
                    "february",
                    "march",
                    "april",
                    "may",
                    "june",
                    "july",
                    "august",
                    "september",
                    "october",
                    "november",
                    "december"
                ];

                return array.reduce((acc, item) => {
                    const date = new Date(item.date);
                    const monthIndex = date.getMonth();
                    const monthKey = months[monthIndex];
                    const monthName = monthKey.charAt(0) + monthKey.slice(1);

                    if (!acc[monthKey]) {
                        acc[monthKey] = {
                            name: monthName,
                            events: []
                        };
                    }
                    acc[monthKey].events.push(item);
                    return acc;
                }, {});
            },
            toggleCollapse(index) {
                this.openIndex = this.openIndex === index ? null : index;
            },
            newEvent() {
                this.UPDATE_MODAL_EVENTS_TOGGLE(true);
            },
            filterEvents(formattedEventList, filter) {
                let result = {};

                for (let month in formattedEventList) {
                    const monthObj = formattedEventList[month];

                    const filteredEvents = monthObj.events.filter((event) => {
                        const eventYear = new Date(event.date).getFullYear();

                        // Create a case-insensitive regex for each name in textFilter
                        const regexMatches =
                            filter.text.length === 0 ||
                            filter.text.some((name) => {
                                const regex = new RegExp(name.toLowerCase(), "i");
                                return regex.test(event.name.toLowerCase());
                            });

                        return eventYear === filter.selectedYear && regexMatches;
                    });

                    if (filteredEvents.length > 0) {
                        result[month] = {
                            name: monthObj.name,
                            events: filteredEvents
                        };
                    }
                }

                return result;
            },
            updateFilter(filters) {
                this.UPDATE_HELPERS_EVENTS_FILTER_TEXT([...filters]);
                this.filteredList = this.filterEvents(
                    { ...this.formattedEventList },
                    this.helpers.eventsFilter
                );
            },
            handleCreateYearsContext(eventList) {
                const YEAR_DEFAULT = 2025;
                const yearsContext = createYearsContext(eventList ?? []);

                if (!yearsContext?.length) {
                    this.UPDATE_HELPERS_EVENTS_FILTER_SELECTED_YEAR(YEAR_DEFAULT);
                }

                this.UPDATE_HELPERS_CONTEXT_YEARS(
                    yearsContext.length ? [...yearsContext] : [YEAR_DEFAULT]
                );
            }
        },
        watch: {
            "helpers.eventsFilter.selectedYear"() {
                this.filteredList = this.filterEvents(
                    { ...this.formattedEventList },
                    this.helpers.eventsFilter
                );
            },
            eventList: {
                handler(v) {
                    this.handleCreateYearsContext(v);
                    this.formattedEventList = this.groupByMonth(this.eventList);
                    this.filteredList = this.filterEvents(
                        { ...this.formattedEventList },
                        this.helpers.eventsFilter
                    );
                },
                deep: true,
                immediate: true
            }
        }
    };
</script>

<style lang="scss">
    #events-settings {
        height: 100%;

        .block-no-items {
            display: flex;
            align-items: center;
            padding: 16px;
            min-height: 372px;
            height: 100%;

            .no-items {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                border-radius: 6px;
                width: 100%;

                .empty-box {
                    width: 80px;
                    height: 80px;
                }

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26px;
                    color: #4c4541;
                    margin: 12px 0 0;
                }

                .subtitle {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #998f8a;
                    padding: 4px 0 12px;
                    margin: 0;
                }

                .btn-new {
                    border-color: #974900 !important;
                    background-color: #974900 !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    padding: 8px 21px;
                    line-height: 20px;
                    letter-spacing: 0.175px;

                    .add-icon {
                        width: 14px;
                        height: 14px;
                        fill: #fff;
                    }
                }

                @media (max-width: 480px) {
                    .empty-box {
                        width: 40px;
                        height: 40px;
                    }

                    .title {
                        font-size: 14px;
                    }

                    .subtitle {
                        font-size: 12px;
                        text-align: center;
                        padding: 4px 48px 12px;
                        line-height: 16px;
                    }

                    .btn-new {
                        padding: 3px 14px;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.15px;

                        .add-icon {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
        }

        .search-input {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 16px 16px 0;

            @media (max-width: 580px) {
                flex-direction: column;
                gap: 8px;

                .div-custom-input {
                    max-width: 100%;
                    order: 2;
                }
            }

            .div-custom-input {
                display: flex;
                align-items: center;
                border-radius: 4px;
                width: 100%;
                overflow: hidden;
                border: 1px solid #cfc4be;
                padding: 0 12px;
                max-height: 38px;

                .icon-block {
                    display: flex;
                    align-items: center;
                    height: 14px;
                    .search-icon {
                        width: 14px;
                        height: 14px;
                        fill: #cfc4be;
                    }
                }
                .input-search-fields {
                    width: 100% !important;
                    border: none;
                    overflow: hidden;

                    &.focus {
                        box-shadow: none;
                    }
                    &.b-form-tags {
                        padding: 0 !important;
                        display: flex;
                        align-items: center !important;
                        .b-form-tags-list {
                            width: 100% !important;
                            margin-left: 14px !important;
                            margin-top: 0;
                            height: 100% !important;
                            flex-wrap: nowrap !important;
                            display: flex !important;
                            align-items: center !important;
                            overflow: scroll;
                            overflow-y: hidden;
                            cursor: grabbing;
                            cursor: -webkit-grabbing;

                            &::-webkit-scrollbar {
                                display: none !important;
                            }
                            .b-form-tags-field {
                                margin: 9px 0;
                            }
                        }
                    }

                    .b-form-tags-button {
                        display: none;
                    }

                    .b-form-tag {
                        background-color: #974900;
                        margin-right: 0.6rem;
                        padding: 2px 8px !important;
                        font-size: 13px;
                    }
                }
            }

            .add-field {
                display: flex;
                padding-left: 8px !important;
                padding-right: 0 !important;
                @media (max-width: 580px) {
                    max-width: 100% !important;
                    padding-left: 0 !important;
                    flex: 0 0 100% !important;
                    width: 100% !important;
                }
                .btn-new {
                    border-color: #974900 !important;
                    background-color: #974900 !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    width: 100% !important;
                    padding: 8px 0 !important;
                    line-height: 20px;
                    letter-spacing: 0.175px;

                    @media (max-width: 580px) {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        padding: 3.5px 0 !important;
                    }

                    .add-icon {
                        width: 14px;
                        height: 14px;
                        fill: #fff;
                    }
                }

                .icon-option {
                    border-color: #974900 !important;
                    border-left-color: #fff !important;
                    background-color: #974900 !important;
                    padding: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .dropdown {
                        width: 14px;
                        height: 14px;
                        fill: #fff;
                    }
                }
            }
        }

        .collapse-months {
            max-height: 259px;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            margin-right: 6px;

            .collapse-block {
                padding: 18px 8px 0 16px;

                .collapse-header {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 16px;
                    border-radius: 2px;
                    background: #f4eee8;

                    .event-name {
                        color: #4c4541;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                    }

                    .event-counter {
                        color: #4c4541;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;

                        .arrow-icon {
                            width: 16px;
                            height: 16px;
                            fill: #4c4541;

                            &.--is-open {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }

            &.--is-modal {
                max-height: calc(100vh - 255px);
            }

            &::-webkit-scrollbar {
                width: 7px;
            }

            &::-webkit-scrollbar-track {
                background: #fbeee8;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #cfc4be;
                border-radius: 9px;
            }
        }
    }
</style>
