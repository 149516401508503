<template>
    <div id="resource-settings">
        <div
            class="block-no-items"
            v-if="resourcesList.length === 0"
        >
            <div class="no-items h-100">
                <EmptyBoxIcon class="empty-box" />
                <p class="title">{{ $t("CalendarsPage.NoResourceTitle") }}</p>
                <p class="subtitle">
                    {{ $t("CalendarsPage.NoResourceSubtitle") }}
                </p>
                <b-button
                    class="btn-new"
                    @click="UPDATE_MODAL_RESOURCES_TOGGLE(true)"
                >
                    <LinkIcon class="add-icon" />
                    {{ $t("CalendarsPage.NewResource") }}
                </b-button>
            </div>
        </div>
        <div
            v-else
            class="resources --has-events"
        >
            <div class="search-input">
                <div class="div-custom-input input__fields">
                    <div class="icon-block">
                        <SearchIcon class="search-icon" />
                    </div>

                    <b-form-tags
                        class="input-search-fields"
                        ref="formTagFields"
                        v-model="filter.textFilter"
                        duplicate-tag-text=""
                        :placeholder="
                            filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''
                        "
                        @input="(e) => updateFilter(e)"
                        remove-on-delete
                        add-on-change
                    />
                </div>
            </div>

            <div :class="['resource-list', isModal ? '--is-modal' : '']">
                <div
                    v-for="resource in filteredList"
                    :key="resource.id"
                    class="resource-row"
                >
                    <div
                        class="resource-ghost"
                        @click="clickOnResource(resource)"
                    />
                    <div class="resource-block">
                        <div class="check-resource">
                            <div
                                :class="[
                                    'checkbox-container-resources',
                                    resource.checked ? 'checked' : ''
                                ]"
                            >
                                <span class="icon-checkbox __no-action-default"><CheckIcon /></span>
                            </div>
                        </div>
                        <div class="text-block">
                            <div class="resource-name">
                                {{ resource.name }}
                            </div>
                            <div class="resource-code">{{ resource.code }}</div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div
                            class="unlink __no-action-default"
                            @click="unLinkResource(resource.id)"
                        >
                            <UnLinkIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import EmptyBoxIcon from "@core/assets/icons/empty-box-icon.svg";
    import { BButton, BFormTags } from "bootstrap-vue";
    import SearchIcon from "@core/assets/icons/search-icon.svg";
    import CheckIcon from "@core/assets/icons/check-icon.svg";
    import UnLinkIcon from "@core/assets/icons/resource-unlink.svg";
    import LinkIcon from "@core/assets/icons/resource-link.svg";
    import { saveCalendarService } from "@/views/pages/calendar/actionCalendar/services";

    export default {
        props: {
            resourcesList: {
                type: Array,
                default: () => []
            },
            isModal: {
                type: Boolean,
                default: false
            }
        },
        components: {
            EmptyBoxIcon,
            SearchIcon,
            CheckIcon,
            UnLinkIcon,
            LinkIcon,
            BButton,
            BFormTags
        },
        computed: {
            ...mapState("calendar", ["calendarSettings"])
        },
        data() {
            return {
                filteredList: [],
                filter: {
                    textFilter: []
                }
            };
        },
        mounted() {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
            this.getResources();
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", ["UPDATE_MODAL_RESOURCES_TOGGLE", "UPDATE_RESOURCES_LIST"]),
            getResources() {
                this.filteredList = this.resourcesList;
            },
            filterResources(filters, list) {
                if (!filters.length) return list;

                let filteredSet = new Set();

                filters.forEach((filterValue) => {
                    const regex = new RegExp(filterValue.toLowerCase(), "i");
                    list.forEach((item) => {
                        if (regex.test(item.name.toLowerCase())) {
                            filteredSet.add(item);
                        }
                    });
                });

                return Array.from(filteredSet);
            },
            updateFilter(filters) {
                this.filteredList = this.filterResources(filters, [
                    ...this.calendarSettings.resources.resourcesList
                ]);
            },
            clearChecked() {
                const resourceListCopy = [...this.calendarSettings.resources.resourcesList];

                resourceListCopy.forEach((resource, i) => {
                    resourceListCopy[i]["checked"] = false;
                });

                this.UPDATE_RESOURCES_LIST([...resourceListCopy]);
            },
            clickOnResource(item) {
                const resourceListCopy = [...this.calendarSettings.resources.resourcesList];

                resourceListCopy.forEach((resource, i) => {
                    if (resource.id == item.id) {
                        resourceListCopy[i]["checked"] = !resource?.checked;
                    }
                });

                this.UPDATE_RESOURCES_LIST([...resourceListCopy]);
            },
            unLinkResource(resourceId) {
                this.$swal({
                    title: this.$t("StructurePage.UnlinkResources"),
                    html: `<div>${this.$t("CalendarsPage.DeleteResourceMessage")}</div>`,
                    showCancelButton: true,
                    showCloseButton: true,
                    cancelButtonText: this.$t("IntegrationPage.cancel"),
                    confirmButtonText: this.$t("IntegrationPage.Unlink"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (!result.value) return;

                    const resourcesCopy = [...this.calendarSettings.resources.resourcesList];

                    const replacedResourceList = resourceId?.toString()
                        ? this.handleUnlinkResource(resourcesCopy, resourceId)
                        : this.handleUnlinkCheckedResources(resourcesCopy);

                    if (!replacedResourceList) return;

                    this.UPDATE_RESOURCES_LIST([...replacedResourceList]);

                    this.$setToast({
                        title: this.$t("Success"),
                        icon: "SmileIcon",
                        text: this.$t("CalendarsPage.ChangesSaved"),
                        variant: "success"
                    });

                    saveCalendarService(this);
                });
            },
            handleUnlinkResource(resourceList, resourceId) {
                const resourceIndex = resourceList.findIndex(
                    (resource) => resource.id == resourceId
                );

                if (resourceIndex == -1) return null;

                resourceList.splice(resourceIndex, 1);

                return resourceList;
            },
            handleUnlinkCheckedResources(resourceList) {
                const checkedResources = resourceList.filter((resource) => !resource?.checked);

                if (!checkedResources) return null;

                return checkedResources;
            }
        },
        watch: {
            "calendarSettings.resources.resourcesList": {
                handler(newValue) {
                    this.filteredList = [...newValue];
                },
                deep: true,
                immediate: true
            }
        }
    };
</script>

<style lang="scss">
    #resource-settings {
        height: 100%;

        .block-no-items {
            display: flex;
            align-items: center;
            padding: 16px;
            min-height: 372px;
            height: 100%;

            .no-items {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                border-radius: 6px;
                width: 100%;

                .empty-box {
                    width: 80px;
                    height: 80px;
                }

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26px;
                    color: #4c4541;
                    margin: 12px 0 0;
                }

                .subtitle {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #998f8a;
                    padding: 4px 0 12px;
                    margin: 0;
                }

                .btn-new {
                    border-color: #974900 !important;
                    background-color: #974900 !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    padding: 8px 21px;
                    line-height: 20px;
                    letter-spacing: 0.175px;

                    .add-icon {
                        width: 14px;
                        height: 14px;
                        fill: #fff;
                    }
                }

                @media (max-width: 480px) {
                    .empty-box {
                        width: 40px;
                        height: 40px;
                    }

                    .title {
                        font-size: 14px;
                    }

                    .subtitle {
                        font-size: 12px;
                        text-align: center;
                        padding: 4px 48px 12px;
                        line-height: 16px;
                    }

                    .btn-new {
                        padding: 3px 14px;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.15px;

                        .add-icon {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
        }

        .resources {
            height: 100%;
            padding: 16px 0 16px 16px;

            .search-input {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 0 16px 16px 0;

                @media (max-width: 580px) {
                    flex-direction: column;
                    gap: 8px;

                    .div-custom-input {
                        max-width: 100%;
                        order: 2;
                    }
                }

                .div-custom-input {
                    display: flex;
                    align-items: center;
                    border-radius: 4px;
                    width: 100%;
                    overflow: hidden;
                    border: 1px solid #cfc4be;
                    padding: 0 12px;
                    max-height: 38px;

                    .icon-block {
                        display: flex;
                        align-items: center;
                        height: 14px;
                        .search-icon {
                            width: 14px;
                            height: 14px;
                            fill: #cfc4be;
                        }
                    }
                    .input-search-fields {
                        width: 100% !important;
                        border: none;
                        overflow: hidden;

                        &.focus {
                            box-shadow: none;
                        }
                        &.b-form-tags {
                            padding: 0 !important;
                            display: flex;
                            align-items: center !important;
                            .b-form-tags-list {
                                width: 100% !important;
                                margin-left: 14px !important;
                                margin-top: 0;
                                height: 100% !important;
                                flex-wrap: nowrap !important;
                                display: flex !important;
                                align-items: center !important;
                                overflow: scroll;
                                overflow-y: hidden;
                                cursor: grabbing;
                                cursor: -webkit-grabbing;

                                &::-webkit-scrollbar {
                                    display: none !important;
                                }
                                .b-form-tags-field {
                                    margin: 9px 0;
                                }
                            }
                        }

                        .b-form-tags-button {
                            display: none;
                        }

                        .b-form-tag {
                            background-color: #974900;
                            margin-right: 0.6rem;
                            padding: 2px 8px !important;
                            font-size: 13px;
                        }
                    }
                }

                .add-field {
                    display: flex;
                    padding-left: 8px !important;
                    padding-right: 0 !important;
                    @media (max-width: 580px) {
                        max-width: 100% !important;
                        padding-left: 0 !important;
                        flex: 0 0 100% !important;
                        width: 100% !important;
                    }
                    .btn-new {
                        border-color: #974900 !important;
                        background-color: #974900 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        width: 100% !important;
                        padding: 8px 0 !important;
                        line-height: 20px;
                        letter-spacing: 0.175px;

                        @media (max-width: 580px) {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            padding: 3.5px 0 !important;
                        }

                        .add-icon {
                            width: 14px;
                            height: 14px;
                            fill: #fff;
                        }
                    }

                    .icon-option {
                        border-color: #974900 !important;
                        border-left-color: #fff !important;
                        background-color: #974900 !important;
                        padding: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .dropdown {
                            width: 14px;
                            height: 14px;
                            fill: #fff;
                        }
                    }
                }
            }

            .resource-list {
                max-height: 230px;
                height: 100%;
                margin-right: 8px;
                overflow-y: auto;

                .resource-row {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #eee;
                    background: #fff;
                    margin-right: 16px;
                    cursor: pointer;
                    position: relative;

                    .resource-ghost {
                        position: absolute;
                        display: flex;
                        height: 100%;
                        width: 100%;
                        z-index: 1;
                    }

                    .resource-block {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 7.5px 16px;
                        overflow: hidden;

                        .picture {
                            img {
                                width: 40px;
                                border-radius: 50%;
                                @media (max-width: 480px) {
                                    width: 30px;
                                }
                            }
                        }

                        .check-resource {
                            display: none;
                            padding: 9px 0;

                            &:has(.checked) {
                                display: flex;
                            }

                            .checkbox-container-resources {
                                display: flex;
                                background-color: #fff;
                                border: 1px solid #cfc4be;
                                border-radius: 3px;
                                margin-top: 4px;
                                cursor: pointer;
                                width: 18px;
                                height: 18px;
                                margin: 0;

                                .icon-checkbox {
                                    display: flex !important;
                                    align-items: center;
                                    justify-content: center;
                                    margin-top: 2px;
                                    svg {
                                        width: 12px;
                                        height: 12px;
                                    }
                                }
                                &.checked {
                                    background-color: #974900;
                                    display: block;
                                }
                            }

                            @media (max-width: 480px) {
                                padding: 6px 0;
                            }
                        }

                        .text-block {
                            overflow: hidden;
                            .resource-name {
                                color: #4c4541;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 20px;
                                white-space: nowrap;
                                word-break: break-all;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                @media (max-width: 480px) {
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }
                            .resource-code {
                                color: #998f8a;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 16px;

                                @media (max-width: 480px) {
                                    font-size: 10px;
                                    line-height: 14px;
                                }
                            }
                        }
                    }

                    .unlink {
                        display: none;
                        cursor: pointer;
                        padding: 8px;
                        margin-right: 16px;
                        border-radius: 5px;
                        z-index: 2;
                        position: relative;

                        svg {
                            width: 12px;
                            height: 12px;
                            fill: #974900;
                        }

                        &:hover {
                            background-color: #ffede2 !important;
                        }

                        &:active {
                            background-color: #ffdbc4 !important;
                        }
                    }

                    &:hover,
                    &:active {
                        &:has(.checked) {
                            border-color: #eee;
                            background: #fff;
                            .resource-block {
                                .picture {
                                    display: none;

                                    @media (max-width: 480px) {
                                        display: block;
                                    }
                                }
                                .check-resource {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                                .text-block {
                                    .resource-name {
                                        color: #4c4541;
                                    }
                                    .resource-code {
                                        color: #998f8a;
                                    }
                                }
                            }
                            .unlink {
                                display: none;
                            }
                        }

                        background: #ffede2;
                        border-color: #974900;
                        .resource-block {
                            .picture {
                                display: none;

                                @media (max-width: 480px) {
                                    display: block;
                                }
                            }
                            .check-resource {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .text-block {
                                .resource-code,
                                .resource-name {
                                    color: #974900;
                                }
                            }
                        }
                        .unlink {
                            display: flex;
                        }
                    }
                }

                &.--is-modal {
                    max-height: calc(100vh - 255px);
                }

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    background: #fbeee8;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #cfc4be;
                    border-radius: 9px;
                }
            }
        }
    }
</style>
