var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"general-settings"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DepartmentPage.Name'),"label-for":"name","invalid-feedback":_vm.$t(_vm.calendarSettings.general.states.name.feedback)}},[_c('b-form-input',{attrs:{"id":"name","type":"text","value":_vm.calendarSettings.general.fields.name,"state":_vm.calendarSettings.general.states.name.valid
                    ? null
                    : _vm.calendarSettings.general.states.name.valid,"placeholder":_vm.$t('TypeHere')},on:{"input":function (value) { return _vm.UPDATE_MODAL_GENERAL_NAME_FIELD(value); }}})],1),_c('Datepicker',{staticClass:"reference-date",attrs:{"field":{
            id: 'reference-date-datepicker',
            label: _vm.$t('CalendarsPage.ReferenceDate2'),
            industrialStructParameterType: 'datetime',
            value: _vm.calendarSettings.general.fields.referenceDate
        }},on:{"input":function (value) { return _vm.UPDATE_MODAL_GENERAL_DATE_FIELD(value); }}}),_c('b-form-group',{attrs:{"label":_vm.$t('CalendarsPage.Duration2'),"label-for":"duration","invalid-feedback":_vm.$t('RequiredField')}},[_c('div',{staticClass:"input-duration"},[_c('b-form-input',{attrs:{"id":"duration","type":"number","value":_vm.calendarSettings.general.fields.duration,"placeholder":_vm.$t('TypeHere')},on:{"input":function (value) { return _vm.UPDATE_MODAL_GENERAL_DURATION_FIELD(value); }},nativeOn:{"input":function($event){return _vm.handleOnInputDuration.apply(null, arguments)}}}),_c('div',{staticClass:"days"},[_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.Days"))+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }