var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"day-column__container"},[_c('div',{staticClass:"day-column__hours-area"},[_c('div',{staticClass:"day-column__period-block-wrapper"},_vm._l((_vm.periodList),function(period,i){return _c('div',{key:("period-" + i),staticClass:"day-column__period-block"},[(period.type === 'period')?_c('Period',{attrs:{"period":period,"pixelBasePerMinute":_vm.periods.pixelBasePerMinute *
                        _vm.calculateMinutes(period.startTime, period.endTime)},on:{"click":_vm.handleFillPeriodModal}}):_vm._e(),(period.type === 'interval')?_c('Interval',{attrs:{"period":period,"pixelBasePerMinute":_vm.periods.pixelBasePerMinute *
                        _vm.calculateMinutes(period.startTime, period.endTime)},on:{"click":_vm.handleFillPeriodModal}}):_vm._e(),(period.type === 'spacing')?_c('div',{style:({
                        width: '0px',
                        height:
                            _vm.periods.pixelBasePerMinute *
                                _vm.calculateMinutes(period.startTime, period.endTime) +
                            'px'
                    })}):_vm._e()],1)}),0),_vm._l((_vm.hourList),function(period,i){return _c('half-hour-block',{key:("hour-of-day-" + i),attrs:{"pixelBasePerMinute":_vm.periods.pixelBasePerMinute * _vm.calendarRefs.halfAnHour,"period":{
                type: 'half-hour',
                startTime: period,
                endTime: _vm.hourList.length - 1 != i ? _vm.hourList[i + 1] : '00:00'
            }},on:{"click":_vm.handleFillPeriodModal}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }