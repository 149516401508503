<template>
    <div
        class="sys-dropdown__container"
        ref="dropdownContainer"
    >
        <button
            class="sys-dropdown__button"
            @click="toggleDropdown"
        >
            <label
                class="sys-dropdown__label"
                v-if="label"
            >
                {{ label }}
            </label>
            <span class="sys-dropdown__arrow-icon">
                <dropdown-icon />
            </span>
        </button>
        <ul
            v-show="showDropdownList"
            :class="[
                'sys-dropdown__list',
                { '--left': position === 'left', '--right': position === 'right' }
            ]"
        >
            <li
                v-for="(option, index) in options"
                :key="`filter-option-${index}`"
                :class="[
                    'sys-dropdown__option-container',
                    {
                        '--drop-active': value === option.id
                    }
                ]"
                @click="() => selectOption(option)"
            >
                <div class="sys-dropdown__option-content">
                    {{ option.text }}
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        components: {
            DropdownIcon: () => import("@/@core/assets/formatted-icons/arrow-down-icon.svg")
        },
        props: {
            options: {
                type: Array,
                default: [],
                required: true
            },
            position: {
                type: String,
                default: "right"
            },
            value: {
                default: null
            },
            label: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                showDropdownList: false
            };
        },
        methods: {
            toggleDropdown() {
                this.showDropdownList = !this.showDropdownList;
            },
            selectOption(option) {
                this.$emit("input", option); // Emitir a opção selecionada
                this.showDropdownList = false; // Fechar o dropdown
            },
            closeDropdown() {
                this.showDropdownList = false; // Fechar o dropdown
            },
            handleClickOutside(event) {
                if (
                    this.$refs.dropdownContainer &&
                    !this.$refs.dropdownContainer.contains(event.target)
                ) {
                    this.closeDropdown();
                }
            }
        },
        mounted() {
            document.addEventListener("click", this.handleClickOutside); // Adiciona o event listener
        },
        beforeDestroy() {
            document.removeEventListener("click", this.handleClickOutside); // Remove o event listener
        }
    };
</script>

<style lang="scss">
    .sys-dropdown__container {
        border: none !important;
        padding: 0;
        margin: 0;
        user-select: none;
        display: flex;
        position: relative;

        .sys-dropdown__button {
            height: 28px;
            padding: 4px 10px;
            border-radius: 5px;
            background: #fff;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;

            &:hover {
                background: #ffede2;
            }

            &:active {
                background: #ffdbc4;
            }

            .sys-dropdown__label {
                margin: 0;
                color: #974900 !important;
                font-size: 12px !important;
                font-weight: 600 !important;
                line-height: 20px !important;
                letter-spacing: 0.15px;
            }

            .sys-dropdown__arrow-icon {
                display: flex;

                svg {
                    min-height: 12px;
                    max-height: 12px;
                    min-width: 12px;
                    max-width: 12px;
                    fill: #974900;
                }
            }
        }

        .sys-dropdown__list {
            width: 180px;
            max-width: 180px;
            padding: 8px 0 !important;
            margin: 0 !important;
            border-radius: 6px !important;
            background: #fff;
            box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);

            position: absolute;
            top: 32px;
            z-index: 10;

            display: flex;
            flex-direction: column;
            list-style: none;

            &.--right {
                left: 0;
            }

            &.--left {
                right: 0;
            }

            .sys-dropdown__option-container {
                .sys-dropdown__option-content {
                    padding: 12px 21px;
                    color: #998f8a;
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    line-height: 20px !important;
                    color: #974900 !important;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                    white-space: nowrap;
                    cursor: pointer;

                    &:hover {
                        color: #974900;
                        background: #ffede2 !important;
                    }

                    &:active {
                        color: #974900;
                        background: #ffdbc4 !important;
                    }
                }

                &.--drop-active {
                    .sys-dropdown__option-content {
                        background: #974900 !important;
                        color: #fff !important;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 480px) {
        .sys-dropdown__container {
            & > li {
                width: 100%;

                .sys-dropdown__button {
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }
</style>
