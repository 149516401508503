<template>
    <div
        class="half-hour-block__container"
        :style="{
            'min-height': pixelBasePerMinute + 'px',
            'max-height': pixelBasePerMinute + 'px'
        }"
        @click="(event) => $emit('click', event, period)"
    />
</template>

<script>
    export default {
        props: {
            pixelBasePerMinute: {
                type: Number,
                default: 1.2
            },
            period: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>

<style lang="scss" scoped>
    .half-hour-block__container {
        background: #fff;
        flex: 1;
        display: flex;
        border: 1px solid #e0e0e0;
        border-left: 0;
        border-top: 0;
        min-height: 36px;
        max-height: 36px;
        cursor: pointer;
        z-index: 2;

        &:hover {
            background: #ffede2;
        }

        &:active {
            background: #ffdbc4;
        }
    }

    @media (max-width: 915px) {
        .half-hour-block__container {
            min-width: 84px;
        }
    }
</style>
