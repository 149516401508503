var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"dropdownContainer",staticClass:"sys-dropdown__container"},[_c('button',{staticClass:"sys-dropdown__button",on:{"click":_vm.toggleDropdown}},[(_vm.label)?_c('label',{staticClass:"sys-dropdown__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('span',{staticClass:"sys-dropdown__arrow-icon"},[_c('dropdown-icon')],1)]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDropdownList),expression:"showDropdownList"}],class:[
            'sys-dropdown__list',
            { '--left': _vm.position === 'left', '--right': _vm.position === 'right' }
        ]},_vm._l((_vm.options),function(option,index){return _c('li',{key:("filter-option-" + index),class:[
                'sys-dropdown__option-container',
                {
                    '--drop-active': _vm.value === option.id
                }
            ],on:{"click":function () { return _vm.selectOption(option); }}},[_c('div',{staticClass:"sys-dropdown__option-content"},[_vm._v(" "+_vm._s(option.text)+" ")])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }