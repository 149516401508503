<template>
    <div id="settings-calendar">
        <div class="settings-header">
            <span
                :class="['tab-title', currentBody === 'general' ? '--tab-active' : '']"
                @click="currentBody = 'general'"
                >{{ $t("AccountSetting.General") }}</span
            >
            <span
                :class="['tab-title', currentBody === 'events' ? '--tab-active' : '']"
                @click="currentBody = 'events'"
                >{{ $t("CalendarsPage.Events") }}</span
            >
            <span
                :class="['tab-title', currentBody === 'resources' ? '--tab-active' : '']"
                @click="currentBody = 'resources'"
                >{{ $t("WorkcenterList.Resources") }}</span
            >
        </div>
        <div
            :class="[
                'settings-body',
                removeFooter ? '--is-modal' : '',
                currentBody === 'events' && !calendarSettings.events.eventList.length
                    ? '--no-events'
                    : ''
            ]"
        >
            <General v-show="currentBody === 'general'" />
            <Events
                v-show="currentBody === 'events'"
                :eventList="calendarSettings.events.eventList"
                :isModal="removeFooter"
            />

            <Resources
                v-show="currentBody === 'resources'"
                :resourcesList="calendarSettings.resources.resourcesList"
                :isModal="removeFooter"
                ref="resourcesComponent"
            />
        </div>
        <div
            v-if="!removeFooter"
            :class="[
                'settings-footer',
                (currentBody === 'events' && !calendarSettings.events.eventList.length) ||
                (currentBody === 'resources' && !calendarSettings.resources.resourcesList.length)
                    ? '--no-events'
                    : ''
            ]"
        >
            <b-button
                v-if="currentBody === 'general'"
                class="btn-apply"
                @click="onApply()"
            >
                <SaveIcon /> {{ $t("CalendarsPage.Apply") }}
            </b-button>
            <div
                v-if="currentBody === 'events'"
                class="footer-events"
            >
                <div class="year-block">
                    <CalendarIcon class="calendar-icon" />
                    <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        class="years-dropdown"
                        ref="dropdown"
                        dropup
                        :dropdown="false"
                        @shown="focusSelectedYear"
                    >
                        <template v-slot:button-content>
                            {{ helpers.eventsFilter.selectedYear }}
                        </template>
                        <div
                            class="custom-dropdown-menu"
                            ref="dropdownMenu"
                        >
                            <b-dropdown-item
                                v-for="year in helpers.context.years"
                                :key="year"
                                @click="selectYear(year)"
                                :class="{
                                    'selected-year': year === helpers.eventsFilter.selectedYear
                                }"
                            >
                                {{ year }}
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <ArrowDownIcon
                        class="arrow-icon cursor-pointer"
                        @click="toggleDropdown"
                    />
                </div>
                <b-button
                    class="btn-new-event"
                    @click="newEvent()"
                >
                    <AddIcon /> {{ $t("CalendarsPage.NewEvent") }}
                </b-button>
            </div>
            <div
                v-if="currentBody === 'resources'"
                class="footer-resources"
            >
                <div
                    v-if="hasCheckedResource"
                    class="block-checked"
                >
                    <b-button
                        class="btn-clear"
                        @click="clearCheckedResources()"
                    >
                        {{ $t("Clear") }}
                    </b-button>
                    <b-button
                        class="btn-action"
                        @click="onUnlinkResource()"
                    >
                        <UnLinkIcon /> {{ $t("StructurePage.UnlinkResource") }}
                    </b-button>
                </div>
                <b-button
                    v-else
                    class="btn-action"
                    @click="UPDATE_MODAL_RESOURCES_TOGGLE(true)"
                >
                    <LinkIcon />
                    {{ $t("CalendarsPage.NewResource") }}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import General from "./components/General.vue";
    import Events from "./components/Events.vue";
    import Resources from "./components/Resources.vue";
    import { BButton, BDropdown, BDropdownItem } from "bootstrap-vue";
    import SaveIcon from "@core/assets/icons/save-icon.svg";
    import AddIcon from "@core/assets/icons/add-icon.svg";
    import CalendarIcon from "@/assets/images/icons/calendar-icon.svg";
    import ArrowDownIcon from "@core/assets/icons/arrow-down.svg";
    import UnLinkIcon from "@core/assets/icons/resource-unlink.svg";
    import LinkIcon from "@core/assets/icons/resource-link.svg";
    import { calendarInitialState } from "@/store/calendar";
    import { saveCalendarService } from "@/views/pages/calendar/actionCalendar/services/index.js";
    import {
        checkCompletedPeriods,
        createYearsContext
    } from "@/views/pages/calendar/actionCalendar/utils";

    export default {
        props: {
            removeFooter: {
                type: Boolean,
                default: false
            }
        },
        components: {
            General,
            Events,
            Resources,
            BButton,
            BDropdown,
            BDropdownItem,
            SaveIcon,
            AddIcon,
            CalendarIcon,
            ArrowDownIcon,
            UnLinkIcon,
            LinkIcon
        },
        data() {
            return {
                currentBody: "general",
                hasCheckedResource: false
            };
        },
        mounted() {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_EVENTS_TOGGLE",
                "UPDATE_MODAL_RESOURCES_TOGGLE",
                "UPDATE_GENERAL_APPLIED",
                "UPDATE_CONTEXT_DAYS",
                "UPDATE_PERIODS_PERIOD_LIST",
                "UPDATE_MODAL_GENERAL_STATES",
                "UPDATE_PERIODS_IS_COMPLETED_PERIODS",
                "UPDATE_HELPERS_EVENTS_FILTER_SELECTED_YEAR"
            ]),
            validateFields() {
                const fields = { ...this.calendarSettings.general.fields };
                const states = { ...calendarInitialState().calendarSettings.general.states };

                const validations = {
                    name: () => {
                        if (!fields.name) {
                            states.name = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.name = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    }
                };

                let isValid = true;

                for (const action of Object.values(validations)) {
                    const resultValidation = action?.();

                    if (isValid) {
                        isValid = resultValidation;
                    }
                }

                this.UPDATE_MODAL_GENERAL_STATES({ ...states });

                return isValid;
            },
            onApply() {
                const resultValidation = this.validateFields();

                if (!resultValidation) return;

                const payload = {
                    name: this.calendarSettings.general.fields.name,
                    referenceDate: this.calendarSettings.general.fields.referenceDate,
                    duration: this.calendarSettings.general.fields.duration
                };

                const filteredPeriodList = [
                    ...this.periods.periodList.filter(
                        (period) => period.day <= Number(payload.duration)
                    )
                ];
                this.UPDATE_PERIODS_PERIOD_LIST([...filteredPeriodList]);

                this.UPDATE_CONTEXT_DAYS(
                    payload.duration > 0
                        ? Array.from({ length: Number(payload.duration) }, (_, i) => ({
                              id: i + 1,
                              text: String(i + 1)
                          }))
                        : []
                );

                this.UPDATE_GENERAL_APPLIED({ ...payload });

                const isCompletedPeriods = checkCompletedPeriods(
                    filteredPeriodList,
                    Number(payload.duration)
                );

                this.UPDATE_PERIODS_IS_COMPLETED_PERIODS(isCompletedPeriods);

                saveCalendarService(this);
            },
            clearCheckedResources() {
                if (this.$refs.resourcesComponent) {
                    this.$refs.resourcesComponent.clearChecked();
                    this.hasCheckedResource = false;
                }
            },
            onUnlinkResource() {
                if (this.$refs.resourcesComponent) {
                    this.$refs.resourcesComponent.unLinkResource();
                }
            },
            newEvent() {
                this.UPDATE_MODAL_EVENTS_TOGGLE(true);
            },
            toggleDropdown() {
                const dropdown = this.$refs.dropdown;
                if (dropdown) {
                    dropdown.show();
                }
            },
            selectYear(year) {
                this.UPDATE_HELPERS_EVENTS_FILTER_SELECTED_YEAR(year);
            },
            focusSelectedYear() {
                this.$nextTick(() => {
                    const menu = this.$refs.dropdownMenu;
                    const selectedItem = menu.querySelector(".selected-year");

                    if (selectedItem) {
                        selectedItem.scrollIntoView({
                            behavior: "auto",
                            block: "start"
                        });
                    }
                });
            }
        },
        computed: {
            ...mapState("calendar", {
                calendarSettings: "calendarSettings",
                periods: "periods",
                helpers: "helpers"
            })
        },
        watch: {
            currentBody(v) {
                this.$emit("currentBody", v);
            },
            "calendarSettings.resources.resourcesList": {
                handler(newValue) {
                    this.hasCheckedResource = newValue?.some((resource) => !!resource?.checked);
                },
                deep: true,
                immediate: true
            }
        }
    };
</script>

<style lang="scss">
    #settings-calendar {
        height: 100%;
        display: flex;
        flex-direction: column;
        .settings-header {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ffede2;
            .tab-title {
                cursor: pointer;
                padding: 16px 0;
                text-align: center;
                width: 100%;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                color: #998f8a;

                &.--tab-active {
                    color: #974900;
                    box-shadow: 0px -4px 0px -1px #974900 inset;
                }

                &:hover {
                    color: #974900;
                }
            }
        }

        .settings-body {
            overflow-y: auto;
            flex-grow: 1;
            &.--no-events {
                height: 100%;
            }

            &.--is-modal {
                &:has(.block-no-items) {
                    height: calc(100vh - 120px);
                }
                &:has(.--has-events) {
                    height: calc(100vh - 191px);
                }
            }
        }

        .settings-footer {
            padding: 16px;
            border-top: 1px solid #cfc4be;
            .btn-apply {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                box-shadow: none;
                width: 100%;
                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.0125em;
                padding: 2px 0;
                border: 1px solid #974900 !important;
                background-color: #974900 !important;
                color: #ffff !important;

                svg {
                    width: 12px;
                    height: 12px;
                    fill: #fff;
                }

                &:hover {
                    box-shadow: none;
                    background-color: #9f5714 !important;
                }

                &:active {
                    background-color: #9f5714 !important;
                }
            }

            &.--no-events {
                border: none;
                padding: 0;

                .footer-events,
                .footer-resources {
                    display: none;
                }
            }

            .footer-events {
                display: flex;
                justify-content: space-between;
                gap: 8px;

                .year-block {
                    display: flex;
                    align-items: center;
                    padding: 4px 10px;
                    border-radius: 5px;
                    border: 1px solid #974900;

                    .years-dropdown {
                        padding: 0 8px 0 6px;
                    }

                    .arrow-icon,
                    .calendar-icon {
                        width: 12px;
                        height: 12px;
                        fill: #974900;
                    }

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                .btn-new-event {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.15px;
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    padding: 2px 14px;

                    svg {
                        width: 12px;
                        height: 12px;
                        fill: #fff;
                    }

                    &:hover {
                        box-shadow: none;
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #9f5714 !important;
                    }
                }

                .dropdown-toggle {
                    padding: 0;
                    color: #974900;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.15px;
                }

                .custom-dropdown-menu {
                    max-height: 200px;
                    overflow-y: auto;

                    .selected-year {
                        background-color: #974900;

                        .dropdown-item {
                            color: #fff;
                        }

                        .dropdown-item:hover,
                        .dropdown-item:focus {
                            color: #fff;
                        }

                        .dropdown-item:active {
                            background: #974900;
                        }
                    }

                    .dropdown-item:hover,
                    .dropdown-item:focus {
                        color: #974900;
                    }

                    .dropdown-item:active {
                        background: #ffdbc4;
                    }
                }
            }

            .footer-resources {
                .block-checked {
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;

                    .btn-clear {
                        border-radius: 5px;
                        border: 1px solid #974900;
                        width: 100%;
                        height: 26px;
                        color: #974900 !important;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.15px;
                        background-color: #fff !important;
                        padding: 2px 14px;
                        max-width: 69px;

                        svg {
                            width: 12px;
                            height: 12px;
                            fill: #fff;
                        }

                        &:hover {
                            box-shadow: none;
                            background-color: #ffede2 !important;
                        }

                        &:active {
                            background-color: #ffdbc4 !important;
                        }
                    }
                }

                .btn-action {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.15px;
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    padding: 2px 14px;

                    svg {
                        width: 12px;
                        height: 12px;
                        fill: #fff;
                    }

                    &:hover {
                        box-shadow: none;
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #9f5714 !important;
                    }
                }
            }
        }
    }
</style>
