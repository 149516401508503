import months from "./months";

const monthsList = Object.freeze([
    {
        id: months.january,
        text: "Janeiro",
        name: "Janeiro",
        nameEN: "January"
    },
    {
        id: months.february,
        text: "Fevereiro",
        name: "Fevereiro",
        nameEN: "February"
    },
    {
        id: months.march,
        text: "Março",
        name: "Março",
        nameEN: "March"
    },
    {
        id: months.april,
        text: "Abril",
        name: "Abril",
        nameEN: "April"
    },
    {
        id: months.may,
        text: "Maio",
        name: "Maio",
        nameEN: "May"
    },
    {
        id: months.june,
        text: "Junho",
        name: "Junho",
        nameEN: "June"
    },
    {
        id: months.july,
        text: "Julho",
        name: "Julho",
        nameEN: "July"
    },
    {
        id: months.august,
        text: "Agosto",
        name: "Agosto",
        nameEN: "August"
    },
    {
        id: months.september,
        text: "Setembro",
        name: "Setembro",
        nameEN: "September"
    },
    {
        id: months.october,
        text: "Outubro",
        name: "Outubro",
        nameEN: "October"
    },
    {
        id: months.november,
        text: "Novembro",
        name: "Novembro",
        nameEN: "November"
    },
    {
        id: months.december,
        text: "Dezembro",
        name: "Dezembro",
        nameEN: "December"
    }
]);

export default monthsList;
