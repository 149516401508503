<template>
    <div
        v-if="!calendarSettings.modalEvents.eventId"
        class="link-resource-body"
    >
        <div class="text-block">
            <div class="title">{{ $t("CalendarsPage.ResourceSelection") }}</div>
            <div class="subtitle">
                {{ $t("CalendarsPage.SubTitleLinkModalEvent") }}
            </div>
        </div>
        <div class="search-block">
            <div class="search-input">
                <div class="div-custom-input input__calendar_resources">
                    <div class="icon-block">
                        <SearchIcon class="search-icon" />
                    </div>
                    <b-form-tags
                        v-if="forceRenderSearchInput"
                        class="input-search-resources-calendar"
                        ref="formTagStructures"
                        v-model="filter.textFilter"
                        duplicate-tag-text=""
                        :placeholder="
                            filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''
                        "
                        @input="(e) => updateFilter(e)"
                        remove-on-delete
                        add-on-change
                    />
                </div>
            </div>
        </div>
        <div class="action-block">
            <div class="select-all">
                <div
                    :class="['checkbox-container-resource', allResourceChecked ? 'checked' : '']"
                    @click="checkAllResource()"
                >
                    <span class="icon-checkbox"><CheckIcon /></span>
                </div>
                <span class="text">
                    {{ $t("SelectAll") }}
                </span>
            </div>
        </div>
        <div
            class="resource-block"
            v-if="forceRenderLinkResource"
        >
            <div
                v-if="filteredList.length === 0"
                class="block-no-results"
            >
                <div class="no-results">
                    <SearchIcon />
                    <p class="text">{{ $t("NoResultsFound") }}</p>
                </div>
            </div>
            <div v-else>
                <div
                    v-for="resource in filteredList"
                    :key="resource.id"
                >
                    <div class="user-info-block">
                        <div class="check-resource">
                            <div
                                :class="[
                                    'checkbox-container-resource __no-action-default',
                                    resource.checked ? 'checked' : ''
                                ]"
                                @click="toggleCheckbox(resource)"
                            >
                                <span class="icon-checkbox __no-action-default"><CheckIcon /></span>
                            </div>
                        </div>
                        <div class="text-block">
                            <div class="resource-name">
                                {{ resource.name }}
                            </div>
                            <div class="resource-code">{{ resource.code }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        v-else
        class="linked-resources"
    >
        <div class="h-100">
            <div>
                <div class="select-all">
                    <div
                        :class="[
                            'checkbox-container-resources',
                            allResourceChecked ? 'checked' : ''
                        ]"
                        @click="checkAllResource()"
                    >
                        <span class="icon-checkbox"><CheckIcon /></span>
                    </div>
                    <span class="text">{{ $t("WorkcenterList.Resources") }} </span>
                </div>
                <div class="action-block">
                    <div
                        v-if="!filteredList.find((e) => e.checked)"
                        class="link-resource"
                        @click="openLinkModal()"
                    >
                        <LinkIcon class="link-icon" />
                        <span>{{ $t("StructurePage.LinkResource") }}</span>
                    </div>
                    <div
                        v-else
                        class="unlink-resource"
                        @click="onUnlinkResource()"
                    >
                        <UnLinkIcon class="unlink-icon" />
                        <span>{{ $t("StructurePage.UnlinkResource") }}</span>
                    </div>
                    <div class="function-filter">
                        <span>{{ $t("StructurePage.Type") }}</span>
                        <div
                            id="filter-select-resource"
                            class="filter"
                            tabindex="0"
                        >
                            <span>{{ $t(selectedFilter) }}</span>
                            <ArrowDown class="arrow-down" />
                        </div>
                    </div>
                    <Popover
                        :items="typeList"
                        target="filter-select-resource"
                        placement="bottom"
                        :custom-position="`action-button ${
                            isModalResourceInfoOpen ? '--double-modal-openned' : ''
                        }`"
                        triggers="focus"
                    />
                </div>
            </div>
            <div
                v-if="forceRenderResource"
                :class="['resource-list', filteredList.length >= 9 ? 'enable-scroll' : '']"
            >
                <div
                    v-if="filteredList.length === 0"
                    class="block-no-results"
                >
                    <div class="no-results">
                        <SearchIcon />
                        <p class="text">{{ $t("NoResultsFound") }}</p>
                    </div>
                </div>
                <div v-else>
                    <div
                        v-for="resource in filteredList"
                        :key="resource.id"
                        :class="[
                            'resource-row',
                            resourceInfo.id === resource.id && isModalResourceInfoOpen
                                ? '--is-viewing'
                                : ''
                        ]"
                        @click="openInfoResource(resource, $event)"
                    >
                        <div class="user-info-block">
                            <div class="check-resource">
                                <div
                                    :class="[
                                        'checkbox-container-resources __no-action-default',
                                        resource.checked ? 'checked' : ''
                                    ]"
                                    @click="toggleResourceLinked(resource)"
                                >
                                    <span class="icon-checkbox __no-action-default"
                                        ><CheckIcon
                                    /></span>
                                </div>
                            </div>
                            <div class="text-block">
                                <div class="resource-name">
                                    {{ resource.name }}
                                </div>
                                <div class="resource-code">{{ resource.code }}</div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div
                                v-if="isModalResourceInfoOpen"
                                class="view-block"
                            >
                                <EyeIcon />
                                <span>{{ $t("DepartmentPage.Viewing") }}</span>
                            </div>
                            <div
                                v-if="Array.isArray(resource.type)"
                                class="roles-block"
                            >
                                <div class="role-tag">
                                    {{ $t(resource.type[0]) }}
                                </div>
                                <div
                                    v-if="resource.type[1]"
                                    class="role-tag"
                                >
                                    {{ $t(resource.type[1]) }}
                                </div>
                                <div
                                    v-if="resource.type.length > 2"
                                    class="role-tag"
                                >
                                    + {{ resource.type.length - 2 }}
                                </div>
                            </div>
                            <div
                                v-else
                                class="roles-block"
                            >
                                <div class="role-tag">
                                    {{ $t(resource.type) }}
                                </div>
                            </div>
                            <div
                                class="unlink __no-action-default"
                                @click="unLinkResource(resource)"
                            >
                                <UnLinkIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { BFormTags } from "bootstrap-vue";
    import { mapState, mapMutations } from "vuex";
    import Popover from "@core/layouts/layout-horizontal/components/Menu/Popover.vue";

    export default {
        components: {
            BFormTags,
            Popover,
            CheckIcon: () => import("@core/assets/icons/check-icon.svg"),
            SearchIcon: () => import("@core/assets/icons/search-icon.svg"),
            ArrowDown: () => import("@core/assets/icons/arrow-down-orange.svg"),
            LinkIcon: () => import("@core/assets/icons/link-icon.svg"),
            EyeIcon: () => import("@core/assets/icons/eye-icon.svg"),
            UnLinkIcon: () => import("@core/assets/icons/unlink-icon.svg")
        },
        props: {
            flagToUnCheck: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                forceRenderSearchInput: true,
                forceRenderLinkResource: true,
                forceRenderResource: true,
                selectedFilter: "DepartmentPage.All",
                typeList: [
                    {
                        title: "DepartmentPage.All",
                        click: () => {
                            this.selectedFilter = "DepartmentPage.All";
                        }
                    }
                ],
                resourceInfo: {},
                filteredList: [],
                filter: {
                    textFilter: []
                },
                allResourceChecked: false
            };
        },
        mounted() {
            this.UPDATE_RESOURCES_TO_LINK_LIST([
                { id: 1, name: "teste", code: 1234 },
                { id: 2, name: "teste 2", code: 1234 },
                { id: 3, name: "teste 3", code: 1234 },
                { id: 4, name: "teste 4", code: 1234 },
                { id: 5, name: "teste 5", code: 1234 },
                { id: 6, name: "teste 6", code: 1234 },
                { id: 7, name: "teste 7", code: 1234 },
                { id: 8, name: "teste 8", code: 1234 },
                { id: 9, name: "teste 9", code: 1234 },
                { id: 10, name: "teste 10", code: 1234 },
                { id: 11, name: "teste 11", code: 1234 }
            ]);
            this.UPDATE_RESOURCES_LIST([
                { id: 1, name: "teste", code: 1234, type: ["Máquina"] },
                {
                    id: 2,
                    name: "teste 2",
                    code: 1234,
                    type: ["Teste", "Máquina", "Escavadeira", "TI"]
                },
                { id: 3, name: "teste 3", code: 1234, type: ["Máquina"] },
                { id: 4, name: "teste 4", code: 1234, type: ["Máquina"] },
                { id: 5, name: "teste 5", code: 1234, type: ["Máquina"] },
                { id: 6, name: "teste 6", code: 1234, type: ["Máquina"] },
                { id: 7, name: "teste 7", code: 1234, type: ["Máquina"] },
                { id: 8, name: "teste 8", code: 1234, type: ["Máquina"] },
                { id: 9, name: "teste 9", code: 1234, type: ["Máquina"] },
                { id: 10, name: "teste 10", code: 1234, type: ["Máquina"] },
                { id: 11, name: "teste 11", code: 1234, type: ["Máquina"] }
            ]);
            const resourcesToLink = this.calendarSettings.resources.modal.resourcesToLink;
            const resourcesList = this.calendarSettings.resources.resourcesList;
            this.filteredList = this.calendarSettings.modalEvents.eventId
                ? resourcesList
                : resourcesToLink;
            this.updateFilterByFunction(this.selectedFilter);
            this.getAllTypeFilter();
        },
        methods: {
            ...mapMutations("calendar", [
                "UPDATE_RESOURCES_LIST",
                "UPDATE_RESOURCES_TO_LINK_LIST",
                "RESET_RESOURCES",
                "UPDATE_MODAL_RESOURCE_INFO_TOGGLE",
                "UPDATE_MODAL_RESOURCES_TOGGLE",
                "UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE"
            ]),
            updateFilter(e) {
                const resourcesToLink = this.calendarSettings.resources.modal.resourcesToLink;
                const resourcesList = this.calendarSettings.resources.resourcesList;
                this.filteredList = this.calendarSettings.modalEvents.eventId
                    ? resourcesList
                    : resourcesToLink;
                if (e.length === 0) return;

                const arr = this.filteredList;

                let filteredArray = [];
                e.forEach((filterValue) => {
                    const regex = new RegExp(filterValue.toLowerCase(), "i");
                    const filteredItems = arr.filter((item) => {
                        return regex.test(item.name.toLowerCase()) || regex.test(item.code);
                    });
                    filteredArray = filteredArray.concat(filteredItems);
                });

                this.filteredList = filteredArray;
            },
            openLinkModal() {
                this.unCheckAllResource();
                this.$emit("onLinkResource");
                this.UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(false);
                this.UPDATE_MODAL_RESOURCE_INFO_TOGGLE(false);
                this.UPDATE_MODAL_RESOURCES_TOGGLE(true);
            },
            unCheckAllResource() {
                if (this.calendarSettings.resources.resourcesList.length) {
                    this.calendarSettings.resources.resourcesList.map((e) => {
                        e["checked"] = false;
                    });
                }
                this.allResourceChecked = false;
                this.forceRenderResourceList();
                this.UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(false);
            },
            openInfoResource(item, elem) {
                this.resourceInfo = item;
                const resourcesList = this.calendarSettings.resources.resourcesList;
                const isNoActionDefault = elem.target.closest(".__no-action-default");
                const hasChecked = resourcesList.some((item) => item.checked);

                if (isNoActionDefault) return;

                if (hasChecked && !item.checked) {
                    item.checked = true;
                } else if (item.checked) {
                    item.checked = false;
                    this.allResourceChecked = false;
                    !resourcesList.some((item) => item.checked) &&
                        this.UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(false);
                } else {
                    this.UPDATE_MODAL_RESOURCE_INFO_TOGGLE(true);
                    this.UPDATE_MODAL_RESOURCES_TOGGLE(false);
                }
                this.$emit("openInfoResource", item);
                this.verifyAllChecked();
                this.forceRenderResourceList();
            },
            onUnlinkResource() {
                this.$emit("onUnlinkResource");
            },
            unLinkResource(item) {
                this.toggleResourceLinked(item);
            },
            forceRenderResourceList() {
                this.forceRenderResource = false;
                this.$nextTick().then(() => {
                    this.forceRenderResource = true;
                });
            },
            toggleResourceLinked(elem) {
                const resourcesList = this.calendarSettings.resources.resourcesList;
                if (!elem.checked) {
                    elem["checked"] = true;
                    this.UPDATE_MODAL_RESOURCE_INFO_TOGGLE(false);
                    this.UPDATE_MODAL_RESOURCES_TOGGLE(false);
                    this.UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(true);
                } else {
                    elem["checked"] = false;
                    this.allResourceChecked = false;
                    const hasChecked = resourcesList.some((item) => !!item.checked);
                    hasChecked
                        ? this.UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(true)
                        : this.UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(false);
                }
                this.verifyAllChecked();
                this.forceRenderResourceList();
            },
            updateFilterByFunction(selectedType) {
                const resourcesList = this.calendarSettings.resources.resourcesList;
                this.filteredList =
                    selectedType === "DepartmentPage.All"
                        ? resourcesList
                        : resourcesList.filter((resource) =>
                              Array.isArray(resource.type)
                                  ? resource.type.includes(selectedType)
                                  : resource.type === selectedType
                          );
            },
            getAllTypeFilter() {
                const getAllTypes = this.filteredList.flatMap((item) => item.type);
                const uniqueTypes = new Set(getAllTypes.flat());

                this.typeList = [
                    {
                        title: "DepartmentPage.All",
                        click: () => {
                            this.selectedFilter = "DepartmentPage.All";
                        }
                    },
                    ...Array.from(uniqueTypes).map((type) => ({
                        title: type,
                        click: () => {
                            this.selectedFilter = type;
                        }
                    }))
                ];
            },
            toggleCheckbox(elem) {
                if (!elem.checked) {
                    elem["checked"] = true;
                } else {
                    elem["checked"] = false;
                    this.allResourceChecked = false;
                }
                this.verifyAllChecked();
                this.forceRenderLinkResourceList();
            },
            checkAllResource() {
                this.filteredList.map((e) => {
                    if (this.allResourceChecked) {
                        e["checked"] = false;
                        this.UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(false);
                    } else {
                        e["checked"] = true;
                        this.UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(true);
                    }
                });
                this.verifyAllChecked();
                this.forceRenderLinkResourceList();
            },
            verifyAllChecked() {
                const hasUnChecked = this.filteredList.find((e) => !e.checked);
                this.allResourceChecked = hasUnChecked ? false : true;
            },
            forceRenderLinkResourceList() {
                this.forceRenderLinkResource = false;
                this.$nextTick().then(() => {
                    this.forceRenderLinkResource = true;
                });
            },
            forceRenderSearchInputComponent() {
                this.forceRenderSearchInput = false;
                this.$nextTick().then(() => {
                    this.forceRenderSearchInput = true;
                });
            }
        },
        computed: {
            ...mapState("calendar", ["calendarSettings", "isModalResourceInfoOpen"])
        },
        watch: {
            selectedFilter(v) {
                this.filter.textFilter = [];
                this.unCheckAllResource();
                this.updateFilterByFunction(v);
            },
            flagToUnCheck() {
                this.unCheckAllResource();
            }
        },
        beforeDestroy() {
            this.RESET_RESOURCES();
        }
    };
</script>

<style lang="scss">
    .link-resource-body {
        padding: 16px;
        height: 100%;

        .text-block {
            .title {
                color: #4c4541;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                padding-bottom: 8px;
            }

            .subtitle {
                color: #998f8a;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                padding-bottom: 8px;
            }
        }

        .search-block {
            .text {
                color: #4c4541;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;

                @media (max-width: 480px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .search-input {
                display: flex;
                align-items: center;
                padding: 8px 0;

                .div-custom-input {
                    display: flex;
                    align-items: center;
                    border-radius: 4px;
                    width: 100%;
                    overflow: hidden;
                    border: 1px solid #cfc4be;
                    .icon-block {
                        display: flex;
                        align-items: center;
                        padding-left: 14px;
                        height: 14px;
                        .search-icon {
                            width: 14px;
                            height: 14px;
                            fill: #cfc4be;
                        }
                    }
                    .input-search-resources-calendar {
                        width: 100% !important;
                        border: none;
                        overflow: hidden;

                        &.focus {
                            box-shadow: none;
                        }
                        &.b-form-tags {
                            padding: 0 !important;
                            display: flex;
                            align-items: center !important;
                            .b-form-tags-list {
                                width: 100% !important;
                                margin-left: 14px !important;
                                margin-top: 0;
                                height: 100% !important;
                                flex-wrap: nowrap !important;
                                display: flex !important;
                                align-items: center !important;
                                overflow: scroll;
                                overflow-y: hidden;
                                cursor: grabbing;
                                cursor: -webkit-grabbing;

                                &::-webkit-scrollbar {
                                    display: none !important;
                                }
                                .b-form-tags-field {
                                    margin: 9px 0;
                                }
                            }
                        }

                        .b-form-tags-button {
                            display: none;
                        }

                        .b-form-tag {
                            background-color: #974900;
                            margin-right: 0.6rem;
                            padding: 2px 8px !important;
                            font-size: 13px;
                        }
                    }
                }
            }
            .label-title {
                color: #4c4541;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                padding-bottom: 8px;
            }
        }

        .action-block {
            display: flex;
            justify-content: space-between;
            padding: 8px 0;

            .select-all {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 0 16px;

                .text {
                    color: #4c4541;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                }
            }

            .function-filter .filter {
                color: #974900;
                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.15px;
            }

            .function-filter {
                display: flex;
                align-items: center;
                gap: 8px;
                color: #4c4541;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;

                .filter {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 4px 10px;
                    border-radius: 5px;
                    cursor: pointer;

                    .arrow-down {
                        width: 12px;
                        height: 12px;
                    }

                    &:hover {
                        background: #ffede2;
                    }

                    &:active {
                        background: #ffdbc4;
                    }
                }
            }

            @media (max-width: 480px) {
                flex-wrap: wrap;
            }
        }

        .resource-block {
            padding-bottom: 16px;
            overflow: auto;
            height: calc(100vh - 268px);

            &:has(.block-no-results) {
                overflow-y: hidden;
            }

            .block-no-results {
                height: 100%;
                padding: 16px 0;
                .no-results {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;
                    border-radius: 6px;
                    border: 1px solid #cfc4be;

                    svg {
                        width: 16px;
                        height: 16px;
                        fill: #998f8a;
                    }

                    .text {
                        margin: 0;
                        color: #998f8a;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                    }

                    @media (max-width: 480px) {
                        svg {
                            width: 12px;
                            height: 12px;
                        }
                        .text {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
            }

            .user-info-block {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 7.5px 16px;
                overflow: hidden;
                border-bottom: 1px solid #eee;
                background: #fff;

                .picture img {
                    width: 40px;
                    border-radius: 50%;

                    @media (max-width: 480px) {
                        width: 30px;
                    }
                }

                .check-resource {
                    display: flex;

                    .checkbox-container-resource {
                        margin: 0;
                    }
                }

                .text-block {
                    overflow: hidden;
                    .resource-name {
                        color: #4c4541;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        white-space: nowrap;
                        word-break: break-all;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .resource-code {
                        color: #998f8a;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                    }
                }
            }

            &::-webkit-scrollbar {
                width: 7px;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #cfc4be;
                border-radius: 9px;
            }
        }

        .checkbox-container-resource {
            display: flex;
            background-color: #fff;
            border: 1px solid #cfc4be;
            border-radius: 3px;
            cursor: pointer;
            width: 18px;
            height: 18px;

            .icon-checkbox {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2px;
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
            &.checked {
                background-color: #974900;
                display: block;
            }
        }
    }

    .linked-resources {
        padding: 16px;
        height: 100%;
        .select-all {
            display: flex;
            gap: 8px;

            .text {
                color: #4c4541;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;

                @media (max-width: 480px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .action-block {
            display: flex;
            justify-content: space-between;
            padding: 8px;

            .link-resource,
            .unlink-resource {
                display: flex;
                align-items: center;
                padding: 4px 14px;
                gap: 6px;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background: #ffede2;
                }

                &:active {
                    background: #ffdbc4;
                }
            }

            .link-resource,
            .unlink-resource,
            .function-filter .filter {
                color: #974900;
                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.15px;
            }

            .function-filter {
                display: flex;
                align-items: center;
                gap: 8px;
                color: #4c4541;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;

                .filter {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 4px 10px;
                    border-radius: 5px;
                    cursor: pointer;

                    .arrow-down {
                        width: 12px;
                        height: 12px;
                    }

                    &:hover {
                        background: #ffede2;
                    }

                    &:active {
                        background: #ffdbc4;
                    }
                }

                @media (max-width: 480px) {
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            @media (max-width: 480px) {
                flex-wrap: wrap;
            }
        }

        .resource-list {
            padding-bottom: 16px;
            &.enable-scroll {
                overflow-y: auto;
                max-height: calc(100vh - 200px);
            }

            .resource-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #eee;
                background: #fff;
                cursor: pointer;

                .user-info-block {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 7.5px 16px;
                    overflow: hidden;

                    .picture {
                        img {
                            width: 40px;
                            border-radius: 50%;
                            @media (max-width: 480px) {
                                width: 30px;
                            }
                        }
                    }

                    .check-resource {
                        display: none;
                        padding: 9px 0;

                        &:has(.checked) {
                            display: flex;
                        }

                        .checkbox-container-resources {
                            margin: 0;
                        }

                        @media (max-width: 480px) {
                            padding: 6px 0;
                        }
                    }

                    .text-block {
                        overflow: hidden;
                        .resource-name {
                            color: #4c4541;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            white-space: nowrap;
                            word-break: break-all;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media (max-width: 480px) {
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                        .resource-code {
                            color: #998f8a;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;

                            @media (max-width: 480px) {
                                font-size: 10px;
                                line-height: 14px;
                            }
                        }
                    }
                }

                .view-block {
                    display: none;
                    gap: 8px;
                    align-items: center;
                    padding-right: 16px;
                    color: #974900;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                }

                .roles-block {
                    display: flex;
                    justify-content: flex-end;
                    gap: 8px;
                    padding-right: 16px;
                    .role-tag {
                        white-space: nowrap;
                        border-radius: 4px;
                        background: #eceff1;
                        padding-inline: 9px;
                        color: #607d8b;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 16px;
                    }
                }

                .unlink {
                    display: none;
                    cursor: pointer;
                    padding: 8px;
                    margin-right: 16px;
                    border-radius: 5px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.--is-viewing {
                    background: #ffede2;
                    border-color: #974900;
                    .user-info-block {
                        .text-block {
                            .resource-code,
                            .resource-name {
                                color: #974900;
                            }
                        }
                    }

                    .view-block {
                        display: flex;
                    }

                    .roles-block {
                        display: none;
                    }

                    &.is-mobile {
                        border-bottom: 1px solid #eee;
                        background: #fff;
                        .view-block {
                            display: none;
                        }

                        .roles-block {
                            display: flex;
                        }
                    }
                }

                &:hover,
                &:active {
                    &:has(.checked) {
                        border-color: #eee;
                        background: #fff;
                        .user-info-block {
                            .picture {
                                display: none;
                            }
                            .check-resource {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .text-block {
                                .resource-name {
                                    color: #4c4541;
                                }
                                .resource-code {
                                    color: #998f8a;
                                }
                            }
                        }
                        .view-block {
                            display: none;
                        }
                        .roles-block {
                            display: flex;
                        }
                        .unlink {
                            display: none;
                        }
                    }

                    &.is-mobile {
                        .view-block {
                            display: none;
                        }

                        .roles-block {
                            display: none;
                        }
                    }

                    background: #ffede2;
                    border-color: #974900;
                    .user-info-block {
                        .picture {
                            display: none;
                        }
                        .check-resource {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .text-block {
                            .resource-code,
                            .resource-name {
                                color: #974900;
                            }
                        }
                    }
                    .view-block {
                        padding-right: 8px;
                    }
                    .roles-block {
                        display: none;
                    }
                    .unlink {
                        display: flex;
                    }
                }
            }

            .block-no-results {
                padding-block: 16px;
                .no-results {
                    height: calc(100vh - 277px);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;
                    border-radius: 6px;
                    border: 1px solid #cfc4be;

                    svg {
                        width: 16px;
                        height: 16px;
                        fill: #998f8a;
                    }

                    .text {
                        margin: 0;
                        color: #998f8a;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                    }

                    @media (max-width: 480px) {
                        svg {
                            width: 12px;
                            height: 12px;
                        }
                        .text {
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
            }

            &::-webkit-scrollbar {
                width: 7px;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #cfc4be;
                border-radius: 9px;
            }
        }

        .checkbox-container-resources {
            display: flex;
            background-color: #fff;
            border: 1px solid #cfc4be;
            border-radius: 3px;
            margin-top: 4px;
            cursor: pointer;
            width: 18px;
            height: 18px;

            .icon-checkbox {
                display: flex !important;
                align-items: center;
                justify-content: center;
                margin-top: 2px;
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
            &.checked {
                background-color: #974900;
                display: block;
            }
        }
    }

    @media (max-width: 707px) {
        .link-resource-body .resource-block {
            height: calc(100vh - 340px);
        }
    }
    @media (max-width: 480px) {
        .link-resource-body {
            .text-block {
                .title {
                    font-size: 14px;
                }

                .subtitle {
                    font-size: 12px;
                }
            }

            .action-block {
                padding-top: 0;
                .select-all {
                    .text {
                        font-size: 12px;
                    }
                }
            }

            .resource-block {
                height: calc(100vh - 315px);

                .user-info-block .text-block {
                    .resource-name {
                        font-size: 12px;
                    }
                    .resource-code {
                        font-size: 10px;
                    }
                }
            }
        }
    }
</style>
