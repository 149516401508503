import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";

import i18n from "@/libs/i18n";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/acl";
import DisableAutocomplete from "vue-disable-autocomplete";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import VueMoment from "vue-moment";
import VueApexCharts from "vue-apexcharts";
import VueCookies from "vue-cookies-reactive";
import VueMask from "v-mask";
import VueGeolocation from "vue-browser-geolocation";
import ToastificationContent from "./@core/components/toastification/ToastificationContent.vue";

// Registrando o componente globalmente

import "./redirects";
import "@/prototypes/app.js";

Vue.component("ToastificationContent", ToastificationContent);
Vue.use(VueGeolocation);
Vue.use(VueMask);
Vue.use(VueCookies);
Vue.use(VueApexCharts);
Vue.use(Donut);
Vue.use(DisableAutocomplete);
Vue.use(VueMoment);
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

// import dc charts css
require("../node_modules/dc/dist/style/dc.css");

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
}).$mount("#app");
