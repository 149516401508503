var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"standard-calendar"}},[(_vm.calendarList.length === 0 && !_vm.noItemsFound)?_c('div',{staticClass:"h-100"},[_c('div',{staticClass:"no-items h-100"},[_c('EmptyBoxIcon',{staticClass:"empty-box"}),_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("CalendarsPage.NoCalendarsTitle")))]),_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.NoCalendarsSubtitle"))+" ")]),_c('b-button',{staticClass:"btn-new-calendar",on:{"click":function($event){_vm.permissions.find(function (e) { return e.type === 'create'; }) && _vm.newCalendar()}}},[_c('AddIcon',{staticClass:"add-icon"}),_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.NewCalendar"))+" ")],1)],1)]):_c('div',{staticClass:"h-100"},[_c('div',{staticClass:"header-block"},[_c('div',{staticClass:"first-line"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("Breadcrumbs.Calendars"))+" ")]),_c('div',{staticClass:"add-calendars"},[_c('b-button',{staticClass:"btn-new-calendar",on:{"click":function($event){_vm.permissions.find(function (e) { return e.type === 'create'; }) && _vm.newCalendar()}}},[_c('AddIcon',{staticClass:"add-icon"}),_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.NewCalendar"))+" ")],1)],1)]),_c('div',{staticClass:"second-line"},[_c('div',{staticClass:"search-input"},[_c('div',{staticClass:"div-custom-input input__materials"},[_c('div',{staticClass:"icon-block"},[_c('SearchIcon',{staticClass:"search-icon"})],1),_c('b-form-tags',{ref:"formTagMaterials",staticClass:"input-search-calendars",attrs:{"duplicate-tag-text":"","placeholder":_vm.filter.textFilter.length === 0
                                    ? _vm.$t('SearchPlaceholderDefault')
                                    : '',"remove-on-delete":"","add-on-change":""},on:{"input":function($event){return _vm.getCalendars()}},model:{value:(_vm.filter.textFilter),callback:function ($$v) {_vm.$set(_vm.filter, "textFilter", $$v)},expression:"filter.textFilter"}})],1)])])]),_c('div',{staticClass:"body-block"},[_c('table-default',{attrs:{"id":"table-calendars-standard","rows":_vm.calendarList,"fields":_vm.fields,"stickyHeader":false,"striped":true,"searchFilter":false,"filter":_vm.filter},on:{"updatePageNumber":_vm.updatePageNumber,"updatepageSize":_vm.updatepageSize},scopedSlots:_vm._u([{key:"head(name)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(data.label))+" ")]),_c('div',{staticClass:"d-flex flex-column sortable-icons align-items-end"},[_c('ArrowDown',{staticClass:"rotate",style:(_vm.filter.sort === 'asc' && _vm.filter.fieldSort === data.field.key
                                        ? 'fill: #998F8A'
                                        : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'asc')}}}),_c('ArrowDown',{style:(_vm.filter.sort === 'desc' &&
                                    _vm.filter.fieldSort === data.field.key
                                        ? 'fill: #998F8A'
                                        : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'desc')}}})],1)])]}},{key:"head(duration)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(data.label))+" ")]),_c('div',{staticClass:"d-flex flex-column sortable-icons align-items-end"},[_c('ArrowDown',{staticClass:"rotate",style:(_vm.filter.sort === 'asc' && _vm.filter.fieldSort === data.field.key
                                        ? 'fill: #998F8A'
                                        : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'asc')}}}),_c('ArrowDown',{style:(_vm.filter.sort === 'desc' &&
                                    _vm.filter.fieldSort === data.field.key
                                        ? 'fill: #998F8A'
                                        : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'desc')}}})],1)])]}},{key:"head(referenceDate)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(data.label))+" ")]),_c('div',{staticClass:"d-flex flex-column sortable-icons align-items-end"},[_c('ArrowDown',{staticClass:"rotate",style:(_vm.filter.sort === 'asc' && _vm.filter.fieldSort === data.field.key
                                        ? 'fill: #998F8A'
                                        : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'asc')}}}),_c('ArrowDown',{style:(_vm.filter.sort === 'desc' &&
                                    _vm.filter.fieldSort === data.field.key
                                        ? 'fill: #998F8A'
                                        : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'desc')}}})],1)])]}},{key:"head(resourcesCount)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(data.label))+" ")]),_c('div',{staticClass:"d-flex flex-column sortable-icons align-items-end"},[_c('ArrowDown',{staticClass:"rotate",style:(_vm.filter.sort === 'asc' && _vm.filter.fieldSort === data.field.key
                                        ? 'fill: #998F8A'
                                        : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'asc')}}}),_c('ArrowDown',{style:(_vm.filter.sort === 'desc' &&
                                    _vm.filter.fieldSort === data.field.key
                                        ? 'fill: #998F8A'
                                        : 'opacity: 0.5'),on:{"click":function($event){return _vm.updateFieldAndSort(data.field.key, 'desc')}}})],1)])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"action-block"},[_c('OptionIcon',{staticClass:"action-svg",attrs:{"id":'action-' + data.index,"tabindex":"0"},on:{"click":function($event){return _vm.getItem(data.item)}}}),_c('Popover',{attrs:{"items":_vm.actionList,"target":'action-' + data.index,"placement":"bottomright","custom-position":"action-button","triggers":"focus"}})],1)]}},{key:"cell(duration)",fn:function(data){return [_c('div',{staticClass:"duration-block"},[_vm._v(" "+_vm._s((" " + (_vm.$t(data.item.duration)) + " " + (_vm.$t("ProductionList.Days"))))+" ")])]}},{key:"cell(referenceDate)",fn:function(data){return [_c('div',{staticClass:"duration-block"},[_vm._v(" "+_vm._s(_vm.dateFormatter(data.item.referenceDate))+" ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }