<template>
    <div
        :id="id"
        :class="[
            'sys-form-input__container',
            {
                '--disabled': !!disabled,
                '--error': state === false && !disabled
            }
        ]"
    >
        <label
            v-if="label"
            class="sys-form-input__label"
        >
            {{ label }}
        </label>

        <div class="sys-form-input__wrapper">
            <slot name="prepend" />

            <input
                v-if="!textarea"
                class="sys-form-input__input"
                :placeholder="placeholder"
                :type="type"
                :disabled="disabled"
                :style="{
                    height
                }"
                :readonly="readonly"
                v-model="input"
                @input="(v) => $emit('input', v)"
                @blur="(event) => $emit('blur', event)"
                @focus="(event) => $emit('focus', event)"
                @click="(event) => $emit('click', event)"
                @keyup="(event) => $emit('keyup', event)"
                @keydown="(event) => $emit('keydown', event)"
                @keypress="(event) => $emit('keypress', event)"
            />

            <textarea
                v-if="!!textarea"
                class="sys-form-input__textarea"
                :value="value"
                :placeholder="placeholder"
                :type="type"
                :disabled="disabled"
                :rows="2"
                :style="{
                    height,
                    resize: resize ? '' : 'none'
                }"
                :readonly="readonly"
                @input="(v) => $emit('input', v)"
                @blur="$emit('blur')"
                @focus="$emit('focus')"
                @keyup="(e) => $emit('keyup', e)"
                @keydown="(e) => $emit('keydown', e)"
                @keypress="(e) => $emit('keypress', e)"
            />
        </div>

        <p
            class="sys-form-input__feedback"
            v-if="state === false && !disabled"
        >
            {{ feedback }}
        </p>
    </div>
</template>

<script>
    import { BFormInput, BFormGroup } from "bootstrap-vue";

    export default {
        components: {
            BFormInput,
            BFormGroup
        },
        props: {
            id: {
                type: String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            },
            label: {
                type: String,
                default: ""
            },
            type: {
                type: String,
                default: "text"
            },
            placeholder: {
                type: String,
                default: ""
            },
            value: {
                default: ""
            },
            state: {
                default: true
            },
            feedback: {
                type: String,
                default: ""
            },
            formatter: {
                default: null
            },
            lazyFormatter: {
                type: Boolean,
                default: false
            },
            height: {
                type: String
            },
            resize: {
                type: Boolean,
                default: false
            },
            textarea: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                input: ""
            };
        },
        watch: {
            input(n, _) {
                if (n !== this.value) {
                    this.input = this.value;
                }
            },
            value(n, o) {
                if (o != n) {
                    this.input = n;
                    return;
                }

                this.input = o;
            }
        },
        mounted() {
            this.input = this.value;
        }
    };
</script>
<style lang="scss">
    .sys-form-input__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        .sys-form-input__wrapper {
            position: relative;
            display: flex;
        }

        &.--disabled {
            .sys-form-input__input,
            .sys-form-input__textarea {
                background-color: #efefef !important;
                border: 1px solid #7e7570 !important;
                color: #7e7570 !important;

                &::placeholder {
                    color: #7e7570 !important;
                }
            }
        }

        &.--error {
            .sys-form-input__input,
            .sys-form-input__textarea {
                border: 1px solid #d32f2f !important;
            }
        }

        .sys-form-input__label {
            margin-bottom: 4px;
            color: #4c4541;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        .sys-form-input__input {
            height: 38px;
        }

        .sys-form-input__input,
        .sys-form-input__textarea {
            outline: none;
            padding: 8px 13px;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #d8d6de;
            border-radius: 0.357rem;
            display: block;
            width: 100%;

            font-size: 1rem;
            font-weight: 400;
            line-height: 1.45;
            color: #4c4541;
            margin: 0;
            transition:
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;

            &:focus {
                border-color: #974900;
                box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
                background-color: #fff;
            }

            &::placeholder {
                color: #cfc4be;
            }

            &:disabled {
                border-color: #7e7570 !important;
            }
        }

        .sys-form-input__feedback {
            margin: 1px 0 0 5px;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            color: #d32f2f;
        }
    }
</style>
