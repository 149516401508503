const HOUR_IN_MINUTES = 60;

function timeToMinutes(time) {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * HOUR_IN_MINUTES + minutes;
}

function calculateMinutes(startTime, endTime) {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const startMinutes = startHour * 60 + startMinute;
    const endMinutes = endHour * 60 + endMinute;

    let timeDifference = endMinutes - startMinutes;
    if (timeDifference < 0) {
        timeDifference = 24 * 60 - startMinutes;
    }

    return timeDifference;
}

function checkCompletedPeriods(events, days) {
    let totalMinutes = 0;
    // 23:59
    const minute = 1;
    const DAY_IN_MINUTES = 1440 - minute;

    for (let event of events) {
        // Para cada evento do dia
        totalMinutes += calculateMinutes(event.startTime, event.endTime);
    }

    return DAY_IN_MINUTES * days == totalMinutes;
}

function verifyIfHasPermission(vm) {
    const routeName = vm.$route.name;
    const permissions = vm.$store.state.calendar.permissions;

    if (routeName === "newCalendar" && !permissions.find((e) => e.type === "create")) {
        vm.$store.commit("calendar/UPDATE_BYPASS_LEAVE", true);
        vm.$router.push({ name: "standard" });
        return;
    }

    if (routeName === "editCalendar" && !permissions.find((e) => e.type === "update")) {
        vm.$store.commit("calendar/UPDATE_BYPASS_LEAVE", true);
        vm.$router.push({ name: "standard" });
        return;
    }
}

function createYearsContext(eventList) {
    const yearsContext = new Set();

    eventList?.forEach((event) => {
        const date = new Date(event.date);
        const year = date.getFullYear();

        yearsContext.add(year);
    });

    return Array.from(yearsContext);
}

export {
    timeToMinutes,
    checkCompletedPeriods,
    calculateMinutes,
    verifyIfHasPermission,
    createYearsContext
};
