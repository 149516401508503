<template>
    <div id="action-calendar">
        <ModalSettings :device="windowWidth" />

        <ModalEvents />

        <ModalPeriods />

        <ModalLinkResources
            :modalTitle="'StructurePage.LinkResource'"
            :device="windowWidth"
        />
        <Header @updateOptions="toggleOptions" />
        <div class="calendar-config">
            <div
                v-if="showOptions && windowWidth >= 1024"
                class="config-block"
            >
                <Settings />
            </div>
            <div class="period-block">
                <Periods />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import Header from "./components/header/index.vue";
    import Settings from "./components/settings/index.vue";
    import Periods from "./components/periods/index.vue";
    import ModalSettings from "./components/settings/ModalSettings.vue";
    import ModalEvents from "./components/settings/ModalEvents";
    import ModalPeriods from "./components/settings/ModalAddEditPeriod.vue";
    import ModalLinkResources from "./components/settings/ModalLinkResources.vue";
    import useCalendarPayload from "@/views/pages/calendar/actionCalendar/hooks/useCalendarPayload.js";
    import {
        getCalendarService,
        getCalendarContextService
    } from "@/views/pages/calendar/actionCalendar/services/index.js";
    import { verifyIfHasPermission } from "@/views/pages/calendar/actionCalendar/utils/index.js";

    export default {
        components: {
            Header,
            Settings,
            Periods,
            ModalSettings,
            ModalEvents,
            ModalPeriods,
            ModalLinkResources
        },
        data() {
            return {
                showOptions: true
            };
        },
        async mounted() {
            this.handleInitialRouteCalls();
        },
        async beforeRouteLeave(_to, _from, next) {
            if (this.periods.isCompletedPeriods) return next();

            try {
                await this.handleOpenIncompletedPeriodsAttentionModal();
                next();
            } catch {
                next(false);
            }
        },
        beforeDestroy() {
            this.RESET_STATES();
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_SETTINGS_TOGGLE",
                "UPDATE_MODAL_PERIODS_TOGGLE",
                "UPDATE_EXCEPTION_EVENT_TYPES_TOGGLE",
                "UPDATE_DURATION_UNITS_TOGGLE",
                "UPDATE_STATUS_TOGGLE",
                "UPDATE_PERMISSIONS_LIST",
                "RESET_STATES",
                "UPDATE_BYPASS_LEAVE"
            ]),
            async handleInitialRouteCalls() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    await getCalendarContextService(this, false);

                    const calendarId = this.$router.currentRoute.params?.id?.toString() ?? null;
                    calendarId && (await getCalendarService(this, calendarId, false));

                    verifyIfHasPermission(this);
                } catch {
                    this.UPDATE_BYPASS_LEAVE(true);
                    this.$router.push({ name: "standard" });
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async handleOpenIncompletedPeriodsAttentionModal() {
                return new Promise((resolve, reject) => {
                    this.$swal({
                        title: this.$t("IntegrationPage.attention"),
                        text: this.$t("CalendarsPage.IncompletedPeriodsAttention"),
                        showCancelButton: true,
                        showCloseButton: true,
                        cancelButtonText: this.$t("CalendarsPage.DiscardChanges"),
                        confirmButtonText: this.$t("CalendarsPage.ContinueEditing"),
                        allowOutsideClick: false,
                        customClass: {
                            container: "swal-default",
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1"
                        },
                        buttonsStyling: false
                    }).then((result) => {
                        if (!result.value) {
                            return resolve();
                        }

                        reject();
                    });
                });
            },
            async getContext() {
                const currentLanguage = this.$cookies.get("userInfo").language;
                await this.$http2
                    .get(`/api/myconfig/calendar/context?lang=${currentLanguage}`)
                    .then((response) => {
                        const eventTypes = response.data.data.exceptionEventTypes;
                        const durationUnits = response.data.data.durationUnits;
                        const statusList = response.data.data.status.map((e) => ({
                            ...e,
                            text: e.name
                        }));
                        const permissionsList = response.data.data.permissions;
                        this.UPDATE_PERMISSIONS_LIST(permissionsList);
                        this.UPDATE_EXCEPTION_EVENT_TYPES_TOGGLE(eventTypes);
                        this.UPDATE_DURATION_UNITS_TOGGLE(durationUnits);
                        this.UPDATE_STATUS_TOGGLE(statusList);
                    });
            },
            redirect(path) {
                const isSamePage = String(this.$router.currentRoute.name) === String(path);
                if (!isSamePage) {
                    this.$router.push({ name: path });
                }
            },
            toggleOptions() {
                this.showOptions = !this.showOptions;
                if (this.windowWidth <= 1023) {
                    this.UPDATE_MODAL_SETTINGS_TOGGLE(!this.isModalSettingsOpen);
                }
            }
        },
        computed: {
            ...mapState("app", {
                windowWidth: "windowWidth"
            }),
            ...mapState("calendar", {
                calendarSettings: "calendarSettings",
                isModalSettingsOpen: "isModalSettingsOpen",
                periods: "periods",
                permissions: "permissions"
            })
        }
    };
</script>

<style lang="scss">
    .calendar-config {
        display: flex;
        gap: 30px;
        width: 100%;
        padding-top: 24px;

        .config-block,
        .period-block {
            border-radius: 6px;
            background: #fff;
            box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
        }

        .config-block {
            width: 339px;
            max-width: 339px;
            min-width: 339px;
        }

        .period-block {
            width: 100%;
        }
    }

    @media (min-width: 1024px) {
        .calendar-config {
            max-height: calc(100dvh - 49px - 60px - 24px - 62px);
        }
    }
</style>
