<template>
    <div class="sidebar-modal-events__recurrence-container">
        <div class="sidebar-modal-events__recurrence-header">
            <div class="sidebar-modal-events__recurrence-header-left">
                <span class="sidebar-modal-events__recurrence-title">
                    {{ $t("CalendarsPage.Recurrence") }}
                </span>
            </div>

            <div class="sidebar-modal-events__recurrence-header-right">
                <sys-switch
                    size="md"
                    :value="calendarSettings.modalEvents.fields.isRecurrent"
                    @input="handleInputRecurrent"
                />
            </div>
        </div>

        <div
            :class="[
                'sidebar-modal-events__recurrence-content',
                { '--hide': !calendarSettings.modalEvents.fields.isRecurrent }
            ]"
        >
            <div class="sidebar-modal-events__recurrence-start">
                <span class="sidebar-modal-events__content-label">
                    {{ $t("CalendarsPage.Starts") }}
                </span>
                <div class="sidebar-modal-events__recurrence-start-right">
                    <sys-form-datepicker
                        id="recurrence-starts"
                        type="date"
                        :value="calendarSettings.modalEvents.fields.recurrence.startDate"
                        :state="calendarSettings.modalEvents.states.recurrence.startDate.valid"
                        :feedback="
                            $t(calendarSettings.modalEvents.states.recurrence.startDate.feedback)
                        "
                        @input="(value) => UPDATE_MODAL_EVENTS_RECURRENCE_START_DATE_FIELD(value)"
                    />
                </div>
            </div>

            <div class="sidebar-modal-events__recurrence-repeat">
                <span class="sidebar-modal-events__content-label">
                    {{ $t("CalendarsPage.RepeatEvery") }}
                </span>
                <div class="sidebar-modal-events__recurrence-repeat-right">
                    <div class="sidebar-modal-events__recurrence-repeat-initial-fields">
                        <div class="sidebar-modal-events__recurrence-repeat-quantity-field">
                            <sys-form-input
                                :value="calendarSettings.modalEvents.fields.recurrence.quantity"
                                :state="
                                    calendarSettings.modalEvents.states.recurrence.quantity.valid
                                "
                                :placeholder="$t('TypeHere')"
                                @input="
                                    (e) =>
                                        UPDATE_MODAL_EVENTS_RECURRENCE_QUANTITY_FIELD(
                                            e.target.value
                                        )
                                "
                                @keypress="(e) => !/[0-9]/.test(e.key) && e.preventDefault()"
                            />
                        </div>

                        <div class="sidebar-modal-events__recurrence-repeat-frequency-field">
                            <sys-form-select
                                :placeholder="$t('Select')"
                                :value="calendarSettings.modalEvents.fields.recurrence.frequency"
                                :options="context.durationUnits"
                                :state="
                                    calendarSettings.modalEvents.states.recurrence.frequency.valid
                                "
                                :feedback="
                                    $t(
                                        calendarSettings.modalEvents.states.recurrence.frequency
                                            .feedback
                                    )
                                "
                                @input="handleInputFrequency"
                                translate
                            />
                        </div>
                    </div>

                    <div
                        class="sidebar-modal-events__recurrence-repeat-day-fields"
                        v-show="
                            calendarSettings.modalEvents.fields.recurrence.frequency ==
                                frequency.year ||
                            calendarSettings.modalEvents.fields.recurrence.frequency ==
                                frequency.month
                        "
                    >
                        <div class="sidebar-modal-events__recurrence-repeat-day-fields-left">
                            <sys-radio-button
                                :label="$t('CalendarsPage.OnThe')"
                                :value="calendarSettings.modalEvents.fields.recurrence.isOnThe"
                                @input="
                                    (value) => {
                                        UPDATE_MODAL_EVENTS_RECURRENCE_IS_ON_THE(value);
                                        UPDATE_MODAL_EVENTS_RECURRENCE_IS_ON(!value);
                                    }
                                "
                            />
                        </div>

                        <div class="sidebar-modal-events__recurrence-repeat-day-colums-fields">
                            <div class="sidebar-modal-events__recurrence-repeat-day-field">
                                <sys-form-input
                                    :value="
                                        calendarSettings.modalEvents.fields.recurrence.daysOfMonth
                                            .length
                                            ? calendarSettings.modalEvents.fields.recurrence
                                                  .daysOfMonth[0]
                                            : ''
                                    "
                                    @input="
                                        (e) =>
                                            e.target.value
                                                ? UPDATE_MODAL_EVENTS_RECURRENCE_DAYS_OF_MONTH_FIELD(
                                                      Number(e.target.value) > 31
                                                          ? ['31']
                                                          : [e.target.value]
                                                  )
                                                : UPDATE_MODAL_EVENTS_RECURRENCE_DAYS_OF_MONTH_FIELD(
                                                      []
                                                  )
                                    "
                                    @keypress="(e) => !/[0-9]/.test(e.key) && e.preventDefault()"
                                    :state="
                                        calendarSettings.modalEvents.states.recurrence.daysOfMonth
                                            .valid
                                    "
                                    :placeholder="$t('TypeHere')"
                                    :disabled="calendarSettings.modalEvents.fields.recurrence.isOn"
                                />
                            </div>

                            <div
                                class="sidebar-modal-events__recurrence-repeat-month-field"
                                v-show="
                                    calendarSettings.modalEvents.fields.recurrence.frequency ==
                                    frequency.year
                                "
                            >
                                <sys-form-select
                                    :placeholder="$t('Select')"
                                    :value="
                                        calendarSettings.modalEvents.fields.recurrence.months.length
                                            ? calendarSettings.modalEvents.fields.recurrence
                                                  .months[0]
                                            : null
                                    "
                                    :options="monthsList"
                                    :disabled="calendarSettings.modalEvents.fields.recurrence.isOn"
                                    @input="
                                        (value) =>
                                            UPDATE_MODAL_EVENTS_RECURRENCE_MONTHS_FIELD([value])
                                    "
                                    translate
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        class="sidebar-modal-events__recurrence-repeat-on-fields"
                        v-show="
                            calendarSettings.modalEvents.fields.recurrence.frequency ==
                                frequency.year ||
                            calendarSettings.modalEvents.fields.recurrence.frequency ==
                                frequency.month
                        "
                    >
                        <div class="sidebar-modal-events__recurrence-repeat-on-fields-left">
                            <sys-radio-button
                                :label="$t('CalendarsPage.On')"
                                :value="calendarSettings.modalEvents.fields.recurrence.isOn"
                                @input="
                                    (value) => {
                                        UPDATE_MODAL_EVENTS_RECURRENCE_IS_ON(value);
                                        UPDATE_MODAL_EVENTS_RECURRENCE_IS_ON_THE(!value);
                                    }
                                "
                            />
                        </div>

                        <div class="sidebar-modal-events__recurrence-repeat-on-column-fields">
                            <sys-form-select
                                :placeholder="$t('Select')"
                                :value="
                                    calendarSettings.modalEvents.fields.recurrence.weeksOfMonth
                                        .length
                                        ? calendarSettings.modalEvents.fields.recurrence
                                              .weeksOfMonth[0]
                                        : null
                                "
                                :options="weeksOfMonthList"
                                :disabled="calendarSettings.modalEvents.fields.recurrence.isOnThe"
                                @input="
                                    (value) =>
                                        UPDATE_MODAL_EVENTS_RECURRENCE_WEEKS_OF_MONTH_FIELD([value])
                                "
                                translate
                            />

                            <sys-form-select
                                :placeholder="$t('Select')"
                                :value="
                                    calendarSettings.modalEvents.fields.recurrence.daysOfWeek.length
                                        ? calendarSettings.modalEvents.fields.recurrence
                                              .daysOfWeek[0]
                                        : null
                                "
                                :options="daysOfWeekList"
                                :disabled="calendarSettings.modalEvents.fields.recurrence.isOnThe"
                                @input="
                                    (value) =>
                                        UPDATE_MODAL_EVENTS_RECURRENCE_DAYS_OF_WEEK_FIELD([value])
                                "
                                translate
                            />
                            <sys-form-select
                                v-show="
                                    calendarSettings.modalEvents.fields.recurrence.frequency ==
                                    frequency.year
                                "
                                :placeholder="$t('Select')"
                                :value="
                                    calendarSettings.modalEvents.fields.recurrence.months.length
                                        ? calendarSettings.modalEvents.fields.recurrence.months[0]
                                        : null
                                "
                                :options="monthsList"
                                :disabled="calendarSettings.modalEvents.fields.recurrence.isOnThe"
                                @input="
                                    (value) => UPDATE_MODAL_EVENTS_RECURRENCE_MONTHS_FIELD([value])
                                "
                                translate
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="sidebar-modal-events__recurrence-on-every"
                v-show="calendarSettings.modalEvents.fields.recurrence.frequency == frequency.week"
            >
                <span class="sidebar-modal-events__content-label">
                    {{ $t("CalendarsPage.OnEvery") }}
                </span>

                <div class="sidebar-modal-events__recurrence-on-every-right">
                    <sys-checkbox
                        size="md"
                        :label="$t('CalendarsPage.WeekDays.sunday')"
                        @input="handleInputCheckboxDaysOfWeek(daysOfWeek.sunday)"
                        :value="
                            calendarSettings.modalEvents.fields.recurrence.daysOfWeek.includes(
                                daysOfWeek.sunday
                            )
                        "
                    />
                    <sys-checkbox
                        size="md"
                        :label="$t('CalendarsPage.WeekDays.monday')"
                        @input="handleInputCheckboxDaysOfWeek(daysOfWeek.monday)"
                        :value="
                            calendarSettings.modalEvents.fields.recurrence.daysOfWeek.includes(
                                daysOfWeek.monday
                            )
                        "
                    />
                    <sys-checkbox
                        size="md"
                        :label="$t('CalendarsPage.WeekDays.tuesday')"
                        @input="handleInputCheckboxDaysOfWeek(daysOfWeek.tuesday)"
                        :value="
                            calendarSettings.modalEvents.fields.recurrence.daysOfWeek.includes(
                                daysOfWeek.tuesday
                            )
                        "
                    />
                    <sys-checkbox
                        size="md"
                        :label="$t('CalendarsPage.WeekDays.wednesday')"
                        @input="handleInputCheckboxDaysOfWeek(daysOfWeek.wednesday)"
                        :value="
                            calendarSettings.modalEvents.fields.recurrence.daysOfWeek.includes(
                                daysOfWeek.wednesday
                            )
                        "
                    />
                    <sys-checkbox
                        size="md"
                        :label="$t('CalendarsPage.WeekDays.thursday')"
                        @input="handleInputCheckboxDaysOfWeek(daysOfWeek.thursday)"
                        :value="
                            calendarSettings.modalEvents.fields.recurrence.daysOfWeek.includes(
                                daysOfWeek.thursday
                            )
                        "
                    />
                    <sys-checkbox
                        size="md"
                        :label="$t('CalendarsPage.WeekDays.friday')"
                        @input="handleInputCheckboxDaysOfWeek(daysOfWeek.friday)"
                        :value="
                            calendarSettings.modalEvents.fields.recurrence.daysOfWeek.includes(
                                daysOfWeek.friday
                            )
                        "
                    />
                    <sys-checkbox
                        size="md"
                        :label="$t('CalendarsPage.WeekDays.saturday')"
                        @input="handleInputCheckboxDaysOfWeek(daysOfWeek.saturday)"
                        :value="
                            calendarSettings.modalEvents.fields.recurrence.daysOfWeek.includes(
                                daysOfWeek.saturday
                            )
                        "
                    />
                </div>
            </div>
            <div class="sidebar-modal-events__recurrence-closes">
                <span class="sidebar-modal-events__content-label">
                    {{ $t("CalendarsPage.Ends2") }}
                </span>
                <div class="sidebar-modal-events__recurrence-closes-right">
                    <sys-form-datepicker
                        id="recurrence-closes"
                        type="date"
                        :value="calendarSettings.modalEvents.fields.recurrence.endDate"
                        @input="(value) => UPDATE_MODAL_EVENTS_RECURRENCE_END_DATE_FIELD(value)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SysFormInput from "@/@core/components/sys-form-input";
    import SysFormDatepicker from "@/@core/components/sys-form-datepicker";
    import SysFormSelect from "@/@core/components/sys-form-select";
    import SysCheckbox from "@/@core/components/sys-checkbox";
    import SysSwitch from "@/@core/components/sys-switch";
    import SysRadioButton from "@/@core/components/sys-radio-button";
    import daysOfWeek from "@/views/pages/calendar/enums/daysOfWeek";
    import weeksOfMonthList from "@/views/pages/calendar/enums/weeksOfMonthList";
    import daysOfWeekList from "@/views/pages/calendar/enums/daysOfWeekList";
    import monthsList from "@/views/pages/calendar/enums/monthList";
    import frequency from "@/views/pages/calendar/enums/frequency";
    import { mapMutations, mapState } from "vuex";
    import calendar from "@/store/calendar";

    export default {
        components: {
            SysFormInput,
            SysFormSelect,
            SysCheckbox,
            SysFormDatepicker,
            SysSwitch,
            SysRadioButton
        },
        computed: {
            ...mapState("calendar", ["isModalEventsOpen", "calendarSettings", "context"]),
            ...mapState("app", ["windowWidth"])
        },
        data() {
            return {
                isOpen: true,
                daysOfWeek: daysOfWeek,
                weeksOfMonthList: weeksOfMonthList,
                daysOfWeekList: daysOfWeekList,
                monthsList: monthsList,
                frequency: frequency
            };
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_EVENTS_RECURRENCE_START_DATE_FIELD",
                "UPDATE_MODAL_EVENTS_RECURRENCE_END_DATE_FIELD",
                "UPDATE_MODAL_EVENTS_RECURRENCE_DAYS_OF_WEEK_FIELD",
                "UPDATE_MODAL_EVENTS_RECURRENCE_MONTHS_FIELD",
                "UPDATE_MODAL_EVENTS_RECURRENCE_WEEKS_OF_MONTH_FIELD",
                "UPDATE_MODAL_EVENTS_IS_RECURRENT_FIELD",
                "UPDATE_MODAL_EVENTS_RECURRENCE_FREQUENCY_FIELD",
                "UPDATE_MODAL_EVENTS_RECURRENCE_QUANTITY_FIELD",
                "UPDATE_MODAL_EVENT_RECURRENCE",
                "UPDATE_MODAL_EVENTS_RECURRENCE_DAYS_OF_MONTH_FIELD",
                "UPDATE_MODAL_EVENTS_STATES",
                "UPDATE_MODAL_EVENTS_RECURRENCE_IS_ON",
                "UPDATE_MODAL_EVENTS_RECURRENCE_IS_ON_THE"
            ]),
            handleInputRecurrent(value) {
                this.UPDATE_MODAL_EVENTS_IS_RECURRENT_FIELD(value);

                this.UPDATE_MODAL_EVENTS_STATES({
                    ...this.calendarSettings.modalEvents.states,
                    recurrence: {
                        startDate: {
                            valid: true,
                            feedback: ""
                        },
                        quantity: {
                            valid: true,
                            feedback: ""
                        },
                        frequency: {
                            valid: true,
                            feedback: ""
                        },
                        daysOfMonth: {
                            valid: true,
                            feedback: ""
                        }
                    }
                });

                !value &&
                    this.UPDATE_MODAL_EVENT_RECURRENCE({
                        isOnThe: true,
                        isOn: false,
                        frequency: null,
                        quantity: "1",
                        daysOfWeek: [1],
                        daysOfMonth: [],
                        weeksOfMonth: [],
                        months: [],
                        startDate: new Date(),
                        endDate: null
                    });
            },
            handleInputFrequency(value) {
                this.UPDATE_MODAL_EVENT_RECURRENCE({
                    isOnThe: true,
                    isOn: false,
                    frequency: value,
                    quantity: this.calendarSettings.modalEvents.fields.recurrence.quantity,
                    daysOfWeek: [1],
                    daysOfMonth: [],
                    weeksOfMonth: [],
                    months: [],
                    startDate: this.calendarSettings.modalEvents.fields.recurrence.startDate,
                    endDate: this.calendarSettings.modalEvents.fields.recurrence.endDate
                });
            },
            handleInputCheckboxDaysOfWeek(value) {
                const localDaysOfWeek = [
                    ...this.calendarSettings.modalEvents.fields.recurrence.daysOfWeek
                ];

                const valueIndex = localDaysOfWeek.findIndex((i) => i === value);

                if (valueIndex === -1) {
                    localDaysOfWeek.push(value);
                    this.UPDATE_MODAL_EVENTS_RECURRENCE_DAYS_OF_WEEK_FIELD([...localDaysOfWeek]);
                    return;
                }

                localDaysOfWeek.splice(valueIndex, 1);

                this.UPDATE_MODAL_EVENTS_RECURRENCE_DAYS_OF_WEEK_FIELD(
                    localDaysOfWeek?.length === 0 ? [1] : [...localDaysOfWeek]
                );
            }
        }
    };
</script>

<style lang="scss">
    .sidebar-modal-events__recurrence-container {
        padding: 15px;
        border-radius: 6px;
        border: 1px solid #cfc4be;
        background: #fafafa;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .sidebar-modal-events__recurrence-header {
            display: flex;
            align-items: center;

            .sidebar-modal-events__recurrence-header-left {
                display: flex;

                .sidebar-modal-events__recurrence-title {
                    color: #4c4541;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 26px;
                }
            }

            .sidebar-modal-events__recurrence-header-right {
                display: flex;
                margin-left: auto;
            }
        }

        .sidebar-modal-events__recurrence-content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &.--hide {
                display: none;
            }

            .sidebar-modal-events__content-label {
                color: #4c4541;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-top: 9px;
                min-width: 134px;
                max-width: 134px;
            }

            .sidebar-modal-events__recurrence-start,
            .sidebar-modal-events__recurrence-closes,
            .sidebar-modal-events__recurrence-repeat {
                display: flex;
                justify-content: space-between;
            }

            .sidebar-modal-events__recurrence-repeat {
                .sidebar-modal-events__recurrence-repeat-right {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .sidebar-modal-events__recurrence-repeat-initial-fields {
                        display: flex;
                        gap: 8px;
                        .sidebar-modal-events__recurrence-repeat-quantity-field {
                            min-width: 67px;
                            max-width: 67px;
                        }

                        .sidebar-modal-events__recurrence-repeat-frequency-field {
                            min-width: 134px;
                            max-width: 134px;
                        }
                    }

                    .sidebar-modal-events__recurrence-repeat-day-fields {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;

                        .sidebar-modal-events__recurrence-repeat-day-fields-left {
                            margin-top: 9px;
                        }

                        .sidebar-modal-events__recurrence-repeat-day-colums-fields {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            width: 100%;

                            .sidebar-modal-events__recurrence-repeat-day-field {
                                min-width: 67px;
                                max-width: 67px;
                            }

                            .sidebar-modal-events__recurrence-repeat-month-field {
                                min-width: 134px;
                                max-width: 134px;
                            }
                        }
                    }

                    .sidebar-modal-events__recurrence-repeat-on-fields {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;

                        .sidebar-modal-events__recurrence-repeat-on-fields-left {
                            margin-top: 9px;
                        }

                        .sidebar-modal-events__recurrence-repeat-on-column-fields {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                        }
                    }
                }
            }

            .sidebar-modal-events__recurrence-on-every {
                display: flex;
                .sidebar-modal-events__content-label {
                    min-width: 134px;
                    max-width: 134px;
                }
                .sidebar-modal-events__recurrence-on-every-right {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 8px 16px;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .sidebar-modal-events__recurrence-container {
            .sidebar-modal-events__recurrence-header {
                .sidebar-modal-events__recurrence-header-left {
                    .sidebar-modal-events__recurrence-title {
                        font-size: 16px !important;
                        line-height: 26px !important;
                    }
                }
            }

            .sidebar-modal-events__recurrence-content {
                .sidebar-modal-events__content-label {
                    margin-top: 0 !important;
                    min-width: fit-content !important;
                    max-width: fit-content !important;
                    font-size: 12px !important;
                    line-height: 16px !important;
                }

                .sidebar-modal-events__recurrence-start,
                .sidebar-modal-events__recurrence-closes,
                .sidebar-modal-events__recurrence-repeat {
                    display: flex;
                    justify-content: flex-start !important;
                    flex-direction: column;
                    gap: 4px;
                }

                .sidebar-modal-events__recurrence-repeat {
                    .sidebar-modal-events__recurrence-repeat-right {
                        .sidebar-modal-events__recurrence-repeat-initial-fields {
                            .sidebar-modal-events__recurrence-repeat-frequency-field {
                                min-width: initial !important;
                                max-width: initial !important;
                                width: 100%;
                            }
                        }

                        .sidebar-modal-events__recurrence-repeat-day-fields {
                            display: flex;
                            align-items: flex-start;
                            gap: 8px;

                            .sidebar-modal-events__recurrence-repeat-day-fields-left {
                                margin-top: 9px;
                            }

                            .sidebar-modal-events__recurrence-repeat-day-colums-fields {
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                                width: 100%;

                                .sidebar-modal-events__recurrence-repeat-day-field {
                                    min-width: 67px;
                                    max-width: 67px;
                                }

                                .sidebar-modal-events__recurrence-repeat-month-field {
                                    min-width: 100% !important;
                                    max-width: 100% !important;
                                }
                            }
                        }

                        .sidebar-modal-events__recurrence-repeat-on-fields {
                            display: flex;
                            align-items: flex-start;
                            gap: 8px;

                            .sidebar-modal-events__recurrence-repeat-on-fields-left {
                                margin-top: 9px;
                            }

                            .sidebar-modal-events__recurrence-repeat-on-column-fields {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                gap: 8px;
                            }
                        }
                    }
                }

                .sidebar-modal-events__recurrence-on-every {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    .sidebar-modal-events__content-label {
                        min-width: fit-content !important;
                        max-width: fit-content !important;
                    }
                }
            }
        }
    }
</style>
