<template>
    <div id="exception-calendar">
        <ModalEvents isException />
        <div
            class="h-100"
            v-if="monthGroups.length === 0 && !noItemsFound"
        >
            <div class="no-items h-100">
                <EmptyBoxIcon class="empty-box" />
                <p class="title">{{ $t("CalendarsPage.NoEventTitle") }}</p>
                <p class="subtitle">
                    {{ $t("CalendarsPage.NoEventSubtitle") }}
                </p>
                <b-button
                    class="btn-new-calendar"
                    @click="UPDATE_MODAL_EVENTS_TOGGLE(true)"
                >
                    <AddIcon class="add-icon" />
                    {{ $t("CalendarsPage.NewEvent") }}
                </b-button>
            </div>
        </div>
        <div
            v-else
            class="calendar-body"
        >
            <div class="filter-block"></div>
            <div class="event-block">
                <EventMonth :monthGroups="monthGroups" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import EmptyBoxIcon from "@core/assets/icons/empty-box-icon.svg";
    import AddIcon from "@core/assets/icons/add-icon.svg";
    import SearchIcon from "@core/assets/icons/search-icon.svg";
    import { BButton, BFormTags } from "bootstrap-vue";
    import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
    import ModalEvents from "../actionCalendar/components/settings/ModalEvents";
    import EventMonth from "./components/EventMonth.vue";

    export default {
        components: {
            EmptyBoxIcon,
            AddIcon,
            SearchIcon,
            BButton,
            BFormTags,
            ToastificationContent,
            ModalEvents,
            EventMonth
        },
        data() {
            return {
                noItemsFound: true,
                filter: {
                    textFilter: []
                },
                monthGroups: [
                    {
                        month: 2,
                        year: 2025,
                        monthName: "fevereiro",
                        events: [
                            {
                                id: 28,
                                name: "super evento teste 699",
                                eventTypeId: 1,
                                eventTypeName: "Fora de turno",
                                efficiency: 100,
                                date: "2025-02-01T00:00:00",
                                startTime: "08:00",
                                endTime: "11:00",
                                allDay: false,
                                description: "Primeiro evento de exemplo.",
                                isRecurrent: true,
                                resourceIds: [257, 260],
                                resourceNames: ["recurso com customizados", "24"],
                                recurrence: {
                                    frequencyId: 2,
                                    quantity: 1,
                                    daysOfWeek: [1, 3, 5],
                                    daysOfMonth: [],
                                    weeksOfMonth: [],
                                    months: [],
                                    startDate: "2025-01-10T00:00:00",
                                    endDate: "2025-01-15T00:00:00"
                                }
                            }
                        ]
                    },
                    {
                        month: 1,
                        year: 2024,
                        monthName: "janeiro",
                        events: [
                            {
                                id: 37,
                                name: "Revisão mensal",
                                eventTypeId: 3,
                                eventTypeName: "Parada planejada",
                                efficiency: 15,
                                date: "2024-01-01T00:00:00",
                                startTime: "02:00",
                                endTime: "18:00",
                                allDay: false,
                                description: "Primeiro evento de exemplo.",
                                isRecurrent: true,
                                resourceIds: [260],
                                resourceNames: ["24"],
                                recurrence: {
                                    frequencyId: 2,
                                    quantity: 1,
                                    daysOfWeek: [1, 3, 5],
                                    daysOfMonth: [],
                                    weeksOfMonth: [],
                                    months: [],
                                    startDate: "2025-01-10T00:00:00",
                                    endDate: "2025-03-10T00:00:00"
                                }
                            }
                        ]
                    },
                    {
                        month: 1,
                        year: 2025,
                        monthName: "janeiro",
                        events: [
                            {
                                id: 127,
                                name: "string",
                                eventTypeId: 1,
                                eventTypeName: "Fora de turno",
                                efficiency: 0,
                                date: "2025-01-24T00:00:00",
                                startTime: "00:00",
                                endTime: "00:00",
                                allDay: true,
                                description: "string",
                                isRecurrent: true,
                                resourceIds: [237],
                                resourceNames: ["rec 1"],
                                recurrence: null
                            },
                            {
                                id: 128,
                                name: "string 2",
                                eventTypeId: 1,
                                eventTypeName: "Fora de turno",
                                efficiency: 0,
                                date: "2025-01-24T00:00:00",
                                startTime: "00:00",
                                endTime: "00:00",
                                allDay: true,
                                description: "string 2",
                                isRecurrent: false,
                                resourceIds: [237],
                                resourceNames: ["rec 1"],
                                recurrence: null
                            }
                        ]
                    }
                ]
            };
        },
        mounted() {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
            this.monthGroups.sort((a, b) => {
                if (a.year !== b.year) {
                    return a.year - b.year;
                }
                return a.month - b.month;
            });
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", ["UPDATE_MODAL_EVENTS_TOGGLE"]),
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            }
        },
        computed: {
            ...mapState("", {})
        }
    };
</script>

<style lang="scss">
    #exception-calendar {
        padding: 16px 0;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

        @media (max-width: 768px) {
            margin-top: 24px;
        }

        &:has(.no-items) {
            height: calc(100vh - 136px);
        }

        &:has(.body-block .block-no-results) {
            height: calc(100vh - 135px);
        }

        &:has(.body-block .no-items) {
            height: calc(100vh - 135px);
        }

        .no-items {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            margin: 0 16px;
            border: 1px solid #cfc4be;

            .empty-box {
                width: 80px;
                height: 80px;
            }

            .title {
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                color: #4c4541;
                margin: 0;
            }

            .subtitle {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #998f8a;
                padding: 4px 0 12px;
                margin: 0;
            }

            .btn-new-calendar {
                border-color: #974900 !important;
                background-color: #974900 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                padding: 8px 21px;
                line-height: 20px;
                letter-spacing: 0.175px;

                .add-icon {
                    width: 14px;
                    height: 14px;
                    fill: #fff;
                }
            }

            @media (max-width: 480px) {
                .empty-box {
                    width: 40px;
                    height: 40px;
                }

                .title {
                    font-size: 14px;
                }

                .subtitle {
                    font-size: 12px;
                    text-align: center;
                    padding: 4px 48px 12px;
                    line-height: 16px;
                }

                .btn-new-calendar {
                    padding: 3px 14px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.15px;

                    .add-icon {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }

        .calendar-body {
            padding: 0 16px;
        }

        &::-webkit-scrollbar {
            height: 3px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #cfc4be;
            border-radius: 9px;
        }
    }
</style>
