<template>
    <div
        v-if="period.type === 'interval'"
        class="interval__container"
        :style="{
            height: pixelBasePerMinute + 'px'
        }"
        @click="(event) => $emit('click', event, period)"
    >
        <div class="interval__content">
            <span
                class="interval__icon-container"
                v-show="pixelBasePerMinute > 28"
            >
                <time-icon />
            </span>
            <span class="interval__label">
                {{ $t("CalendarsPage.Interval") }}
            </span>
            <span
                class="interval__range"
                v-show="pixelBasePerMinute > 28"
            >
                {{ period.startTime }} - {{ period.endTime }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            TimeIcon: () => import("@/@core/assets/formatted-icons/time-icon.svg")
        },
        props: {
            period: {
                type: Object,
                default: () => ({})
            },
            pixelBasePerMinute: {
                type: Number,
                default: 1.2
            }
        }
    };
</script>

<style lang="scss">
    .day-column__container {
        .interval__container {
            z-index: 3;
            cursor: pointer;
            border: 1px solid transparent;
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(
                    -45deg,
                    #eee 25%,
                    #fff 25%,
                    #fff 50%,
                    #eee 50%,
                    #eee 75%,
                    #fff 75%,
                    #fff
                )
                0 290px / 75px 75px;

            &:hover {
                background: linear-gradient(
                        -45deg,
                        #ffdbc4 25%,
                        #ffede2 25%,
                        #ffede2 50%,
                        #ffdbc4 50%,
                        #ffdbc4 75%,
                        #ffede2 75%,
                        #ffede2
                    )
                    0 290px / 75px 75px;
                border: 1px solid #974900;

                .interval__content {
                    .interval__icon-container {
                        svg {
                            fill: #974900;
                        }
                    }

                    .interval__label {
                        color: #974900;
                    }

                    .interval__range {
                        color: #974900;
                    }
                }
            }

            .interval__content {
                width: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;

                .interval__icon-container {
                    margin-bottom: 8px;

                    svg {
                        min-width: 20px;
                        max-width: 20px;
                        min-height: 20px;
                        max-height: 20px;
                        fill: #4c4541;
                    }
                }

                .interval__label {
                    color: #4c4541;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                }

                .interval__range {
                    white-space: nowrap;
                    color: #4c4541;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                }
            }
        }
    }
</style>
