<template>
    <b-sidebar
        id="step-sidebar-right"
        :class="['sidebar-step-by-step', isOpen ? '--is-open' : '--is-closed']"
        right
        bg-variant="white"
        v-model="isOpen"
    >
        <template #header>
            <div class="header-sidebar">
                <div class="sidebar-title">
                    {{ $t(modalTitle) }}
                </div>
            </div>
            <div class="separator"></div>
        </template>
        <template>
            <div>
                <div :class="['info-block', stepPosition === 1 ? 'step-active' : 'next-step']">
                    <div class="icon"><InfoIcon /></div>
                    <div class="text">{{ $t("CalendarsPage.EventInformation") }}</div>
                </div>
                <div
                    :class="[
                        'vertical-separator',
                        stepPosition === 1 ? 'step-active' : 'next-step'
                    ]"
                ></div>
                <div :class="['resources-block', stepPosition === 2 ? 'step-active' : '']">
                    <div class="icon"><ResourceIcon /></div>
                    <div class="text">{{ $t("CalendarsPage.ResourceSelection") }}</div>
                </div>
            </div>
        </template>
    </b-sidebar>
</template>

<script>
    import { BSidebar } from "bootstrap-vue";

    export default {
        components: {
            BSidebar,
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            InfoIcon: () => import("@core/assets/icons/info-icon.svg"),
            ResourceIcon: () => import("@core/assets/icons/select-resource-icon.svg")
        },
        props: {
            currentId: {
                type: Number,
                default: null
            },
            stepPosition: {
                type: Number,
                default: 1
            },
            modalTitle: {
                type: String,
                default: ""
            },
            device: {
                type: String,
                default: "desktop"
            },
            isOpen: {
                type: Boolean,
                default: false
            },
            isOperation: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {};
        },
        mounted() {},
        methods: {}
    };
</script>
<style lang="scss">
    .sidebar-step-by-step {
        .custom-select {
            cursor: pointer;
            padding: 4px 34px 4px 8px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
            &[disabled] {
                border-radius: 5px;
                border: 1px solid #7e7570;
                background-color: #eee;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
                color: #7e7570 !important;
            }
        }

        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                padding: 16px;

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                }
            }

            .close-icon {
                border: 1px solid #974900;
                border-radius: 5px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    width: 12px;
                    height: 12px;
                    path {
                        fill: #974900;
                    }
                }

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }

            .separator {
                width: 100%;
                height: 1px;
                background: #cfc4be;
            }
        }

        &.--is-open {
            .b-sidebar {
                animation: openSidebar 0.5s normal;
                right: 407px !important;
                transition: all 0.5s ease-in-out;
            }
        }
        &.--is-closed {
            .b-sidebar {
                animation: closeSidebar 0.5s normal;
                right: 0 !important;
                transition: all 0.5s ease-out;
            }
        }
        @keyframes openSidebar {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        @keyframes closeSidebar {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
        .b-sidebar {
            height: 100% !important;
            max-width: 300px;
            width: 100%;

            .b-sidebar-body {
                padding: 16px;

                .info-block,
                .resources-block,
                .times-block {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .icon {
                        display: flex;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        padding: 7px;
                        border-radius: 50%;
                        border: 1px solid #cfc4be;

                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #cfc4be;
                        }
                    }

                    .text {
                        overflow: hidden;
                        color: #cfc4be;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                    }

                    &.step-active {
                        .icon {
                            border-color: #974900;
                            svg {
                                fill: #974900;
                            }
                        }

                        .text {
                            color: #974900;
                        }
                    }

                    &.next-step {
                        .icon {
                            border-color: #66bb6a;
                            background: #66bb6a;
                            svg {
                                fill: #fff;
                            }
                        }

                        .text {
                            color: #66bb6a;
                        }
                    }
                }

                .vertical-separator {
                    margin-left: 14px;
                    width: 1px;
                    height: 16px;
                    background: #cfc4be;

                    &.step-active {
                        background: #974900;
                    }

                    &.next-step {
                        background: #66bb6a;
                    }
                }

                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
        }
        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 16px;

            .btn-footer {
                box-shadow: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                border-radius: 5px;

                &:hover {
                    box-shadow: none;
                }

                &.btn-reset {
                    background-color: transparent !important;
                    color: #974900 !important;
                    font-size: 12px !important;
                    border: 1px solid transparent !important;
                    padding: 4px 14px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-cancel {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    margin-right: 8px;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-save {
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    color: #ffff !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #a45f1f !important;
                    }
                }
            }
        }
    }
</style>
