<template>
    <div :class="['sys-switch__container', size && `normal-${size}`]">
        <div
            @click="$emit('input', !value)"
            class="sys-switch__switch"
            :class="{ '--switch-on': value, '--switch-off': !value }"
        >
            <div class="switch-handle" />
        </div>

        <label
            class="sys-switch__label"
            v-if="!!label"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                // valor controlado pelo v-model
                type: Boolean,
                default: true
            },
            size: {
                type: String,
                default: "md"
            },
            label: {
                type: String,
                default: ""
            }
        }
    };
</script>

<style lang="scss" scoped>
    .sys-switch__container {
        display: flex;
        height: fit-content;
        align-items: center;
        gap: 5px;

        .sys-switch__switch {
            width: 34px;
            height: 18px;
            background-color: #ccc;
            border-radius: 50px;
            position: relative;
            transition: background-color 0.3s ease;
            display: inline-block;
            cursor: pointer;

            &.--switch-on {
                background-color: #974900; /* Verde quando ligado */
            }

            &.--switch-off {
                background-color: #ece0db; /* Cinza quando desligado */
            }

            .switch-handle {
                width: 12px;
                height: 12px;
                background-color: white;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 4px;
                transform: translateY(-50%);
                transition: left 0.3s ease;
                box-shadow: -1px 2px 3px 0px #17120e40;
            }

            &.--switch-on .switch-handle {
                left: 18px;
            }
        }

        .sys-switch__label {
            color: #4c4541;
            margin: 0;
        }

        &.normal-md {
            .sys-switch__label {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                white-space: nowrap;
            }
        }
    }
</style>
