function useCalendarPayload(vm) {
    const states = { ...vm.$store.state.calendar };
    const { calendarSettings, periods } = states;

    const payload = {
        name: calendarSettings.generalApplied.name,
        referenceDate: calendarSettings.generalApplied.referenceDate,
        duration: Number(calendarSettings.generalApplied.duration),
        events:
            calendarSettings.events.eventList?.map((event) => {
                const { id, origin, ...restOfEvent } = event;

                return {
                    ...restOfEvent,
                    id: origin === "front" ? null : id
                };
            }) ?? [],
        resources: calendarSettings.resources.resourcesList?.map((resource) => resource.id) ?? [],
        periods: periods.periodList
    };

    return { payload };
}

export default useCalendarPayload;
