import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import flags_controller_pipelines from "./new-pipeline/flags-controller";
import departments from "./departments";
import structures from "./structures";
import people from "./people";
import profile from "./profile";
import resource from "./resource";
import materials from "./materials";
import dataFields from "./data-fields";
import productionOrders from "./production-orders";
import operationalStatus from "./operational-status";
import rejectionReasons from "./rejection-reasons";
import calendar from "./calendar";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        flags_controller_pipelines,
        departments,
        structures,
        people,
        profile,
        resource,
        materials,
        dataFields,
        productionOrders,
        operationalStatus,
        rejectionReasons,
        calendar
    },
    strict: process.env.DEV
});
