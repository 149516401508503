<template>
    <div class="day-column__container">
        <div class="day-column__hours-area">
            <div class="day-column__period-block-wrapper">
                <div
                    class="day-column__period-block"
                    v-for="(period, i) in periodList"
                    :key="`period-${i}`"
                >
                    <Period
                        v-if="period.type === 'period'"
                        :period="period"
                        :pixelBasePerMinute="
                            periods.pixelBasePerMinute *
                            calculateMinutes(period.startTime, period.endTime)
                        "
                        @click="handleFillPeriodModal"
                    />

                    <Interval
                        v-if="period.type === 'interval'"
                        :period="period"
                        :pixelBasePerMinute="
                            periods.pixelBasePerMinute *
                            calculateMinutes(period.startTime, period.endTime)
                        "
                        @click="handleFillPeriodModal"
                    />

                    <div
                        v-if="period.type === 'spacing'"
                        :style="{
                            width: '0px',
                            height:
                                periods.pixelBasePerMinute *
                                    calculateMinutes(period.startTime, period.endTime) +
                                'px'
                        }"
                    />
                </div>
            </div>

            <half-hour-block
                v-for="(period, i) in hourList"
                :key="`hour-of-day-${i}`"
                :pixelBasePerMinute="periods.pixelBasePerMinute * calendarRefs.halfAnHour"
                :period="{
                    type: 'half-hour',
                    startTime: period,
                    endTime: hourList.length - 1 != i ? hourList[i + 1] : '00:00'
                }"
                @click="handleFillPeriodModal"
            />
        </div>
    </div>
</template>

<script>
    import Period from "./Period.vue";
    import Interval from "./Interval.vue";
    import HalfHourBlock from "./HalfHourBlock.vue";
    import calendarRefs from "@/views/pages/calendar/enums/calendarRefs";

    import { mapMutations, mapState } from "vuex";
    import { calculateMinutes } from "../../../utils";
    import moment from "moment";

    export default {
        components: {
            Period,
            Interval,
            HalfHourBlock
        },
        computed: {
            ...mapState("calendar", ["periods", "calendarSettings"])
        },
        props: {
            day: {
                type: Number,
                default: null
            },
            periodList: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                calendarRefs: calendarRefs,
                calculateMinutes: calculateMinutes,
                hourList: [
                    "00:00",
                    "00:30",
                    "01:00",
                    "01:30",
                    "02:00",
                    "02:30",
                    "03:00",
                    "03:30",
                    "04:00",
                    "04:30",
                    "05:00",
                    "05:30",
                    "06:00",
                    "06:30",
                    "07:00",
                    "07:30",
                    "08:00",
                    "08:30",
                    "09:00",
                    "09:30",
                    "10:00",
                    "10:30",
                    "11:00",
                    "11:30",
                    "12:00",
                    "12:30",
                    "13:00",
                    "13:30",
                    "14:00",
                    "14:30",
                    "15:00",
                    "15:30",
                    "16:00",
                    "16:30",
                    "17:00",
                    "17:30",
                    "18:00",
                    "18:30",
                    "19:00",
                    "19:30",
                    "20:00",
                    "20:30",
                    "21:00",
                    "21:30",
                    "22:00",
                    "22:30",
                    "23:00",
                    "23:30"
                ]
            };
        },
        methods: {
            ...mapMutations("calendar", [
                "UPDATE_MODAL_PERIODS_TOGGLE",
                "UPDATE_MODAL_PERIOD",
                "RESET_MODAL_PERIODS"
            ]),
            handleFillPeriodModal(_, period) {
                this.RESET_MODAL_PERIODS();

                const startTime = new Date(
                    moment().format("YYYY-MM-DD") + "T" + period.startTime + ":00"
                );
                const endTime = new Date(
                    moment().format("YYYY-MM-DD") +
                        "T" +
                        (period.endTime === "00:00" ? "23:59" : period.endTime) +
                        ":00"
                );

                this.$nextTick(() => {
                    this.UPDATE_MODAL_PERIOD(
                        period.type === "period"
                            ? {
                                  ...this.calendarSettings.modalPeriods,
                                  periodId: period.id,
                                  fields: {
                                      day: this.day,
                                      status: period.statusId,
                                      efficiency: period.efficiency,
                                      startTime,
                                      endTime
                                  }
                              }
                            : {
                                  ...this.calendarSettings.modalPeriods,
                                  fields: {
                                      ...this.calendarSettings.modalPeriods.fields,
                                      day: this.day,
                                      startTime,
                                      endTime
                                  }
                              }
                    );

                    this.handleOpenPeriodsModal();
                });
            },
            handleOpenPeriodsModal() {
                this.UPDATE_MODAL_PERIODS_TOGGLE(true);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .day-column__container {
        display: flex;
        flex: 1;
        flex-direction: column;

        .day-column__hours-area {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 100%;

            .day-column__period-block-wrapper {
                position: absolute;

                left: 1.86px;
                width: calc(100% - 1.86px - 10px);
                max-width: calc(100% - 1.86px - 10px);

                .day-column__period-block {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    overflow: hidden;
                }
            }
        }
    }
</style>
