import daysOfWeek from "./daysOfWeek";

const daysOfWeekList = Object.freeze([
    {
        id: daysOfWeek.sunday,
        text: "Domingo",
        name: "Domingo",
        nameEN: "Sunday"
    },
    {
        id: daysOfWeek.monday,
        text: "Segunda-feira",
        name: "Segunda-feira",
        nameEN: "Monday"
    },
    {
        id: daysOfWeek.tuesday,
        text: "Terça-feira",
        name: "Terça-feira",
        nameEN: "Tuesday"
    },
    {
        id: daysOfWeek.wednesday,
        text: "Quarta-feira",
        name: "Quarta-feira",
        nameEN: "Wednesday"
    },
    {
        id: daysOfWeek.thursday,
        text: "Quinta-feira",
        name: "Quinta-feira",
        nameEN: "Thursday"
    },
    {
        id: daysOfWeek.friday,
        text: "Sexta-feira",
        name: "Sexta-feira",
        nameEN: "Friday"
    },
    {
        id: daysOfWeek.saturday,
        text: "Sábado",
        name: "Sábado",
        nameEN: "Saturday"
    }
]);

export default daysOfWeekList;
