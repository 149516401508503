import calendarErrors from "../../enums/calendarErrors";
import useCalendarPayload from "@/views/pages/calendar/actionCalendar/hooks/useCalendarPayload";
import { checkCompletedPeriods } from "../utils";

async function createCalendarService(vm, hasLoading = true) {
    if (!vm.$store.state.calendar.periods.isCompletedPeriods) return;

    const { payload } = useCalendarPayload(vm);
    hasLoading && vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", true);

    try {
        const response = await vm.$http2.post("/api/myconfig/calendar", payload);

        const calendarId = response.data?.data?.id ?? null;

        vm.$store.commit("calendar/UPDATE_BYPASS_LEAVE", true);
        vm.$router.push({
            name: calendarId === null ? "standard" : "editCalendar",
            params: {
                prefix: localStorage.getItem("prefix") || vm.$router.currentRoute.params.prefix,
                id: calendarId
            }
        });

        vm.$setToast({
            title: vm.$t("Success"),
            icon: "SmileIcon",
            text: vm.$t("CalendarsPage.CalendarCreated"),
            variant: "success"
        });

        vm.$store.commit("calendar/UPDATE_MODAL_GENERAL_STATES", {
            ...vm.$store.state.calendar.calendarSettings.general.states,
            name: {
                valid: true,
                feedback: ""
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (error) {
        error.response.data.errors.map((e) => {
            if (e.id === calendarErrors.nameAlreadyExists) {
                vm.$store.commit("calendar/UPDATE_MODAL_GENERAL_STATES", {
                    ...vm.$store.state.calendar.calendarSettings.general.states,
                    name: {
                        valid: false,
                        feedback: "CalendarsPage.EqualName"
                    }
                });
            } else {
                console.error("Error saving calendar!");
            }
        });

        return Promise.error(error?.response?.data);
    } finally {
        hasLoading && vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", false);
    }
}

async function editCalendarService(vm, calendarId, hasLoading = true) {
    if (!vm.$store.state.calendar.periods.isCompletedPeriods) return;

    const { payload } = useCalendarPayload(vm);
    hasLoading && vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", true);

    try {
        const response = await vm.$http2.put(`/api/myconfig/calendar/${calendarId}`, payload);

        vm.$store.commit("calendar/UPDATE_MODAL_GENERAL_STATES", {
            ...vm.$store.state.calendar.calendarSettings.general.states,
            name: {
                valid: true,
                feedback: ""
            }
        });

        vm.$setToast({
            title: vm.$t("Success"),
            icon: "SmileIcon",
            text: vm.$t("CalendarsPage.UpdatedCalendar"),
            variant: "success"
        });

        return Promise.resolve(response?.data?.data);
    } catch (error) {
        error.response.data.errors.map((e) => {
            if (e.id === calendarErrors.nameAlreadyExists) {
                vm.$store.commit("calendar/UPDATE_MODAL_GENERAL_STATES", {
                    ...vm.$store.state.calendar.calendarSettings.general.states,
                    name: {
                        valid: false,
                        feedback: "CalendarsPage.EqualName"
                    }
                });
            } else {
                console.error("Error saving calendar!");
            }
        });

        return Promise.error(error?.response?.data);
    } finally {
        hasLoading && vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", false);
    }
}

async function getCalendarService(vm, calendarId, hasLoading = true) {
    hasLoading && vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", true);

    try {
        const response = await vm.$http2.get(`/api/myconfig/calendar/${calendarId}`);
        const { data } = response.data;

        const resources = [...vm.$store.state.calendar.context.resources];

        const general = {
            name: data.name,
            referenceDate: new Date(data.referenceDate),
            duration: data.duration
        };

        const isCompletedPeriods = checkCompletedPeriods(
            data?.periods ?? [],
            Number(general.duration)
        );

        vm.$store.commit("calendar/UPDATE_PERIODS_IS_COMPLETED_PERIODS", isCompletedPeriods);

        const resourcesList =
            data?.resources?.map((resourceId) => ({
                ...resources.find((resource) => resource.id == resourceId)
            })) ?? [];

        vm.$store.commit("calendar/UPDATE_GENERAL_APPLIED", { ...general });
        vm.$store.commit("calendar/UPDATE_MODAL_GENERAL_FIELDS", { ...general });
        vm.$store.commit(
            "calendar/UPDATE_PERIODS_PERIOD_LIST",
            data?.periods
                ? [
                      ...data.periods.map((period) => ({
                          ...period,
                          id: crypto.getRandomValues(new Uint8Array(4)).join("")
                      }))
                  ]
                : []
        );

        vm.$store.commit("calendar/UPDATE_RESOURCES_LIST", [...resourcesList]);
        vm.$store.commit("calendar/UPDATE_EVENT_LIST", data?.events ? [...data.events] : []);

        return Promise.resolve(response?.data?.data);
    } catch (error) {
        vm.$store.commit("calendar/UPDATE_BYPASS_LEAVE", true);
        vm.$router.push({
            name: "standard",
            params: {
                prefix: localStorage.getItem("prefix") || vm.$router.currentRoute.params.prefix
            }
        });

        return Promise.error(error?.response?.data);
    } finally {
        hasLoading && vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", false);
    }
}

async function getCalendarContextService(vm, hasLoading = true) {
    const currentLanguage = vm.$cookies.get("userInfo").language;
    hasLoading && vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", true);

    try {
        await getCalendarResourcesService(vm, false);
        const response = await vm.$http2.get(
            `/api/myconfig/calendar/context?lang=${currentLanguage}`
        );

        const { data } = response.data;

        const eventTypes =
            data?.calendarEventTypes?.map((eventType) => ({
                ...eventType,
                text: eventType.name
            })) ?? [];

        const durationUnits =
            data?.durationUnits?.map((duration) => ({ ...duration, text: duration.name })) ?? [];

        const statusList =
            data?.status?.map((e) => ({
                ...e,
                text: e.name
            })) ?? [];

        const permissionsList = data?.permissions ?? [];

        vm.$store.commit("calendar/UPDATE_PERMISSIONS_LIST", permissionsList);
        vm.$store.commit("calendar/UPDATE_EXCEPTION_EVENT_TYPES_TOGGLE", eventTypes);
        vm.$store.commit("calendar/UPDATE_DURATION_UNITS_TOGGLE", durationUnits);
        vm.$store.commit("calendar/UPDATE_STATUS_TOGGLE", statusList);

        return Promise.resolve(response?.data?.data);
    } catch (error) {
        return Promise.error(error?.response?.data);
    } finally {
        hasLoading && vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", false);
    }
}

async function getCalendarResourcesService(vm, hasLoading = true) {
    hasLoading && vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", true);

    try {
        const response = await vm.$http2.get(`/api/myconfig/calendar/resources`);
        const { data } = response.data;

        const resources = data ?? [];

        vm.$store.commit("calendar/UPDATE_CONTEXT_RESOURCES", resources);

        return Promise.resolve(response?.data?.data);
    } catch (error) {
        return Promise.error(error?.response?.data);
    } finally {
        hasLoading && vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", true);
    }
}

async function saveCalendarService(vm) {
    const calendarId = vm.$router.currentRoute?.params?.id?.toString();

    vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", true);

    try {
        await (calendarId
            ? editCalendarService(vm, calendarId, false)
            : createCalendarService(vm, false));

        await getCalendarContextService(false);

        return Promise.resolve(null);
    } catch (error) {
        return error?.response ? Promise.reject(error?.response) : Promise.resolve(null);
    } finally {
        vm.$store.commit("app/UPDATE_FLAG_SHOW_OVERLAY", false);
    }
}

export {
    createCalendarService,
    editCalendarService,
    getCalendarService,
    getCalendarContextService,
    getCalendarResourcesService,
    saveCalendarService
};
