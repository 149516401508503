<template>
    <div class="d-flex">
        <modal-step-by-step
            v-if="windowWidth > 707 && !calendarSettings.modalEvents.eventId && isException"
            :modalTitle="
                !calendarSettings.modalEvents.eventId
                    ? $t('CalendarsPage.NewEvent')
                    : $t('CalendarsPage.EditEvent')
            "
            :isOpen="isOpen"
            :stepPosition="currentStep"
        />
        <ModalLinkResources isException />
        <ModalOptionsResource
            v-if="windowWidth > 480"
            @onUnlinkResource="openUnlinkModal"
            @close="flagToUnCheck += 1"
        />
        <ModalInfoResource
            :modalTitle="
                calendarSettings.modalEvents.eventId
                    ? 'Nome do Evento'
                    : $t('CalendarsPage.EventInformation')
            "
            :device="windowWidth"
            :resourceInfo="resourceInfo"
            @onUnlink="openUnlinkModal"
        />
        <b-sidebar
            id="sidebar-modal-events"
            :class="[
                'sidebar-modal-events',
                verifyIfHasModalsOpen() && windowWidth > 480 ? '--role-openned' : '--role-closed'
            ]"
            right
            bg-variant="white"
            v-model="isOpen"
            :aria-hidden="false"
            @hidden="closeModal"
        >
            <template #header>
                <div
                    v-if="!isException || currentStep === 1 || calendarSettings.modalEvents.eventId"
                    class="header-sidebar d-flex justify-content-between"
                >
                    <span
                        v-if="!isException"
                        class="sidebar-title"
                    >
                        {{
                            !calendarSettings.modalEvents.eventId
                                ? $t("CalendarsPage.NewEvent")
                                : $t("CalendarsPage.EditEvent")
                        }}
                    </span>

                    <span
                        v-else
                        class="sidebar-title"
                        >{{
                            calendarSettings.modalEvents.eventId
                                ? "Nome do Evento"
                                : $t("CalendarsPage.EventInformation")
                        }}</span
                    >

                    <span
                        v-if="!isException || calendarSettings.modalEvents.eventId"
                        class="close-icon"
                        @click="closeModal()"
                    >
                        <CloseIcon />
                    </span>
                </div>
                <div
                    v-if="
                        !isException || calendarSettings.modalEvents.eventId || windowWidth <= 707
                    "
                    class="separator"
                ></div>
                <div
                    v-if="calendarSettings.modalEvents.eventId"
                    class="modal-navbar"
                >
                    <div
                        :class="['info', currentStep === 1 ? 'active' : '']"
                        @click="currentStep = 1"
                    >
                        {{ $t("Informations") }}
                    </div>
                    <div
                        :class="['resource', currentStep === 2 ? 'active' : '']"
                        @click="currentStep = 2"
                    >
                        {{ $t("WorkcenterList.Resources") }}
                    </div>
                </div>
            </template>

            <template>
                <div
                    v-if="currentStep === 1 || !isException"
                    class="sidebar-modal-events__fields"
                >
                    <sys-form-input
                        :label="$t('CalendarsPage.Name')"
                        :value="calendarSettings.modalEvents.fields.name"
                        :placeholder="$t('TypeHere')"
                        :state="calendarSettings.modalEvents.states.name.valid"
                        :feedback="$t(calendarSettings.modalEvents.states.name.feedback)"
                        @input="(e) => UPDATE_MODAL_EVENTS_NAME_FIELD(e.target.value)"
                    />

                    <div class="sidebar-modal-events__fields-row">
                        <div class="sidebar-modal-events__type-field">
                            <sys-form-select
                                :label="$t('CalendarsPage.Type')"
                                :placeholder="$t('Select')"
                                :value="calendarSettings.modalEvents.fields.exceptionEventType"
                                :options="context.calendarEventTypes"
                                :state="
                                    calendarSettings.modalEvents.states.exceptionEventType.valid
                                "
                                :feedback="
                                    $t(
                                        calendarSettings.modalEvents.states.exceptionEventType
                                            .feedback
                                    )
                                "
                                @input="(value) => UPDATE_MODAL_EVENTS_EVENT_TYPE_FIELD(value)"
                                translate
                            />
                        </div>

                        <div class="sidebar-modal-events__efficiency-field">
                            <sys-form-input
                                :label="$t('CalendarsPage.Efficiency')"
                                :value="calendarSettings.modalEvents.fields.efficiency"
                                :placeholder="$t('TypeHere')"
                                :state="calendarSettings.modalEvents.states.efficiency.valid"
                                :feedback="
                                    $t(calendarSettings.modalEvents.states.efficiency.feedback)
                                "
                                @input="(e) => handleInputEfficiency(e.target.value)"
                                @blur="handleBlurEfficiency()"
                                @keypress="(e) => handleKeyPressEfficiency(e)"
                                :disabled="
                                    calendarSettings.modalEvents.fields.exceptionEventType !=
                                        this.exceptionEventTypes.overtime &&
                                    calendarSettings.modalEvents.fields.exceptionEventType !=
                                        this.exceptionEventTypes.onShift
                                "
                            />
                        </div>
                    </div>

                    <sys-form-datepicker
                        id="date"
                        type="date"
                        :label="$t('CalendarsPage.Date')"
                        :value="calendarSettings.modalEvents.fields.date"
                        :state="calendarSettings.modalEvents.states.date.valid"
                        :feedback="$t(calendarSettings.modalEvents.states.date.feedback)"
                        @input="(value) => UPDATE_MODAL_EVENTS_DATE_FIELD(value)"
                    />

                    <div class="sidebar-modal-events__fields-row">
                        <div class="sidebar-modal-events__hour-fields">
                            <div class="sidebar-modal-events__starts-field">
                                <sys-form-datepicker
                                    id="starts"
                                    type="hour"
                                    :label="$t('CalendarsPage.Starts')"
                                    :value="calendarSettings.modalEvents.fields.startTime"
                                    :state="calendarSettings.modalEvents.states.startTime.valid"
                                    :feedback="
                                        $t(calendarSettings.modalEvents.states.startTime.feedback)
                                    "
                                    :disabled="calendarSettings.modalEvents.fields.allDay"
                                    @input="(value) => UPDATE_MODAL_EVENTS_START_TIME_FIELD(value)"
                                />
                            </div>

                            <div class="sidebar-modal-events__ends-field">
                                <sys-form-datepicker
                                    id="ends"
                                    type="hour"
                                    :label="$t('CalendarsPage.Ends')"
                                    :value="calendarSettings.modalEvents.fields.endTime"
                                    :state="calendarSettings.modalEvents.states.endTime.valid"
                                    :feedback="
                                        $t(calendarSettings.modalEvents.states.endTime.feedback)
                                    "
                                    :disabled="calendarSettings.modalEvents.fields.allDay"
                                    @input="(value) => UPDATE_MODAL_EVENTS_END_TIME_FIELD(value)"
                                />
                            </div>
                        </div>

                        <div class="sidebar-modal-events__all-day-field">
                            <sys-switch
                                size="md"
                                :value="calendarSettings.modalEvents.fields.allDay"
                                :label="$t('CalendarsPage.AllDayField')"
                                @input="handleOnInputAllDay"
                            />
                        </div>
                    </div>

                    <sys-form-input
                        :label="$t('CalendarsPage.Description')"
                        :value="calendarSettings.modalEvents.fields.description"
                        @input="(e) => UPDATE_MODAL_EVENTS_DESCRIPTION_FIELD(e.target.value)"
                        :placeholder="$t('TypeHere')"
                        height="98px"
                        textarea
                    />

                    <recurrence />
                </div>
                <div
                    v-if="currentStep === 2 && isException"
                    class="h-100"
                >
                    <Resources
                        :flagToUnCheck="flagToUnCheck"
                        @onUnlinkResource="openUnlinkModal"
                        @openInfoResource="openInfoResource"
                    />
                </div>
            </template>

            <template #footer>
                <div
                    v-if="windowWidth <= 707 && !calendarSettings.modalEvents.eventId"
                    class="step-action"
                >
                    <div class="d-flex justify-content-between">
                        <div
                            :class="[
                                'step-collapse',
                                isStepBlockOpenned ? 'not-collapsed' : 'collapsed'
                            ]"
                        >
                            {{ `${currentStep} ${$t("Of")} 2`
                            }}<span
                                id="step-collapse"
                                @click="openStepBlock()"
                                class="icon"
                            >
                                <ArrowDownIcon
                            /></span>
                        </div>
                        <div class="text">{{ $t(getModalTitle(currentStep)) }}</div>
                    </div>
                    <div class="step-progress">
                        <div
                            :class="[
                                'first-step',
                                currentStep === 1
                                    ? 'step-active'
                                    : currentStep > 1
                                      ? 'next-step'
                                      : ''
                            ]"
                        ></div>
                        <div
                            :class="[
                                'second-step',
                                currentStep === 2
                                    ? 'step-active'
                                    : currentStep > 2
                                      ? 'next-step'
                                      : ''
                            ]"
                        ></div>
                    </div>
                    <b-collapse :visible="isStepBlockOpenned">
                        <div class="progress-block">
                            <div class="separator-progress"></div>
                            <div class="detail-block">
                                <div
                                    :class="[
                                        'info-block',
                                        currentStep === 1
                                            ? 'step-active'
                                            : currentStep > 1
                                              ? 'next-step'
                                              : ''
                                    ]"
                                >
                                    <div class="icon"><InfoIcon /></div>
                                    <div class="text">
                                        {{ $t("PeoplePage.GeneralInformation") }}
                                    </div>
                                </div>
                                <div
                                    :class="[
                                        'vertical-separator',
                                        currentStep === 2 ? 'next-step' : ''
                                    ]"
                                ></div>
                                <div
                                    :class="[
                                        'attachments-block',

                                        currentStep === 2 ? 'step-active' : ''
                                    ]"
                                >
                                    <div class="icon"><ResourceIcon /></div>
                                    <div class="text">
                                        {{ $t("CalendarsPage.ResourceSelection") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div
                    v-if="verifyFooterShow()"
                    :class="[
                        'sidebar-modal-events__footer-container',
                        isException && !calendarSettings.modalEvents.eventId
                            ? '--has-step-block'
                            : ''
                    ]"
                >
                    <div class="sidebar-modal-events__footer-content-right">
                        <sys-button
                            scheme="square"
                            variant="outline"
                            :size="handleFooterButtonSize()"
                            :full="isException ? windowWidth <= 707 : windowWidth <= 480"
                            @click="handleCancelEvent"
                            :noBorder="isException && currentStep === 2"
                        >
                            {{ $t("CalendarsPage.Cancel") }}
                        </sys-button>

                        <sys-button
                            v-if="!calendarSettings.modalEvents.eventId && currentStep !== 1"
                            scheme="square"
                            variant="outline"
                            :size="handleFooterButtonSize()"
                            :full="isException ? windowWidth <= 707 : windowWidth <= 480"
                            @click="previousStep()"
                        >
                            {{ $t("PeoplePage.Previous") }}
                        </sys-button>

                        <sys-button
                            scheme="square"
                            variant="contained"
                            :size="handleFooterButtonSize()"
                            :full="isException ? windowWidth <= 707 : windowWidth <= 480"
                            @click="handleSaveEvent"
                        >
                            {{
                                isException &&
                                !calendarSettings.modalEvents.eventId &&
                                currentStep === 1
                                    ? $t("RefuseStatus.Next")
                                    : $t("CalendarsPage.Save")
                            }}
                        </sys-button>
                    </div>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import { BSidebar, BCollapse } from "bootstrap-vue";
    import SysButton from "@/@core/components/sys-button";
    import SysFormInput from "@/@core/components/sys-form-input";
    import SysFormDatepicker from "@/@core/components/sys-form-datepicker";
    import SysFormSelect from "@/@core/components/sys-form-select";
    import SysCheckbox from "@/@core/components/sys-checkbox";
    import SysSwitch from "@/@core/components/sys-switch";
    import Recurrence from "./Recurrence.vue";
    import exceptionEventTypes from "@/views/pages/calendar/enums/exceptionEventTypes";
    import frequency from "@/views/pages/calendar/enums/frequency";
    import modalStepByStep from "./ModalStepByStep.vue";
    import Resources from "./Resources.vue";
    import ModalLinkResources from "../ModalLinkResources.vue";
    import ModalOptionsResource from "../ModalOptionsResource.vue";
    import ModalInfoResource from "../ModalInfoResource.vue";
    import { saveCalendarService } from "@/views/pages/calendar/actionCalendar/services";
    import { timeToMinutes } from "@/views/pages/calendar/actionCalendar/utils/index.js";

    export default {
        data() {
            return {
                isOpen: false,
                exceptionEventTypes: exceptionEventTypes,
                frequency: frequency,
                currentStep: 1,
                flagToUnCheck: 1,
                isStepBlockOpenned: false,
                resourceInfo: {}
            };
        },
        props: {
            isException: {
                type: Boolean,
                default: false
            }
        },
        components: {
            BSidebar,
            BCollapse,
            SysButton,
            SysFormInput,
            SysFormSelect,
            SysCheckbox,
            SysFormDatepicker,
            SysSwitch,
            Recurrence,
            modalStepByStep,
            Resources,
            ModalLinkResources,
            ModalOptionsResource,
            ModalInfoResource,
            AddIcon: () => import("@core/assets/icons/add-icon.svg"),
            CalendarIcon: () => import("@/assets/images/icons/calendar-icon.svg"),
            ArrowDownIcon: () => import("@core/assets/icons/arrow-down.svg"),
            SaveIcon: () => import("@core/assets/icons/save-icon.svg"),
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            InfoIcon: () => import("@core/assets/icons/info-icon.svg"),
            ResourceIcon: () => import("@core/assets/icons/select-resource-icon.svg")
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_EVENTS_TOGGLE",
                "UPDATE_MODAL_EVENTS_NAME_FIELD",
                "UPDATE_MODAL_EVENTS_EVENT_TYPE_FIELD",
                "UPDATE_MODAL_EVENTS_EFFICIENCY_FIELD",
                "UPDATE_MODAL_EVENTS_DESCRIPTION_FIELD",
                "UPDATE_EVENT_LIST",
                "UPDATE_MODAL_EVENTS_ALL_DAY_FIELD",
                "UPDATE_MODAL_EVENTS_DATE_FIELD",
                "UPDATE_MODAL_EVENTS_START_TIME_FIELD",
                "UPDATE_MODAL_EVENTS_END_TIME_FIELD",
                "RESET_MODAL_EVENTS",
                "UPDATE_MODAL_EVENTS_STATES"
            ]),
            handleOnInputAllDay(value) {
                this.UPDATE_MODAL_EVENTS_ALL_DAY_FIELD(value);

                this.UPDATE_MODAL_EVENTS_STATES({
                    ...this.calendarSettings.modalEvents.states,
                    startTime: {
                        valid: true,
                        feedback: ""
                    },
                    endTime: {
                        valid: true,
                        feedback: ""
                    }
                });
            },
            closeModal() {
                this.UPDATE_MODAL_EVENTS_TOGGLE(false);
                this.RESET_MODAL_EVENTS();
                this.currentStep = 1;
            },
            openUnlinkModal() {},
            openInfoResource(item) {
                this.resourceInfo = item;
            },
            verifyIfHasModalsOpen() {
                const modalLinkResource =
                    this.calendarSettings.resources.modal.isModalLinkResourcesOpen;
                const modalResourceInfo = this.isModalResourceInfoOpen;
                const modalResourceOptions = this.isModalResourceOptions;
                const isEditEvent = this.calendarSettings.modalEvents.eventId;

                if (
                    this.isException &&
                    isEditEvent &&
                    (modalLinkResource || modalResourceInfo || modalResourceOptions)
                ) {
                    return true;
                }

                return false;
            },
            formatEfficiency(value) {
                const formattedValue =
                    value === "." ? "" : value.replace(/[^0-9.]/g, "").replace(/^0(\d)/, "$1");

                return formattedValue;
            },
            openStepBlock() {
                this.isStepBlockOpenned = !this.isStepBlockOpenned;
            },
            getModalTitle(v) {
                if (v === 1) return "PeoplePage.GeneralInformation";
                if (v === 2) return "CalendarsPage.ResourceSelection";
            },
            handleBlurEfficiency() {
                let formattedValue = this.calendarSettings.modalEvents.fields.efficiency.replace(
                    /[^0-9.]/g,
                    ""
                );

                if (formattedValue[formattedValue.length - 1] == ".") {
                    formattedValue = formattedValue.replace(".", "");
                }

                this.UPDATE_MODAL_EVENTS_EFFICIENCY_FIELD(
                    formattedValue ? formattedValue + "%" : ""
                );
            },
            handleInputEfficiency(value) {
                const formattedValue = this.formatEfficiency(value);
                this.UPDATE_MODAL_EVENTS_EFFICIENCY_FIELD(formattedValue);
            },
            handleKeyPressEfficiency(event) {
                const key = event.key;

                // Permite apenas números e o ponto (.)
                if (!/[0-9.]/.test(key)) {
                    event.preventDefault(); // Impede a entrada de outros caracteres
                }

                if (
                    key === "." &&
                    this.calendarSettings.modalEvents.fields.efficiency.includes(".")
                ) {
                    event.preventDefault(); // Impede a inserção de mais de um ponto
                }
            },
            handleFooterButtonSize() {
                if (this.windowWidth > 768) {
                    return "md";
                }

                if (this.windowWidth > 480 && this.windowWidth <= 768) {
                    return "md";
                }

                if (this.windowWidth <= 480) {
                    return "xs";
                }
            },
            verifyFooterShow() {
                const isEditEvent = this.calendarSettings.modalEvents.eventId;

                if (!isEditEvent) return true;

                if (this.currentStep === 2) return false;

                return true;
            },
            adjustTimeZone3(date) {
                // Lógica temporaria para resolver problema de -3 do fuso
                const offset = date.getTimezoneOffset() * 60000;
                const localISOTime = new Date(date.getTime() - offset).toISOString().slice(0, -1);

                return localISOTime;
            },
            handleSaveEvent() {
                const handleCreate = (eventListCopy, newEvent) => {
                    eventListCopy.push(newEvent);
                    this.UPDATE_EVENT_LIST([...eventListCopy]);

                    this.$setToast({
                        title: this.$t("Success"),
                        icon: "SmileIcon",
                        text: this.$t("CalendarsPage.EventCreated"),
                        variant: "success"
                    });

                    this.closeModal();
                    saveCalendarService(this);
                };

                const handleEdit = (eventListCopy, newEvent) => {
                    const eventIndex = eventListCopy.findIndex((i) => i.id == newEvent.id);

                    if (eventIndex === -1) return;

                    eventListCopy[eventIndex] = { ...newEvent };

                    this.UPDATE_EVENT_LIST([...eventListCopy]);
                    this.closeModal();

                    saveCalendarService(this);
                };

                const formatRecurrence = (f, vm) => {
                    const recurrence = { ...f.recurrence };

                    if (!f.isRecurrent) return null;

                    if (recurrence.frequency === vm.frequency.day) {
                        recurrence.daysOfWeek = [];
                        recurrence.weeksOfMonth = [];
                        recurrence.months = [];
                    }

                    if (recurrence.frequency === vm.frequency.week) {
                        recurrence.daysOfMonth = [];
                        recurrence.weeksOfMonth = [];
                        recurrence.months = [];
                    }

                    if (recurrence.frequency === vm.frequency.month && recurrence.isOnThe) {
                        recurrence.daysOfWeek = [];
                        recurrence.weeksOfMonth = [];
                        recurrence.months = [];
                    }

                    if (recurrence.frequency === vm.frequency.month && recurrence.isOn) {
                        recurrence.daysOfMonth = [];
                        recurrence.months = [];
                    }

                    if (recurrence.frequency === vm.frequency.year && recurrence.isOnThe) {
                        recurrence.daysOfWeek = [];
                        recurrence.weeksOfMonth = [];
                    }

                    if (recurrence.frequency === vm.frequency.year && recurrence.isOn) {
                        recurrence.daysOfMonth = [];
                    }

                    return {
                        ...recurrence,
                        startDate: vm.adjustTimeZone3(recurrence.startDate),
                        endDate: recurrence.endDate ? vm.adjustTimeZone3(recurrence.endDate) : null,
                        frequencyId: recurrence.frequency,
                        quantity: Number(recurrence.quantity),
                        daysOfWeek: recurrence.daysOfWeek.map(Number),
                        daysOfMonth: recurrence.daysOfMonth.map(Number),
                        weeksOfMonth: recurrence.weeksOfMonth.map(Number),
                        months: recurrence.months.map(Number)
                    };
                };

                const resultValidation = this.validateFields();

                if (!resultValidation) return;

                const eventList = [...this.calendarSettings.events.eventList];

                const fields = { ...this.calendarSettings.modalEvents.fields };
                const newEvent = {
                    id:
                        this.calendarSettings.modalEvents.eventId ??
                        crypto.getRandomValues(new Uint8Array(4)).join(""),
                    origin: this.calendarSettings.modalEvents.origin ?? "front",
                    name: fields.name,
                    calendarEventTypeId: fields.exceptionEventType,
                    efficiency:
                        this.calendarSettings.modalEvents.fields.exceptionEventType ==
                            this.exceptionEventTypes.overtime ||
                        this.calendarSettings.modalEvents.fields.exceptionEventType ==
                            this.exceptionEventTypes.onShift
                            ? Number(fields.efficiency.replace(/%/g, ""))
                            : 0,
                    date: this.adjustTimeZone3(fields.date),
                    startTime:
                        !fields.allDay && !!fields.startTime
                            ? this.adjustTimeZone3(fields.startTime)
                                  ?.split("T")?.[1]
                                  ?.substring(0, 5)
                            : null,
                    endTime:
                        !fields.allDay && !!fields.endTime
                            ? this.adjustTimeZone3(fields.endTime)?.split("T")?.[1]?.substring(0, 5)
                            : null,
                    allDay: fields.allDay,
                    description: fields.description || null,
                    isRecurrent: !!fields.isRecurrent,
                    recurrence: formatRecurrence(fields, this)
                };

                if (this.calendarSettings.modalEvents.eventId === null) {
                    handleCreate(eventList, newEvent);

                    return;
                }

                handleEdit(eventList, newEvent);
            },
            previousStep() {
                this.currentStep = this.currentStep - 1;
            },
            validateFields() {
                const modalEvents = { ...this.calendarSettings.modalEvents };
                const fields = { ...modalEvents.fields };
                const states = { ...modalEvents.states };
                const events = { ...this.calendarSettings.events };

                const validations = {
                    name: () => {
                        if (!fields.name) {
                            states.name = {
                                valid: false,
                                feedback: "RequiredField"
                            };
                            return false;
                        }

                        const existingName = events.eventList.some((i) => i.name == fields.name);

                        if (existingName && modalEvents.initialName !== fields.name) {
                            states.name = {
                                valid: false,
                                feedback: "CalendarsPage.EqualName"
                            };

                            return false;
                        }

                        states.name = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    exceptionEventType: () => {
                        if (!fields.exceptionEventType?.toString()) {
                            states.exceptionEventType = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.exceptionEventType = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    efficiency: () => {
                        if (
                            !fields.efficiency &&
                            fields.exceptionEventTypes == this.exceptionEventTypes.overtime &&
                            fields.exceptionEventTypes == this.exceptionEventTypes.onShift
                        ) {
                            states.efficiency = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.efficiency = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    date: () => {
                        if (!fields.date) {
                            states.date = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.date = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    startTime: () => {
                        if (!fields.startTime && !fields.allDay) {
                            states.startTime = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.startTime = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    endTime: () => {
                        if (!fields.endTime && !fields.allDay) {
                            states.endTime = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        if (fields.endTime && fields.startTime && !fields.allDay) {
                            const formattedStartTime = fields.startTime
                                ? this.adjustTimeZone3(fields.startTime)
                                      ?.split("T")?.[1]
                                      ?.substring(0, 5)
                                : null;
                            const formattedEndTime = fields.endTime
                                ? this.adjustTimeZone3(fields.endTime)
                                      ?.split("T")?.[1]
                                      ?.substring(0, 5)
                                : null;

                            const startTimeInMinutes = timeToMinutes(formattedStartTime);
                            const endTimeInMinutes = timeToMinutes(formattedEndTime);

                            if (endTimeInMinutes <= startTimeInMinutes) {
                                states.endTime = {
                                    valid: false,
                                    feedback: "CalendarsPage.InvalidTime"
                                };

                                return false;
                            }
                        }

                        states.endTime = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    recurrence_startDate: () => {
                        if (!fields.isRecurrent) {
                            states.recurrence.startDate = {
                                valid: true,
                                feedback: ""
                            };

                            return true;
                        }

                        if (!fields.recurrence.startDate) {
                            states.recurrence.startDate = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.recurrence.startDate = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    recurrence_quantity: () => {
                        if (!fields.isRecurrent) {
                            states.recurrence.quantity = {
                                valid: true,
                                feedback: ""
                            };

                            return true;
                        }

                        if (!fields.recurrence.quantity || fields.recurrence.quantity == "0") {
                            states.recurrence.quantity = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.recurrence.quantity = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    recurrence_frequency: () => {
                        if (!fields.isRecurrent) {
                            states.recurrence.frequency = {
                                valid: true,
                                feedback: ""
                            };

                            return true;
                        }

                        if (!fields.recurrence.frequency) {
                            states.recurrence.frequency = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.recurrence.frequency = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    recurrence_daysOfMonth: () => {
                        if (
                            !fields.isRecurrent ||
                            fields.recurrence.frequency == this.frequency.day ||
                            fields.recurrence.frequency == this.frequency.week ||
                            !!fields.recurrence.isOn
                        ) {
                            states.recurrence.daysOfMonth = {
                                valid: true,
                                feedback: ""
                            };

                            return true;
                        }

                        if (
                            !fields.recurrence.daysOfMonth.length ||
                            (fields.recurrence.daysOfMonth.length &&
                                fields.recurrence.daysOfMonth[0] == "0")
                        ) {
                            states.recurrence.daysOfMonth = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.recurrence.daysOfMonth = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    }
                };

                let isValid = true;

                for (const action of Object.values(validations)) {
                    const resultValidation = action?.();

                    if (isValid) {
                        isValid = resultValidation;
                    }
                }

                this.UPDATE_MODAL_EVENTS_STATES({ ...states });

                return isValid;
            },
            handleCancelEvent() {
                this.closeModal();
            }
        },
        computed: {
            ...mapState("calendar", [
                "isModalEventsOpen",
                "calendarSettings",
                "context",
                "isModalResourceInfoOpen",
                "isModalResourceOptions"
            ]),
            ...mapState("app", ["windowWidth"])
        },
        watch: {
            isModalEventsOpen(v) {
                this.isOpen = v;
            }
        }
    };
</script>

<style lang="scss">
    .sidebar-modal-events {
        &.--role-openned {
            .b-sidebar.b-sidebar-right {
                right: 407px;
                transition: all 0.5s ease-out;
                @media (max-width: 814px) {
                    left: 0;
                    width: 50vw !important;
                }
                @media (max-width: 480px) {
                    left: 0;
                    width: 100vw !important;
                }
            }
        }
        &.--role-closed {
            .b-sidebar.b-sidebar-right {
                right: 0;
                transition: all 0.5s ease-out;

                @media (max-width: 480px) {
                    left: 0;
                    width: 100vw !important;
                }
            }
        }
        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                width: 100%;
                padding: 16px;

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;

                    @media (max-width: 480px) {
                        font-size: 16px;
                    }
                }

                .close-icon {
                    border: 1px solid #974900;
                    border-radius: 5px;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    svg {
                        width: 12px;
                        height: 12px;
                        path {
                            fill: #974900;
                        }
                    }

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }
            }

            .modal-navbar {
                display: flex;

                .info,
                .resource {
                    cursor: pointer;
                    width: 100%;
                    text-align: center;
                    color: #998f8a;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 16px 0 14px;
                    border-bottom: 1px solid #cfc4be;

                    &.active {
                        color: #974900;
                        background: #fff;
                        box-shadow: 0px -4px 0px -1px #974900 inset;
                    }
                }
            }
        }

        .b-sidebar {
            width: 407px;
            box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
            transition: all 0.5s ease-out;
            height: 100% !important;
            .b-sidebar-body {
                padding: 0;

                &:has(.--no-events) {
                    overflow-y: hidden;
                }

                .col-md-12 {
                    padding: 0;
                }

                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
        }

        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;

            .btn-apply {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                box-shadow: none;
                width: 100%;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.175px;
                padding: 8px 0;
                border: 1px solid #974900 !important;
                background-color: #974900 !important;
                color: #ffff !important;

                svg {
                    width: 14px;
                    height: 14px;
                    fill: #fff;
                }

                &:hover {
                    box-shadow: none;
                }

                @media (max-width: 480px) {
                    font-size: 12px;
                    padding: 3px 0;
                }
            }

            .footer-events {
                display: flex;
                justify-content: space-between;
                gap: 8px;
                width: 100%;

                .year-block {
                    display: flex;
                    align-items: center;
                    padding: 4px 10px;
                    border-radius: 5px;
                    border: 1px solid #974900;

                    .years-dropdown {
                        padding: 0 8px 0 6px;
                    }

                    .arrow-icon,
                    .calendar-icon {
                        width: 14px;
                        height: 14px;
                        fill: #974900;
                    }

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }

                    @media (max-width: 480px) {
                        font-size: 12px;
                        padding: 0 10px;

                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                .btn-new-event {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.175px;
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    padding: 8px 14px;

                    svg {
                        width: 14px;
                        height: 14px;
                        fill: #fff;
                    }

                    &:hover {
                        box-shadow: none;
                    }

                    @media (max-width: 480px) {
                        font-size: 12px;
                        padding: 3px 14px;

                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                .dropdown-toggle {
                    padding: 0;
                    color: #974900;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.175px;
                }

                .custom-dropdown-menu {
                    max-height: 200px;
                    overflow-y: auto;

                    .selected-year {
                        background-color: #974900;

                        .dropdown-item {
                            color: #fff;
                        }

                        .dropdown-item:hover,
                        .dropdown-item:focus {
                            color: #fff;
                        }

                        .dropdown-item:active {
                            background: #974900;
                        }
                    }

                    .dropdown-item:hover,
                    .dropdown-item:focus {
                        color: #974900;
                    }

                    .dropdown-item:active {
                        background: #ffdbc4;
                    }
                }
            }

            &.--no-events {
                border: none;
                padding: 0;

                .footer-events {
                    display: none;
                }
            }
        }

        .step-action {
            padding: 16px 0;
            border-top: 1px solid #cfc4be;

            .step-collapse {
                padding-left: 16px;
            }

            .step-collapse,
            .text {
                display: flex;
                gap: 8px;
                color: #4c4541;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                .icon {
                    svg {
                        width: 16px;
                        height: 16px;
                        fill: #974900;
                    }
                }

                &.not-collapsed {
                    svg {
                        transform: rotate(-180deg);
                        transition: all 0.3s;
                    }
                }

                &.collapsed {
                    svg {
                        transform: rotate(0);
                        transition: all 0.3s;
                    }
                }
            }
            .text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 16px;
            }

            .step-progress {
                display: flex;
                gap: 4px;
                padding: 4px 16px 0;
                .first-step,
                .second-step,
                .third-step {
                    width: 50%;
                    height: 4px;
                    border-radius: 4px;
                    background: #f4eee8;

                    &.step-active {
                        background: #974900;
                    }

                    &.next-step {
                        background: #66bb6a;
                    }
                }
            }
        }

        .progress-block {
            padding-top: 16px;

            .detail-block {
                padding: 16px 16px 0;

                .info-block,
                .attachments-block,
                .times-block {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .icon {
                        display: flex;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        padding: 7px;
                        border-radius: 50%;
                        border: 1px solid #cfc4be;

                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #cfc4be;
                        }
                    }

                    .text {
                        overflow: hidden;
                        color: #cfc4be;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                    }

                    &.step-active {
                        .icon {
                            border-color: #974900;
                            svg {
                                fill: #974900;
                            }
                        }

                        .text {
                            color: #974900;
                        }
                    }

                    &.next-step {
                        .icon {
                            border-color: #66bb6a;
                            background: #66bb6a;
                            svg {
                                fill: #fff;
                            }
                        }

                        .text {
                            color: #66bb6a;
                        }
                    }
                }

                .vertical-separator {
                    margin-left: 14px;
                    width: 1px;
                    height: 16px;
                    background: #cfc4be;

                    &.step-active {
                        background: #974900;
                    }

                    &.next-step {
                        background: #66bb6a;
                    }
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
            z-index: 2;
        }

        .sidebar-modal-events__fields {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px;

            .sidebar-modal-events__fields-row {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                width: 100%;
            }

            .sidebar-modal-events__hour-fields {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                width: 100%;
            }

            .sidebar-modal-events__type-field,
            .sidebar-modal-events__efficiency-field {
                min-width: 179.5px;
                max-width: 179.5px;
            }

            .sidebar-modal-events__starts-field,
            .sidebar-modal-events__ends-field {
                min-width: 113px;
                max-width: 113px;
            }

            .sidebar-modal-events__all-day-field {
                margin-top: 39px;
                display: flex;
            }
        }

        .sidebar-modal-events__footer-container {
            border-top: 1px solid #cfc4be;
            padding: 15.5px 16px;
            display: flex;
            .sidebar-modal-events__footer-content-right {
                margin-left: auto;
                display: flex;
                gap: 8px;
            }

            &.--has-step-block {
                @media (max-width: 707px) {
                    border-top: 1px solid #eee;
                    .sidebar-modal-events__footer-content-right {
                        width: 100%;
                        margin-left: initial !important;
                        display: flex;
                        gap: 8px;
                    }
                }
            }
        }
    }

    @media (max-width: 480px) {
        .b-sidebar {
            width: 100% !important;
        }

        .sidebar-modal-events__fields {
            .sidebar-modal-events__fields-row {
                flex-direction: column !important;
            }

            .sidebar-modal-events__type-field,
            .sidebar-modal-events__efficiency-field {
                min-width: 100% !important;
                max-width: 100% !important;
            }

            .sidebar-modal-events__starts-field,
            .sidebar-modal-events__ends-field {
                min-width: initial !important;
                max-width: initial !important;
            }

            .sidebar-modal-events__all-day-field {
                margin-top: 0 !important;
            }
        }

        .sidebar-modal-events__footer-container {
            border-top: 1px solid #cfc4be;
            padding: 15.5px 16px;
            display: flex;
            .sidebar-modal-events__footer-content-right {
                width: 100%;
                margin-left: initial !important;
                display: flex;
                gap: 8px;
            }
        }
    }
</style>
