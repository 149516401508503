<template>
    <div id="months-events">
        <div
            v-for="(month, index) in monthGroups"
            :key="month.year + month.monthName"
        >
            <div
                @click="toggleCollapse(index)"
                class="month-header"
            >
                <div class="event-name">{{ month.monthName }} • {{ month.year }}</div>
                <div class="event-counter">
                    {{
                        `${month.events.length} ${$t("CalendarsPage.event")}${month.events.length > 1 ? "s" : ""} `
                    }}
                    <ArrowRightIcon
                        :class="['arrow-icon', openIndices.includes(index) ? '--is-open' : '']"
                    />
                </div>
            </div>
            <b-collapse
                :id="'collapse-' + index"
                :visible="openIndices.includes(index)"
            >
                <div class="collapse-body">
                    <Events :events="month.events" />
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
    import { BCollapse } from "bootstrap-vue";
    import Events from "./Events.vue";
    import ArrowRightIcon from "@core/assets/icons/arrow-right.svg";

    export default {
        components: {
            BCollapse,
            Events,
            ArrowRightIcon
        },
        props: {
            monthGroups: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                openIndices: []
            };
        },
        methods: {
            toggleCollapse(index) {
                if (this.openIndices.includes(index)) {
                    this.openIndices = this.openIndices.filter((i) => i !== index);
                } else {
                    this.openIndices.push(index);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    #months-events {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .month-header {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 16px;
            border-radius: 2px;
            background: #f4eee8;

            .event-name {
                color: #4c4541;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
            }

            .event-counter {
                color: #4c4541;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;

                .arrow-icon {
                    width: 16px;
                    height: 16px;
                    fill: #4c4541;

                    &.--is-open {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }
</style>
