import weeksOfMonth from "./weeksOfMonth";

const weeksOfMonthList = Object.freeze([
    {
        id: weeksOfMonth.first,
        text: "Primeira",
        name: "Primeira",
        nameEN: "First"
    },
    {
        id: weeksOfMonth.second,
        text: "Segunda",
        name: "Segunda",
        nameEN: "Second"
    },
    {
        id: weeksOfMonth.third,
        text: "Terceira",
        name: "Terceira",
        nameEN: "Third"
    },
    {
        id: weeksOfMonth.fourth,
        text: "Quarta",
        name: "Quarta",
        nameEN: "Fourth"
    },
    {
        id: -1,
        text: "Última",
        name: "Última",
        nameEN: "Last"
    }
]);

export default weeksOfMonthList;
