var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"settings-calendar"}},[_c('div',{staticClass:"settings-header"},[_c('span',{class:['tab-title', _vm.currentBody === 'general' ? '--tab-active' : ''],on:{"click":function($event){_vm.currentBody = 'general'}}},[_vm._v(_vm._s(_vm.$t("AccountSetting.General")))]),_c('span',{class:['tab-title', _vm.currentBody === 'events' ? '--tab-active' : ''],on:{"click":function($event){_vm.currentBody = 'events'}}},[_vm._v(_vm._s(_vm.$t("CalendarsPage.Events")))]),_c('span',{class:['tab-title', _vm.currentBody === 'resources' ? '--tab-active' : ''],on:{"click":function($event){_vm.currentBody = 'resources'}}},[_vm._v(_vm._s(_vm.$t("WorkcenterList.Resources")))])]),_c('div',{class:[
            'settings-body',
            _vm.removeFooter ? '--is-modal' : '',
            _vm.currentBody === 'events' && !_vm.calendarSettings.events.eventList.length
                ? '--no-events'
                : ''
        ]},[_c('General',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentBody === 'general'),expression:"currentBody === 'general'"}]}),_c('Events',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentBody === 'events'),expression:"currentBody === 'events'"}],attrs:{"eventList":_vm.calendarSettings.events.eventList,"isModal":_vm.removeFooter}}),_c('Resources',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentBody === 'resources'),expression:"currentBody === 'resources'"}],ref:"resourcesComponent",attrs:{"resourcesList":_vm.calendarSettings.resources.resourcesList,"isModal":_vm.removeFooter}})],1),(!_vm.removeFooter)?_c('div',{class:[
            'settings-footer',
            (_vm.currentBody === 'events' && !_vm.calendarSettings.events.eventList.length) ||
            (_vm.currentBody === 'resources' && !_vm.calendarSettings.resources.resourcesList.length)
                ? '--no-events'
                : ''
        ]},[(_vm.currentBody === 'general')?_c('b-button',{staticClass:"btn-apply",on:{"click":function($event){return _vm.onApply()}}},[_c('SaveIcon'),_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.Apply"))+" ")],1):_vm._e(),(_vm.currentBody === 'events')?_c('div',{staticClass:"footer-events"},[_c('div',{staticClass:"year-block"},[_c('CalendarIcon',{staticClass:"calendar-icon"}),_c('b-dropdown',{ref:"dropdown",staticClass:"years-dropdown",attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":"","dropup":"","dropdown":false},on:{"shown":_vm.focusSelectedYear},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.helpers.eventsFilter.selectedYear)+" ")]},proxy:true}],null,false,1915810318)},[_c('div',{ref:"dropdownMenu",staticClass:"custom-dropdown-menu"},_vm._l((_vm.helpers.context.years),function(year){return _c('b-dropdown-item',{key:year,class:{
                                'selected-year': year === _vm.helpers.eventsFilter.selectedYear
                            },on:{"click":function($event){return _vm.selectYear(year)}}},[_vm._v(" "+_vm._s(year)+" ")])}),1)]),_c('ArrowDownIcon',{staticClass:"arrow-icon cursor-pointer",on:{"click":_vm.toggleDropdown}})],1),_c('b-button',{staticClass:"btn-new-event",on:{"click":function($event){return _vm.newEvent()}}},[_c('AddIcon'),_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.NewEvent"))+" ")],1)],1):_vm._e(),(_vm.currentBody === 'resources')?_c('div',{staticClass:"footer-resources"},[(_vm.hasCheckedResource)?_c('div',{staticClass:"block-checked"},[_c('b-button',{staticClass:"btn-clear",on:{"click":function($event){return _vm.clearCheckedResources()}}},[_vm._v(" "+_vm._s(_vm.$t("Clear"))+" ")]),_c('b-button',{staticClass:"btn-action",on:{"click":function($event){return _vm.onUnlinkResource()}}},[_c('UnLinkIcon'),_vm._v(" "+_vm._s(_vm.$t("StructurePage.UnlinkResource"))+" ")],1)],1):_c('b-button',{staticClass:"btn-action",on:{"click":function($event){return _vm.UPDATE_MODAL_RESOURCES_TOGGLE(true)}}},[_c('LinkIcon'),_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.NewResource"))+" ")],1)],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }