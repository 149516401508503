<template>
    <div id="events-block">
        <div
            v-for="event in events"
            :key="event.id"
            :class="['event-block', verifyIfOutDated(event.date) ? '--is-outdated' : '']"
        >
            <div class="day-block">
                <div class="week-day">
                    {{ $t(`CalendarsPage.WeekDays.${getDayOfWeek(event.date)}`) }}
                </div>
                <div class="day">{{ getDayOfMonth(event.date) }}</div>
            </div>
            <div class="event-info">
                <div class="event-title">{{ event.name }}</div>
                <div class="info-block">
                    <div class="first-column">
                        <div class="event-description">
                            <EventIcon class="event-icon" /><span class="text">
                                {{ getEventType(event) }}
                            </span>
                        </div>
                        <div class="event-duration">
                            <DurationIcon class="duration-icon" />
                            <span class="text">
                                {{ getDurationEvent(event) }}
                            </span>
                        </div>
                    </div>
                    <div class="second-column">
                        <div
                            v-if="event.resourceIds.length > 0"
                            class="event-resource"
                        >
                            <ResourceIcon class="resource-icon" />
                            <span class="text">{{
                                `${event.resourceIds.length} ${$t("CalendarsPage.resource")}${event.resourceIds.length > 1 ? "s" : ""}`
                            }}</span>
                        </div>
                        <div
                            v-if="event.isRecurrent && windowWidth > 600"
                            class="event-recurrence"
                        >
                            <RecurrentIcon class="recurrent-icon" />
                            <span class="text">
                                {{ $t("CalendarsPage.Recurrence") }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-show="windowWidth > 600"
                class="action-block"
            >
                <sys-button
                    :id="'event-desktop' + event.id"
                    scheme="square"
                    variant="outline square-danger trash-icon-md"
                    @click="
                        () => {
                            getEvent(event);
                        }
                    "
                >
                    <DeleteIcon />
                </sys-button>
                <sys-button
                    size="md"
                    scheme="square"
                    variant="outline"
                    @click="() => {}"
                    class="white-background"
                >
                    {{ $t("Edit") }}
                </sys-button>
                <Popover
                    :items="optionList"
                    :target="'event-desktop' + event.id"
                    placement="bottomright"
                    custom-position="--max-content"
                    triggers="focus"
                />
            </div>
            <div
                v-show="windowWidth <= 600"
                class="control-block"
            >
                <OptionIcon
                    class="option-svg"
                    @click="getEvent(event)"
                    :id="'event-' + event.id"
                    tabindex="0"
                />
                <RecurrentIcon
                    v-if="event.isRecurrent"
                    class="recurrent-icon"
                />
                <Popover
                    :items="handleOptionList(event.isRecurrent)"
                    :target="'event-' + event.id"
                    placement="bottomright"
                    custom-position="--max-content"
                    triggers="focus"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import DurationIcon from "@core/assets/icons/duration-icon.svg";
    import EventIcon from "@core/assets/icons/event-icon.svg";
    import OptionIcon from "@core/assets/icons/option-icon.svg";
    import EditIcon from "@core/assets/icons/edit-icon.svg";
    import DeleteIcon from "@core/assets/icons/delete-trash-icon.svg";
    import Popover from "@core/layouts/layout-horizontal/components/Menu/Popover.vue";
    import PopoverDeleteIcon from "@core/assets/icons/delete-icon.svg";
    import RecurrentIcon from "@core/assets/icons/recurrent-icon.svg";
    import ResourceIcon from "@core/assets/icons/select-resource-icon.svg";
    import SysButton from "@/@core/components/sys-button";
    import { mapState } from "vuex";

    export default {
        components: {
            DurationIcon,
            EventIcon,
            OptionIcon,
            EditIcon,
            RecurrentIcon,
            ResourceIcon,
            DeleteIcon,
            Popover,
            PopoverDeleteIcon,
            SysButton
        },
        props: {
            events: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                currentEvent: null,
                optionList: [
                    {
                        title: "CalendarsPage.DeleteEvent",
                        icon: PopoverDeleteIcon,
                        click: () => {
                            this.deleteEvent();
                        }
                    },
                    {
                        title: "CalendarsPage.DeleteRecurrence",
                        icon: PopoverDeleteIcon,
                        click: () => {
                            this.deleteRecurrenceEvent(this.currentEvent);
                        }
                    }
                ],
                mobileOptionList: [
                    {
                        id: 1,
                        title: "ResourcePage.Edit",
                        icon: EditIcon,
                        click: () => {}
                    },
                    {
                        id: 2,
                        title: "CalendarsPage.DeleteEvent",
                        icon: PopoverDeleteIcon,
                        click: () => {
                            this.deleteEvent();
                        }
                    },
                    {
                        id: 3,
                        title: "CalendarsPage.DeleteRecurrence",
                        icon: PopoverDeleteIcon,
                        click: () => {
                            this.deleteRecurrenceEvent(this.currentEvent);
                        }
                    }
                ]
            };
        },
        methods: {
            verifyIfOutDated(dateString) {
                const inputDate = new Date(dateString);
                const currentDate = new Date();

                inputDate.setHours(0, 0, 0, 0);
                currentDate.setHours(0, 0, 0, 0);

                return inputDate < currentDate;
            },
            getDayOfWeek(dateString) {
                const date = new Date(dateString);
                const daysOfWeek = [
                    "sunday",
                    "monday",
                    "tuesday",
                    "wednesday",
                    "thursday",
                    "friday",
                    "saturday"
                ];

                return daysOfWeek[date.getDay()];
            },
            getDay(dateString) {
                const date = new Date(dateString);
                return date.getDay();
            },
            getDayOfMonth(dateString) {
                const date = new Date(dateString);
                return date.getDate();
            },
            getEventType(event) {
                const calendarEventTypes = [
                    {
                        id: 1,
                        name: "Fora de turno",
                        nameEN: "Out of shift"
                    },
                    {
                        id: 2,
                        name: "Parada não planejada",
                        nameEN: "Unplanned downtime"
                    },
                    {
                        id: 3,
                        name: "Parada planejada",
                        nameEN: "Planned downtime"
                    },
                    {
                        id: 4,
                        name: "Hora extra",
                        nameEN: "Overtime"
                    },
                    {
                        id: 5,
                        name: "Em turno",
                        nameEN: "On shift"
                    }
                ];
                const eventType = calendarEventTypes.find((e) => e.id == event.eventTypeId);

                if (!event.efficiency) {
                    return eventType?.name;
                }

                return `${eventType?.name} ${event.efficiency}%`;
            },
            getDurationEvent(event) {
                return event.allDay
                    ? this.$t("CalendarsPage.AllDay")
                    : `${event.startTime.slice(0, 5)}-${event.endTime.slice(0, 5)}`;
            },
            handleOptionList(isRecurrent) {
                if (isRecurrent) return this.mobileOptionList;

                return this.mobileOptionList.filter((option) => option.id != 3);
            },
            getEvent(event) {
                this.currentEvent = event;
            },
            deleteEvent() {
                this.$swal({
                    title: this.$t("IntegrationPage.attention"),
                    html: `<div>${this.$t("CalendarsPage.DeleteEventMessage1")} <b>${this.currentEvent.name}</b>. ${this.$t("CalendarsPage.DeleteEventMessage2")}</div>`,
                    showCancelButton: true,
                    showCloseButton: true,
                    cancelButtonText: this.$t("IntegrationPage.cancel"),
                    confirmButtonText: this.$t("IntegrationPage.delete"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.value) {
                    }
                });
            },
            deleteRecurrenceEvent(event) {
                const hasDatePassed = this.verifyIfOutDated(event.date);

                this.$swal({
                    title: this.$t("IntegrationPage.attention"),
                    html: `<div>${this.$t("CalendarsPage.DeleteRecurrenceMessage1")} <b>${event.name}</b> ${hasDatePassed ? this.$t("CalendarsPage.DeleteRecurrenceMessage2") : this.$t("CalendarsPage.DeleteRecurrenceMessage3")}</div>`,
                    showCancelButton: true,
                    showCloseButton: true,
                    cancelButtonText: this.$t("IntegrationPage.cancel"),
                    confirmButtonText: this.$t("CalendarsPage.DeleteRecurrence"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.value) {
                    }
                });
            }
        },
        computed: {
            ...mapState("app", ["windowWidth"])
        }
    };
</script>

<style lang="scss" scoped>
    #events-block {
        padding-top: 16px;

        .event-block {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            margin-top: 16px;
            border-radius: 6px;
            border: 1px solid #e0e0e0;
            background: #fff;
            padding: 15px 16px;

            &.--is-outdated {
                background: repeating-linear-gradient(
                    -45deg,
                    #eee,
                    #eee 25px,
                    #fff 25px,
                    #fff 40px
                );
            }
        }

        .day-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding-right: 16px;
            border-right: 1px solid #e0e0e0;
            max-width: 68px;
            width: 100%;

            .week-day {
                color: #998f8a;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
            }

            .day {
                color: #4c4541;
                font-size: 22px;
                font-weight: 600;
                line-height: 30px;
            }
        }

        .event-info {
            width: 100%;
            min-width: 100px;

            .event-title {
                overflow: hidden;
                color: #4c4541;
                text-overflow: ellipsis;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                padding-bottom: 8px;
                white-space: nowrap;
            }

            .info-block {
                display: flex;
                gap: 10px;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;

                .first-column,
                .second-column {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .first-column {
                    min-width: 150px;
                }

                .event-description,
                .event-duration,
                .event-recurrence,
                .event-resource {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .text {
                        color: #998f8a;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 16px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .event-icon,
                    .duration-icon,
                    .recurrent-icon,
                    .resource-icon {
                        width: 12px;
                        height: 12px;
                        overflow: initial;
                        fill: #998f8a;
                    }
                }

                @media (max-width: 600px) {
                    flex-direction: column;
                    gap: 4px;

                    .first-column {
                        min-width: unset;
                    }
                }
            }

            @media (max-width: 480px) {
                min-width: 50px;
            }
        }

        .action-block {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .control-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 30px;
            width: 100%;
            max-width: 28px;

            .option-svg {
                fill: #974900;
                width: 28px;
                height: 28px;
                cursor: pointer;

                &:hover {
                    background-color: #ffede2;
                    border-radius: 5px;
                }

                &:active {
                    background-color: #ffdbc4;
                    border-radius: 5px;
                }

                &:focus-within,
                &:focus {
                    background-color: #ffdbc4;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                }
            }

            .recurrent-icon {
                width: 12px;
                height: 12px;
            }
        }
    }
</style>
