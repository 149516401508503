var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('b-sidebar',{class:[
            'sidebar-modal-settings',
            _vm.calendarSettings.resources.modal.isModalLinkResourcesOpen && _vm.device > 480
                ? '--role-openned'
                : '--role-closed'
        ],attrs:{"id":"settings-sidebar-right","right":"","bg-variant":"white","width":_vm.device > 480 ? '60vw' : '100vw',"aria-hidden":false},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-sidebar d-flex justify-content-between"},[_c('span',{staticClass:"sidebar-title"},[_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.ConfigureCalendar"))+" ")]),_c('span',{staticClass:"close-icon",on:{"click":function($event){return _vm.closeModal()}}},[_c('CloseIcon')],1)]),_c('div',{staticClass:"separator"})]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"separator"}),_c('b-col',{class:[
                    'footer-sidebar',
                    (_vm.currentBody === 'events' && !_vm.calendarSettings.events.eventList.length) ||
                    (_vm.currentBody === 'resources' &&
                        !_vm.calendarSettings.resources.resourcesList.length)
                        ? '--no-events'
                        : ''
                ],attrs:{"md":"12"}},[(_vm.currentBody === 'general')?_c('b-button',{staticClass:"btn-apply",on:{"click":function($event){return _vm.onApply()}}},[_c('SaveIcon'),_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.Apply"))+" ")],1):_vm._e(),(_vm.currentBody === 'events')?_c('div',{staticClass:"footer-events"},[_c('div',{staticClass:"year-block"},[_c('CalendarIcon',{staticClass:"calendar-icon"}),_c('b-dropdown',{ref:"dropdownModal",staticClass:"years-dropdown",attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},on:{"shown":_vm.focusSelectedYear},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.helpers.eventsFilter.selectedYear)+" ")]},proxy:true}],null,false,1915810318)},[_c('div',{ref:"dropdownMenu",staticClass:"custom-dropdown-menu"},_vm._l((_vm.helpers.context.years),function(year){return _c('b-dropdown-item',{key:year,class:{
                                        'selected-year':
                                            year === _vm.helpers.eventsFilter.selectedYear
                                    },on:{"click":function($event){return _vm.selectYear(year)}}},[_vm._v(" "+_vm._s(year)+" ")])}),1)]),_c('ArrowDownIcon',{staticClass:"arrow-icon cursor-pointer",on:{"click":_vm.toggleDropdown}})],1),_c('b-button',{staticClass:"btn-new-event",on:{"click":function($event){return _vm.newEvent()}}},[_c('AddIcon'),_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.NewEvent"))+" ")],1)],1):_vm._e(),(_vm.currentBody === 'resources')?_c('div',{staticClass:"footer-resources"},[(_vm.hasCheckedResource)?_c('div',{staticClass:"block-checked"},[_c('b-button',{staticClass:"btn-clear",on:{"click":function($event){return _vm.clearCheckedResources()}}},[_vm._v(" "+_vm._s(_vm.$t("Clear"))+" ")]),_c('b-button',{staticClass:"btn-action",on:{"click":function($event){return _vm.onUnlinkResource()}}},[_c('UnLinkIcon'),_vm._v(" "+_vm._s(_vm.$t("StructurePage.UnlinkResource"))+" ")],1)],1):_c('b-button',{staticClass:"btn-action",on:{"click":function($event){return _vm.UPDATE_MODAL_RESOURCES_TOGGLE(true)}}},[_c('LinkIcon'),_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.NewResource"))+" ")],1)],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[[_c('b-col',{staticClass:"h-100",attrs:{"md":"12"}},[_c('Settings',{ref:"settingsComponent",attrs:{"removeFooter":true},on:{"currentBody":function (val) { return (_vm.currentBody = val); },"updateList":function (val) { return (_vm.hasCheckedResource = val); }}})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }