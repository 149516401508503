<template>
    <div :class="['sys-checkbox__container', size && `normal-${size}`]">
        <div
            :class="[
                'sys-checkbox',
                {
                    checked: value
                }
            ]"
            @click="$emit('input', !value)"
        >
            <span class="sys-checkbox__icon"><CheckIcon /></span>
        </div>

        <label
            class="sys-checkbox__label"
            v-if="!!label"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
    import CheckIcon from "@/@core/assets/formatted-icons/check-icon.svg";

    export default {
        components: {
            CheckIcon
        },
        props: {
            label: {
                type: String,
                default: ""
            },
            value: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: "md"
            }
        }
    };
</script>

<style lang="scss" scoped>
    .sys-checkbox__container {
        display: flex;
        align-items: center;
        gap: 5px;

        height: 100%;
        .sys-checkbox {
            display: flex;
            background-color: #fff;
            border: 1px solid #cfc4be;
            border-radius: 3px;
            cursor: pointer;
            align-items: center;
            justify-content: center;

            .sys-checkbox__icon {
                display: flex;
                .sys-checkbox__icon {
                    svg {
                        fill: #fff;
                    }
                }
            }

            &.checked {
                background-color: #974900;
            }
        }

        .sys-checkbox__label {
            color: #4c4541;
            margin: 0;
        }

        &.normal-xl {
            .sys-checkbox {
                min-width: 30px;
                max-width: 30px;
                min-height: 30px;
                max-width: 30px;
            }

            .sys-checkbox__label {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
            }

            .sys-checkbox__icon {
                min-width: 20px;
                max-width: 20px;
                min-height: 20px;
                max-width: 20px;
            }
        }

        &.normal-lg {
            .sys-checkbox {
                min-width: 24px;
                max-width: 24px;
                min-height: 24px;
                max-width: 24px;
            }

            .sys-checkbox__label {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }

            .sys-checkbox__icon {
                min-width: 16px;
                max-width: 16px;
                min-height: 16px;
                max-width: 16px;
            }
        }

        &.normal-md {
            .sys-checkbox {
                min-width: 18px;
                max-width: 18px;
                min-height: 18px;
                max-width: 18px;
            }

            .sys-checkbox__label {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                white-space: nowrap;
            }

            .sys-checkbox__icon {
                min-width: 12px;
                max-width: 12px;
                min-height: 12px;
                max-width: 12px;
            }
        }
    }
</style>
