<template>
    <div
        class="period__container"
        :style="{
            background: period.statusColor ? period.statusColor : '#000',
            'min-height': pixelBasePerMinute + 'px',
            'max-height': pixelBasePerMinute + 'px'
        }"
        @click="(event) => $emit('click', event, period)"
    >
        <span class="period__status-name">{{ period.statusName }}</span>
        <span
            class="period__range"
            v-show="pixelBasePerMinute > 28"
            >{{ period.startTime }} - {{ period.endTime }}
        </span>
        <span
            class="period__efficiency"
            v-show="pixelBasePerMinute > 28"
        >
            {{ $t("CalendarsPage.EfficiencyOf") }} {{ period.efficiency }}
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            period: {
                type: Object,
                default: () => ({})
            },
            pixelBasePerMinute: {
                type: Number,
                default: 1.2
            }
        }
    };
</script>

<style lang="scss">
    .day-column__container {
        .period__container {
            padding: 4px;
            display: flex;
            flex-direction: column;
            border: 1px solid transparent;
            cursor: pointer;
            z-index: 3;

            &:hover {
                background: #ffede2 !important;
                border: 1px solid #974900;

                .period__status-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                }

                .period__range,
                .period__efficiency,
                .period__status-name {
                    color: #4c4541;
                }
            }

            .period__status-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                min-height: fit-content;
            }

            .period__range,
            .period__efficiency {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #fff;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                min-height: fit-content;
            }
        }
    }
</style>
