<template>
    <div
        class="calendar-pagination-arrow__container"
        @click="(event) => $emit('click', event)"
    >
        <arrow-left v-if="direction === 'left'" />
        <arrow-right v-if="direction === 'right'" />
    </div>
</template>

<script>
    export default {
        components: {
            ArrowLeft: () => import("@/@core/assets/formatted-icons/arrow-left-icon.svg"),
            ArrowRight: () => import("@/@core/assets/formatted-icons/arrow-right-icon.svg")
        },
        props: {
            direction: {
                type: String,
                default: ""
            }
        }
    };
</script>

<style lang="scss" scoped>
    .calendar-pagination-arrow__container {
        padding: 8px;
        display: flex;
        cursor: pointer;
        svg {
            min-width: 12px;
            max-width: 12px;
            min-height: 12px;
            max-height: 12px;
            fill: #974900;
        }
    }
</style>
