<template>
    <div class="periods__container">
        <periods-header />
        <calendar />
    </div>
</template>

<script>
    import Calendar from "./components/calendar";
    import PeriodsHeader from "./components/PeriodsHeader";

    export default {
        components: {
            Calendar,
            PeriodsHeader
        },
        data() {
            return {};
        }
    };
</script>

<style lang="scss">
    .periods__container {
        .fld__dropdown-button {
            padding: 4px 10px !important;
            border: 0 !important;
        }
    }
</style>

<style lang="scss" scoped>
    .periods__container {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 16px;
        gap: 16px;
    }
</style>
