<template>
    <div class="calendar__container">
        <div class="calendar__header">
            <div class="calendar__days-control-block">
                <div class="calendar__days-control-area">
                    <pagination-arrow
                        v-if="periods.pagination.startIndex > 0"
                        class="calendar__arrow-left-container"
                        @click="handlePreviouPage"
                        direction="left"
                    />

                    <pagination-arrow
                        v-if="periods.pagination.endIndex < Object.entries(periodList).length - 1"
                        class="calendar__arrow-right-container"
                        @click="handleNextPage"
                        direction="right"
                    />
                </div>
            </div>

            <div class="calendar__days">
                <div
                    class="day-column__day-block"
                    v-for="([day, periodList], i) in handlePaginationList()"
                    :key="`day-h-${i}`"
                >
                    <span class="day-column__label">
                        {{ $t("CalendarsPage.Day") }}
                    </span>
                    <span class="day-column__day">{{ day }}</span>
                </div>
            </div>
        </div>

        <div class="calendar__days-content">
            <div class="calendar__hours-column">
                <hour-block
                    v-for="(hour, i) in hourList"
                    :label="hour"
                    :key="`hour-${i}`"
                />
            </div>

            <div class="calendar__days-columns">
                <day-column
                    v-for="([day, periodList], i) in handlePaginationList()"
                    :key="`day-${i}`"
                    :day="Number(day)"
                    :period-list="periodList"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import HourBlock from "./HourBlock.vue";
    import DayColumn from "./dayColumn";
    import PaginationArrow from "./PaginationArrow.vue";
    import calendarRefs from "@/views/pages/calendar/enums/calendarRefs.js";
    import { calculateMinutes } from "../../utils";
    import { mapMutations, mapState } from "vuex";

    export default {
        components: {
            HourBlock,
            DayColumn,
            PaginationArrow
        },
        computed: {
            ...mapState("calendar", ["context", "periods", "calendarSettings"])
        },
        data() {
            return {
                calendarRefs: calendarRefs,
                hourList: [
                    "00:00",
                    "01:00",
                    "02:00",
                    "03:00",
                    "04:00",
                    "05:00",
                    "06:00",
                    "07:00",
                    "08:00",
                    "09:00",
                    "10:00",
                    "11:00",
                    "12:00",
                    "13:00",
                    "14:00",
                    "15:00",
                    "16:00",
                    "17:00",
                    "18:00",
                    "19:00",
                    "20:00",
                    "21:00",
                    "22:00",
                    "23:00"
                ],
                periodList: {}
            };
        },
        methods: {
            ...mapMutations("calendar", [
                "UPDATE_PERIODS_PIXEL_BASE_PER_MINUTE",
                "UPDATE_PERIODS_PAGINATION",
                "UPDATE_PERIODS_IS_COMPLETED_PERIODS"
            ]),
            handlePaginationList() {
                const { startIndex, endIndex } = this.periods.pagination;

                return Object.entries(this.periodList).filter(
                    (_, i) => i >= startIndex && i <= endIndex
                );
            },
            handleNextPage() {
                const lastIndex = Object.entries(this.periodList).length - 1;
                const oldStartIndex = this.periods.pagination.startIndex;
                const oldEndIndex = this.periods.pagination.endIndex;

                if (oldEndIndex === lastIndex) return;

                const sumBetweenOldEndIndexAndVisualizationPeriod =
                    oldEndIndex + this.periods.visualization;

                const endIndex =
                    sumBetweenOldEndIndexAndVisualizationPeriod > lastIndex
                        ? lastIndex
                        : sumBetweenOldEndIndexAndVisualizationPeriod;

                const startIndex = oldStartIndex + (endIndex - oldEndIndex);

                this.UPDATE_PERIODS_PAGINATION({
                    ...this.periods.pagination,
                    startIndex,
                    endIndex
                });
            },
            handlePreviouPage() {
                const oldStartIndex = this.periods.pagination.startIndex;
                const oldEndIndex = this.periods.pagination.endIndex;

                if (oldStartIndex === 0) return;

                const differenceBetweenOldStartIndexAndVisualization =
                    oldStartIndex - this.periods.visualization;

                const startIndex =
                    differenceBetweenOldStartIndexAndVisualization < 0
                        ? 0
                        : differenceBetweenOldStartIndexAndVisualization;

                const endIndex = oldEndIndex - (oldStartIndex - startIndex);

                this.UPDATE_PERIODS_PAGINATION({
                    ...this.periods.pagination,
                    startIndex,
                    endIndex
                });
            },
            handleCreatePeriodsObject(periodList) {
                const localStatusMap = this.context.status.reduce((acc, status) => {
                    acc[status.id] = status;
                    return acc;
                }, {});

                const duration = Number(this.calendarSettings.generalApplied.duration) || 1;
                const periodsObject = {};

                for (let i = 1; i <= duration; i++) {
                    periodsObject[i] = [];

                    for (const period of periodList) {
                        if (period.day !== i) continue;

                        const { day, ...restOfPeriod } = period;
                        const status = localStatusMap[period.statusId];

                        periodsObject[i].push({
                            ...restOfPeriod,
                            day,
                            type: "period",
                            efficiency: restOfPeriod.efficiency
                                ? `${restOfPeriod.efficiency}%`
                                : "0%",
                            startTime: restOfPeriod.startTime,
                            endTime: restOfPeriod.endTime,
                            statusName: status?.name ?? "",
                            statusColor: status?.color ?? "#000"
                        });
                    }
                }

                return periodsObject;
            },
            handleCreatePeriodList(periodList) {
                // Função para adicionar intervalo inicial
                const addInitialInterval = (day, periodsCopy, currentPeriod) => {
                    if (currentPeriod.startTime !== "00:00") {
                        periodsCopy.unshift({
                            day,
                            type: "interval",
                            startTime: "00:00",
                            endTime: currentPeriod.startTime
                        });
                    }
                };

                // Função para adicionar intervalo entre períodos
                const addIntervalBetweenPeriods = (day, periodsCopy, currentPeriod, nextPeriod) => {
                    if (
                        nextPeriod &&
                        currentPeriod.endTime !== nextPeriod.startTime &&
                        currentPeriod.type === "period"
                    ) {
                        periodsCopy.push({
                            day,
                            type: "interval",
                            endTime: nextPeriod.startTime,
                            startTime: currentPeriod.endTime
                        });
                    }
                };

                // Função para adicionar intervalo final
                const addFinalInterval = (day, periodsCopy, currentPeriod) => {
                    if (currentPeriod.endTime !== "23:59") {
                        periodsCopy.push({
                            day,
                            type: "interval",
                            endTime: "23:59",
                            startTime: currentPeriod.endTime
                        });
                    }
                };

                const localPeriodList = [...periodList].sort(this.handleSortPeriodList);

                const periodsObject = this.handleCreatePeriodsObject(localPeriodList);
                const periodsObjectCopy = { ...periodsObject };
                let smallestRangeInMinutes = 24;

                // Processamento dos períodos por dia
                for (let day in periodsObject) {
                    periodsObjectCopy[day] = [];

                    periodsObject[day].forEach((currentPeriod, i) => {
                        // Adicionar intervalo inicial
                        if (i === 0) {
                            addInitialInterval(day, periodsObjectCopy[day], currentPeriod);
                        }

                        // Adicionar o período atual
                        periodsObjectCopy[day].push(currentPeriod);

                        // Adicionar intervalo entre períodos
                        const nextPeriod = periodsObject[day]?.[i + 1] ?? null;
                        addIntervalBetweenPeriods(
                            day,
                            periodsObjectCopy[day],
                            currentPeriod,
                            nextPeriod
                        );

                        // Adicionar intervalo final

                        if (periodsObject[day].length - 1 === i) {
                            addFinalInterval(day, periodsObjectCopy[day], currentPeriod);
                        }
                    });

                    periodsObjectCopy[day].forEach((period) => {
                        const periodMinutes = calculateMinutes(period.startTime, period.endTime);

                        if (periodMinutes < smallestRangeInMinutes) {
                            smallestRangeInMinutes = periodMinutes;
                        }
                    });
                }

                this.handleInitialBasePixelPerMinute(smallestRangeInMinutes);
                this.handleResetPagination();

                this.periodList = { ...periodsObjectCopy };
            },
            handleSortPeriodList(a, b) {
                // Caso os dias sejam iguais, compara o startTime
                if (a.day !== b.day) {
                    return a.day - b.day;
                }

                const [aHours, aMinutes] = a.startTime.split(":").map(Number);
                const [bHours, bMinutes] = b.startTime.split(":").map(Number);

                const aTotalMinutes = aHours * 60 + aMinutes;
                const bTotalMinutes = bHours * 60 + bMinutes;

                return aTotalMinutes - bTotalMinutes;
            },
            handleInitialBasePixelPerMinute(minimumCurrentPeriodInMinutes) {
                if (minimumCurrentPeriodInMinutes < calendarRefs.minimumPeriod) {
                    const timeDivision = calendarRefs.hour / minimumCurrentPeriodInMinutes;

                    const pixelTotal = calendarRefs.minimumPeriodHeight * timeDivision;
                    const pixelPerMin = pixelTotal / calendarRefs.hour;

                    this.UPDATE_PERIODS_PIXEL_BASE_PER_MINUTE(pixelPerMin);
                    return;
                }

                this.UPDATE_PERIODS_PIXEL_BASE_PER_MINUTE(calendarRefs.initialPixelBasePerMinute);
            },
            timeToMinutes(time) {
                const [hours, minutes] = time.split(":").map(Number);
                return hours * 60 + minutes;
            },
            handleResetPagination() {
                this.UPDATE_PERIODS_PAGINATION({
                    startIndex: 0,
                    endIndex: this.periods.visualization - 1
                });
            }
        },
        watch: {
            "periods.periodList": {
                handler(newValue) {
                    this.handleCreatePeriodList(newValue);
                },
                deep: true, // Tornando o watcher profundo,
                immediate: true
            }
        }
    };
</script>

<style lang="scss" scoped>
    .calendar__container {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        height: 100%;
        // overflow-x: hidden;

        &::-webkit-scrollbar {
            display: none; /* Esconde a barra de rolagem */
        }

        .calendar__header {
            flex: 1;
            display: flex;
            position: sticky;
            top: 0;
            z-index: 2;

            .calendar__days-control-block {
                position: sticky;
                left: 0;
                background: #fff;
                justify-content: space-between;
                min-width: 85px;
                max-width: 85px;
                min-height: 50px;
                max-height: 50px;
                border: 1px solid #e0e0e0;
                display: flex;
                align-items: center;
                justify-content: center;

                .calendar__days-control-area {
                    display: flex;
                    gap: 4px;
                    min-width: 60px;
                    max-width: 60px;

                    .calendar__arrow-right-container {
                        margin-left: auto;
                    }
                }
            }

            .calendar__days {
                flex: 1;
                display: flex;
                .day-column__day-block {
                    flex: 1;
                    background: #fff;
                    user-select: none;
                    padding: 4px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #e0e0e0;
                    border-left: 0;
                    min-height: 50px;
                    max-height: 50px;

                    .day-column__label {
                        color: #4c4541;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 16px;
                    }

                    .day-column__day {
                        color: #4c4541;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 26px;
                    }
                }
            }
        }

        .calendar__days-content {
            display: flex;
            height: fit-content;
            flex: 1;
            z-index: 1;

            .calendar__hours-column {
                position: sticky;
                left: 0;
                z-index: 4;
            }
            .calendar__days-columns {
                display: flex;
                height: fit-content;
                flex: 1;
            }

            &::-webkit-scrollbar {
                display: none; /* Esconde a barra de rolagem */
            }
        }
    }

    @media (max-width: 1022px) {
        .day-column__day-block {
            min-width: 84px;
        }
    }
</style>
