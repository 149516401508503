<template>
    <div class="d-flex">
        <b-sidebar
            id="sidebar-modal-periods"
            class="sidebar-modal-periods"
            right
            bg-variant="white"
            v-model="isOpen"
            :aria-hidden="false"
            @hidden="closeModal"
        >
            <template #header>
                <div class="header-sidebar d-flex justify-content-between">
                    <span class="sidebar-title">
                        {{
                            calendarSettings.modalPeriods.periodId === null
                                ? $t("CalendarsPage.NewPeriod")
                                : $t("CalendarsPage.EditPeriod")
                        }}
                    </span>

                    <span
                        class="close-icon"
                        @click="closeModal()"
                    >
                        <CloseIcon />
                    </span>
                </div>
                <div class="separator"></div>
            </template>

            <template>
                <div class="sidebar-modal-periods__fields">
                    <div class="sidebar-modal-periods__fields-row">
                        <div class="sidebar-modal-periods__day-field day-select">
                            <sys-form-select
                                :label="$t('CalendarsPage.Day')"
                                :placeholder="$t('Select')"
                                :value="calendarSettings.modalPeriods.fields.day"
                                :options="context.days"
                                :state="calendarSettings.modalPeriods.states.day.valid"
                                :feedback="$t(calendarSettings.modalPeriods.states.day.feedback)"
                                @input="(value) => UPDATE_MODAL_PERIODS_DAY_FIELD(value)"
                                translate
                            />
                        </div>
                    </div>
                    <div class="sidebar-modal-periods__fields-row">
                        <div class="sidebar-modal-periods__status-field">
                            <sys-form-select
                                :label="$t('ProductionList.Status')"
                                :placeholder="$t('Select')"
                                :value="calendarSettings.modalPeriods.fields.status"
                                :options="context.status"
                                :state="calendarSettings.modalPeriods.states.status.valid"
                                :feedback="$t(calendarSettings.modalPeriods.states.status.feedback)"
                                @input="(value) => handleSelectStatus(value)"
                                translate
                            />
                        </div>

                        <div class="sidebar-modal-periods__efficiency-field">
                            <sys-form-input
                                :label="$t('CalendarsPage.Efficiency')"
                                :value="calendarSettings.modalPeriods.fields.efficiency"
                                :placeholder="$t('TypeHere')"
                                :state="calendarSettings.modalPeriods.states.efficiency.valid"
                                :feedback="
                                    $t(calendarSettings.modalPeriods.states.efficiency.feedback)
                                "
                                @input="(e) => handleInputEfficiency(e.target.value)"
                                @blur="handleBlurEfficiency()"
                                @keypress="(e) => handleKeyPressEfficiency(e)"
                                :disabled="calendarSettings.modalPeriods.fields.status === null"
                            />
                        </div>
                    </div>

                    <div class="sidebar-modal-periods__fields-row">
                        <div class="sidebar-modal-periods__hour-fields">
                            <div class="sidebar-modal-periods__starts-field">
                                <sys-form-datepicker
                                    id="from-hour"
                                    type="hour"
                                    :label="$t('CalendarsPage.From')"
                                    :value="calendarSettings.modalPeriods.fields.startTime"
                                    :state="calendarSettings.modalPeriods.states.startTime.valid"
                                    :feedback="
                                        $t(calendarSettings.modalPeriods.states.startTime.feedback)
                                    "
                                    @input="(value) => UPDATE_MODAL_PERIODS_START_TIME_FIELD(value)"
                                />
                            </div>

                            <div class="sidebar-modal-periods__ends-field">
                                <sys-form-datepicker
                                    id="to-hour"
                                    type="hour"
                                    :label="$t('CalendarsPage.To')"
                                    :value="calendarSettings.modalPeriods.fields.endTime"
                                    :state="calendarSettings.modalPeriods.states.endTime.valid"
                                    :feedback="
                                        $t(calendarSettings.modalPeriods.states.endTime.feedback)
                                    "
                                    @input="handleOnInputEndTime"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="sidebar-modal-periods__footer-container">
                    <div
                        :class="[
                            'sidebar-modal-periods__footer-content-right',
                            calendarSettings.modalPeriods.periodId !== null ? '--is-edit' : ''
                        ]"
                    >
                        <div class="sidebar-modal-periods__footer-delete">
                            <sys-button
                                v-if="calendarSettings.modalPeriods.periodId !== null"
                                scheme="square"
                                variant="outline square-danger"
                                :size="handleFooterButtonSize()"
                                :full="windowWidth <= 480"
                                @click="handleDeletePeriod"
                            >
                                {{ $t("IntegrationPage.delete") }}
                            </sys-button>
                        </div>
                        <div class="sidebar-modal-periods__footer-buttons">
                            <sys-button
                                scheme="square"
                                variant="outline"
                                :size="handleFooterButtonSize()"
                                :full="windowWidth <= 480"
                                @click="handleCancelPeriod"
                            >
                                {{ $t("CalendarsPage.Cancel") }}
                            </sys-button>

                            <sys-button
                                scheme="square"
                                variant="contained"
                                :size="handleFooterButtonSize()"
                                :full="windowWidth <= 480"
                                @click="handleSavePeriod"
                            >
                                {{ $t("CalendarsPage.Save") }}
                            </sys-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import { BSidebar } from "bootstrap-vue";
    import SysButton from "@/@core/components/sys-button";
    import SysFormInput from "@/@core/components/sys-form-input";
    import SysFormDatepicker from "@/@core/components/sys-form-datepicker";
    import SysFormSelect from "@/@core/components/sys-form-select";
    import { timeToMinutes } from "../../utils";
    import { calendarInitialState } from "@/store/calendar";
    import { checkCompletedPeriods } from "@/views/pages/calendar/actionCalendar/utils";
    import { saveCalendarService } from "@/views/pages/calendar/actionCalendar/services";

    export default {
        data() {
            return {
                isOpen: false
            };
        },
        components: {
            BSidebar,
            SysButton,
            SysFormInput,
            SysFormSelect,
            SysFormDatepicker,
            AddIcon: () => import("@core/assets/icons/add-icon.svg"),
            CalendarIcon: () => import("@/assets/images/icons/calendar-icon.svg"),
            ArrowDownIcon: () => import("@core/assets/icons/arrow-down.svg"),
            SaveIcon: () => import("@core/assets/icons/save-icon.svg"),
            CloseIcon: () => import("@core/assets/icons/close-icon.svg")
        },
        // Função para converter o formato de hora "HH:MM" para minutos desde a meia-noite
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_PERIODS_TOGGLE",
                "UPDATE_MODAL_PERIODS_DAY_FIELD",
                "UPDATE_MODAL_PERIODS_STATUS_FIELD",
                "UPDATE_MODAL_PERIODS_EFFICIENCY_FIELD",
                "UPDATE_MODAL_PERIODS_START_TIME_FIELD",
                "UPDATE_MODAL_PERIODS_END_TIME_FIELD",
                "RESET_MODAL_PERIODS",
                "UPDATE_MODAL_PERIODS_STATES",
                "UPDATE_PERIODS_PERIOD_LIST",
                "UPDATE_PERIODS_IS_COMPLETED_PERIODS"
            ]),

            // Função para validar se uma hora está dentro do intervalo
            isTimesInRanges(startTime, endTime, day, list) {
                const validateInRanges = (time, rangeStart, rangeEnd) => {
                    if (time > rangeStart && time < rangeEnd) {
                        return true;
                    }

                    return false;
                };
                const startTimeInMinutes = timeToMinutes(startTime);
                const endTimeInMinutes = timeToMinutes(endTime);

                for (const range of list) {
                    const rangeStartTimeInMinutes = timeToMinutes(range.startTime);
                    const rangeEndTimeInMinutes = timeToMinutes(range.endTime);

                    if (day != range.day) continue;

                    if (
                        validateInRanges(
                            startTimeInMinutes,
                            rangeStartTimeInMinutes,
                            rangeEndTimeInMinutes
                        )
                    ) {
                        return true;
                    }

                    if (
                        validateInRanges(
                            endTimeInMinutes,
                            rangeStartTimeInMinutes,
                            rangeEndTimeInMinutes
                        )
                    ) {
                        return true;
                    }

                    if (
                        startTimeInMinutes < rangeStartTimeInMinutes &&
                        endTimeInMinutes > rangeEndTimeInMinutes
                    ) {
                        return true;
                    }
                }

                return false; // A hora não está dentro de nenhum intervalo
            },
            closeModal() {
                this.UPDATE_MODAL_PERIODS_TOGGLE(false);
                this.RESET_MODAL_PERIODS();
            },
            formatEfficiency(value) {
                const formattedValue =
                    value === "." ? "" : value.replace(/[^0-9.]/g, "").replace(/^0(\d)/, "$1");

                return formattedValue;
            },
            handleBlurEfficiency() {
                let formattedValue = this.calendarSettings.modalPeriods.fields.efficiency.replace(
                    /[^0-9.]/g,
                    ""
                );

                if (formattedValue[formattedValue.length - 1] == ".") {
                    formattedValue = formattedValue.replace(".", "");
                }

                this.UPDATE_MODAL_PERIODS_EFFICIENCY_FIELD(
                    formattedValue ? formattedValue + "%" : ""
                );
            },
            handleInputEfficiency(value) {
                const formattedValue = this.formatEfficiency(value);
                this.UPDATE_MODAL_PERIODS_EFFICIENCY_FIELD(formattedValue);
            },
            handleKeyPressEfficiency(event) {
                const key = event.key;

                if (!/[0-9.]/.test(key)) {
                    event.preventDefault();
                }

                if (
                    key === "." &&
                    this.calendarSettings.modalPeriods.fields.efficiency.includes(".")
                ) {
                    event.preventDefault();
                }
            },
            handleSelectStatus(value) {
                const efficiencySelected = this.context.status.find(
                    (e) => e.id === value
                )?.efficiency;

                this.UPDATE_MODAL_PERIODS_EFFICIENCY_FIELD(String(efficiencySelected) + "%");
                this.UPDATE_MODAL_PERIODS_STATUS_FIELD(value);
            },
            handleFooterButtonSize() {
                if (this.windowWidth > 768) {
                    return "md";
                }

                if (this.windowWidth > 480 && this.windowWidth <= 768) {
                    return "md";
                }

                if (this.windowWidth <= 480) {
                    return "xs";
                }
            },
            adjustTimeZone3(date) {
                const offset = date.getTimezoneOffset() * 60000;
                const localISOTime = new Date(date.getTime() - offset).toISOString().slice(0, -1);

                return localISOTime;
            },
            handleSavePeriod() {
                const handleEndTime = (endTime) => {
                    if (endTime === "00:00") {
                        return "23:59";
                    }

                    return !!endTime
                        ? this.adjustTimeZone3(endTime)?.split("T")?.[1]?.substring(0, 5)
                        : null;
                };

                const handleCreate = (periodListCopy, newPeriod) => {
                    periodListCopy.push(newPeriod);
                    this.UPDATE_PERIODS_PERIOD_LIST([...periodListCopy]);

                    const isCompletedPeriods = checkCompletedPeriods(
                        periodListCopy,
                        Number(this.calendarSettings.generalApplied.duration)
                    );

                    this.UPDATE_PERIODS_IS_COMPLETED_PERIODS(isCompletedPeriods);
                    this.closeModal();

                    saveCalendarService(this);
                };

                const handleEdit = async (periodListCopy, newPeriod) => {
                    const periodIndex = periodListCopy.findIndex((i) => i.id == newPeriod.id);

                    if (periodIndex === -1) return;

                    periodListCopy[periodIndex] = { ...newPeriod };

                    this.UPDATE_PERIODS_PERIOD_LIST([...periodListCopy]);

                    const isCompletedPeriods = checkCompletedPeriods(
                        periodListCopy,
                        Number(this.calendarSettings.generalApplied.duration)
                    );

                    this.UPDATE_PERIODS_IS_COMPLETED_PERIODS(isCompletedPeriods);
                    this.closeModal();

                    saveCalendarService(this);
                };

                const resultValidation = this.validateFields();

                if (!resultValidation) return;

                const fields = { ...this.calendarSettings.modalPeriods.fields };
                const periodList = [...this.periods.periodList];

                const newPeriod = {
                    id:
                        this.calendarSettings.modalPeriods.periodId ??
                        crypto.getRandomValues(new Uint8Array(4)).join(""),
                    day: fields.day,
                    statusId: fields.status,
                    efficiency: fields.status ? Number(fields.efficiency.replace(/%/g, "")) : 0,
                    startTime: !!fields.startTime
                        ? this.adjustTimeZone3(fields.startTime)?.split("T")?.[1]?.substring(0, 5)
                        : null,
                    endTime: handleEndTime(fields.endTime)
                };

                if (this.calendarSettings.modalPeriods.periodId === null) {
                    handleCreate(periodList, newPeriod);
                    return;
                }

                handleEdit(periodList, newPeriod);
            },
            handleOnInputEndTime(value) {
                const formattedEndTime = value
                    ? this.adjustTimeZone3(value)?.split("T")?.[1]?.substring(0, 5)
                    : null;

                if (!value) return;

                let lastHour = new Date();
                lastHour.setHours(23, 59, 0, 0);

                const endTimeInMinutes = value ? timeToMinutes(formattedEndTime) : null;

                this.UPDATE_MODAL_PERIODS_END_TIME_FIELD(endTimeInMinutes == 0 ? lastHour : value);
            },
            validateFields() {
                const vm = this;
                const modalPeriods = { ...this.calendarSettings.modalPeriods };
                const fields = { ...modalPeriods.fields };
                const states = { ...calendarInitialState().calendarSettings.modalPeriods };
                const formattedStartTime = fields.startTime
                    ? this.adjustTimeZone3(fields.startTime)?.split("T")?.[1]?.substring(0, 5)
                    : null;
                const formattedEndTime = fields.endTime
                    ? this.adjustTimeZone3(fields.endTime)?.split("T")?.[1]?.substring(0, 5)
                    : null;

                const startTimeInMinutes = fields.startTime
                    ? timeToMinutes(formattedStartTime)
                    : null;
                const endTimeInMinutes = fields.endTime ? timeToMinutes(formattedEndTime) : null;

                const validations = {
                    day: () => {
                        if (!fields.day?.toString()) {
                            states.day = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.day = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    status: () => {
                        if (!fields.status?.toString()) {
                            states.status = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.status = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    efficiency: () => {
                        if (!fields.efficiency) {
                            states.efficiency = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.efficiency = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    startTime: () => {
                        if (!fields.startTime) {
                            states.startTime = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.startTime = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    endTime: () => {
                        if (!fields.endTime) {
                            states.endTime = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        if (fields.startTime && fields.endTime) {
                            if (endTimeInMinutes <= startTimeInMinutes) {
                                states.endTime = {
                                    valid: false,
                                    feedback: "CalendarsPage.InvalidTime"
                                };

                                return false;
                            }
                        }

                        states.endTime = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    },
                    startTime_endTime() {
                        if (
                            fields.startTime &&
                            fields.endTime &&
                            states.startTime.valid &&
                            states.endTime.valid
                        ) {
                            const endTimeInvalid = vm.isTimesInRanges(
                                formattedStartTime,
                                formattedEndTime,
                                fields.day,
                                vm.periods.periodList
                            );

                            if (endTimeInvalid) {
                                states.startTime = {
                                    valid: false,
                                    feedback: "CalendarsPage.ConflictBetweenPeriods"
                                };
                                states.endTime = {
                                    valid: false,
                                    feedback: ""
                                };

                                return false;
                            }

                            states.startTime = {
                                valid: true,
                                feedback: ""
                            };

                            states.endTime = {
                                valid: true,
                                feedback: ""
                            };

                            return true;
                        }

                        return true;
                    }
                };

                let isValid = true;

                for (const action of Object.values(validations)) {
                    const resultValidation = action?.();

                    if (isValid) {
                        isValid = resultValidation;
                    }
                }

                this.UPDATE_MODAL_PERIODS_STATES({ ...states });

                return isValid;
            },
            handleDeletePeriod() {
                const periodId = this.calendarSettings.modalPeriods.periodId;

                const periodListCopy = [...this.periods.periodList];
                const periodIndex = periodListCopy.findIndex((period) => period.id == periodId);

                if (periodIndex === -1) return;

                periodListCopy.splice(periodIndex, 1);

                this.UPDATE_PERIODS_PERIOD_LIST([...periodListCopy]);

                this.closeModal();
            },
            handleCancelPeriod() {
                this.closeModal();
            }
        },
        computed: {
            ...mapState("calendar", [
                "isModalPeriodsOpen",
                "calendarSettings",
                "context",
                "periods"
            ]),
            ...mapState("app", ["windowWidth"])
        },
        watch: {
            isModalPeriodsOpen(v) {
                this.isOpen = v;
            }
        }
    };
</script>

<style lang="scss">
    .sidebar-modal-periods {
        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                width: 100%;
                padding: 16px;

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;

                    @media (max-width: 480px) {
                        font-size: 16px;
                    }
                }

                .close-icon {
                    border: 1px solid #974900;
                    border-radius: 5px;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    svg {
                        width: 12px;
                        height: 12px;
                        path {
                            fill: #974900;
                        }
                    }

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }
            }
        }

        .b-sidebar {
            width: 407px;
            box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
            transition: all 0.5s ease-out;
            height: 100% !important;
            .b-sidebar-body {
                padding: 0;

                &:has(.--no-events) {
                    overflow-y: hidden;
                }

                .col-md-12 {
                    padding: 0;
                }

                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
        }

        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;

            .btn-apply {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                box-shadow: none;
                width: 100%;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.175px;
                padding: 8px 0;
                border: 1px solid #974900 !important;
                background-color: #974900 !important;
                color: #ffff !important;

                svg {
                    width: 14px;
                    height: 14px;
                    fill: #fff;
                }

                &:hover {
                    box-shadow: none;
                }

                @media (max-width: 480px) {
                    font-size: 12px;
                    padding: 3px 0;
                }
            }

            .footer-periods {
                display: flex;
                justify-content: space-between;
                gap: 8px;
                width: 100%;

                .year-block {
                    display: flex;
                    align-items: center;
                    padding: 4px 10px;
                    border-radius: 5px;
                    border: 1px solid #974900;

                    .years-dropdown {
                        padding: 0 8px 0 6px;
                    }

                    .arrow-icon,
                    .calendar-icon {
                        width: 14px;
                        height: 14px;
                        fill: #974900;
                    }

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }

                    @media (max-width: 480px) {
                        font-size: 12px;
                        padding: 0 10px;

                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                .btn-new-event {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.175px;
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    padding: 8px 14px;

                    svg {
                        width: 14px;
                        height: 14px;
                        fill: #fff;
                    }

                    &:hover {
                        box-shadow: none;
                    }

                    @media (max-width: 480px) {
                        font-size: 12px;
                        padding: 3px 14px;

                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                .dropdown-toggle {
                    padding: 0;
                    color: #974900;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.175px;
                }

                .custom-dropdown-menu {
                    max-height: 200px;
                    overflow-y: auto;

                    .selected-year {
                        background-color: #974900;

                        .dropdown-item {
                            color: #fff;
                        }

                        .dropdown-item:hover,
                        .dropdown-item:focus {
                            color: #fff;
                        }

                        .dropdown-item:active {
                            background: #974900;
                        }
                    }

                    .dropdown-item:hover,
                    .dropdown-item:focus {
                        color: #974900;
                    }

                    .dropdown-item:active {
                        background: #ffdbc4;
                    }
                }
            }

            &.--no-events {
                border: none;
                padding: 0;

                .footer-periods {
                    display: none;
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }

        .sidebar-modal-periods__fields {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px;

            .sidebar-modal-periods__fields-row {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                width: 100%;
            }

            .sidebar-modal-periods__hour-fields {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                width: 100%;
            }

            .sidebar-modal-periods__day-field {
                max-width: 100%;
                width: 100%;
            }

            .sidebar-modal-periods__status-field,
            .sidebar-modal-periods__efficiency-field {
                min-width: 179.5px;
                max-width: 100%;
            }

            .sidebar-modal-periods__all-day-field {
                margin-top: 39px;
                display: flex;
            }
        }

        .sidebar-modal-periods__footer-container {
            border-top: 1px solid #cfc4be;
            padding: 15.5px 16px;
            display: flex;
            .sidebar-modal-periods__footer-content-right {
                margin-left: auto;
                display: flex;
                gap: 8px;

                .sidebar-modal-periods__footer-buttons {
                    display: flex;
                    gap: 8px;
                }

                &.--is-edit {
                    justify-content: space-between;
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .b-sidebar {
            width: 100% !important;
        }

        .sidebar-modal-periods__fields {
            .sidebar-modal-periods__status-field,
            .sidebar-modal-periods__efficiency-field {
                width: 100%;
                min-width: initial !important;
                max-width: initial !important;
            }

            .sidebar-modal-periods__starts-field,
            .sidebar-modal-periods__ends-field {
                width: 100%;
                min-width: initial !important;
                max-width: initial !important;
            }

            .sidebar-modal-periods__all-day-field {
                margin-top: 0 !important;
            }
        }

        .sidebar-modal-periods__footer-container {
            border-top: 1px solid #cfc4be;
            padding: 15.5px 16px;
            display: flex;
            .sidebar-modal-periods__footer-content-right {
                width: 100%;
                margin-left: initial !important;
                display: flex;
                gap: 8px;

                &.--is-edit .sidebar-modal-periods__footer-delete {
                    width: 33%;
                    .sidebar-modal-periods__footer-buttons {
                        width: 66%;
                        justify-content: flex-end;
                    }
                }
                .sidebar-modal-periods__footer-buttons {
                    width: 100%;
                }
            }
        }
    }
</style>
