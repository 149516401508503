var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.calendarSettings.modalEvents.eventId)?_c('div',{staticClass:"link-resource-body"},[_c('div',{staticClass:"text-block"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("CalendarsPage.ResourceSelection")))]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("CalendarsPage.SubTitleLinkModalEvent"))+" ")])]),_c('div',{staticClass:"search-block"},[_c('div',{staticClass:"search-input"},[_c('div',{staticClass:"div-custom-input input__calendar_resources"},[_c('div',{staticClass:"icon-block"},[_c('SearchIcon',{staticClass:"search-icon"})],1),(_vm.forceRenderSearchInput)?_c('b-form-tags',{ref:"formTagStructures",staticClass:"input-search-resources-calendar",attrs:{"duplicate-tag-text":"","placeholder":_vm.filter.textFilter.length === 0 ? _vm.$t('SearchPlaceholderDefault') : '',"remove-on-delete":"","add-on-change":""},on:{"input":function (e) { return _vm.updateFilter(e); }},model:{value:(_vm.filter.textFilter),callback:function ($$v) {_vm.$set(_vm.filter, "textFilter", $$v)},expression:"filter.textFilter"}}):_vm._e()],1)])]),_c('div',{staticClass:"action-block"},[_c('div',{staticClass:"select-all"},[_c('div',{class:['checkbox-container-resource', _vm.allResourceChecked ? 'checked' : ''],on:{"click":function($event){return _vm.checkAllResource()}}},[_c('span',{staticClass:"icon-checkbox"},[_c('CheckIcon')],1)]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("SelectAll"))+" ")])])]),(_vm.forceRenderLinkResource)?_c('div',{staticClass:"resource-block"},[(_vm.filteredList.length === 0)?_c('div',{staticClass:"block-no-results"},[_c('div',{staticClass:"no-results"},[_c('SearchIcon'),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("NoResultsFound")))])],1)]):_c('div',_vm._l((_vm.filteredList),function(resource){return _c('div',{key:resource.id},[_c('div',{staticClass:"user-info-block"},[_c('div',{staticClass:"check-resource"},[_c('div',{class:[
                                'checkbox-container-resource __no-action-default',
                                resource.checked ? 'checked' : ''
                            ],on:{"click":function($event){return _vm.toggleCheckbox(resource)}}},[_c('span',{staticClass:"icon-checkbox __no-action-default"},[_c('CheckIcon')],1)])]),_c('div',{staticClass:"text-block"},[_c('div',{staticClass:"resource-name"},[_vm._v(" "+_vm._s(resource.name)+" ")]),_c('div',{staticClass:"resource-code"},[_vm._v(_vm._s(resource.code))])])])])}),0)]):_vm._e()]):_c('div',{staticClass:"linked-resources"},[_c('div',{staticClass:"h-100"},[_c('div',[_c('div',{staticClass:"select-all"},[_c('div',{class:[
                        'checkbox-container-resources',
                        _vm.allResourceChecked ? 'checked' : ''
                    ],on:{"click":function($event){return _vm.checkAllResource()}}},[_c('span',{staticClass:"icon-checkbox"},[_c('CheckIcon')],1)]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("WorkcenterList.Resources"))+" ")])]),_c('div',{staticClass:"action-block"},[(!_vm.filteredList.find(function (e) { return e.checked; }))?_c('div',{staticClass:"link-resource",on:{"click":function($event){return _vm.openLinkModal()}}},[_c('LinkIcon',{staticClass:"link-icon"}),_c('span',[_vm._v(_vm._s(_vm.$t("StructurePage.LinkResource")))])],1):_c('div',{staticClass:"unlink-resource",on:{"click":function($event){return _vm.onUnlinkResource()}}},[_c('UnLinkIcon',{staticClass:"unlink-icon"}),_c('span',[_vm._v(_vm._s(_vm.$t("StructurePage.UnlinkResource")))])],1),_c('div',{staticClass:"function-filter"},[_c('span',[_vm._v(_vm._s(_vm.$t("StructurePage.Type")))]),_c('div',{staticClass:"filter",attrs:{"id":"filter-select-resource","tabindex":"0"}},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.selectedFilter)))]),_c('ArrowDown',{staticClass:"arrow-down"})],1)]),_c('Popover',{attrs:{"items":_vm.typeList,"target":"filter-select-resource","placement":"bottom","custom-position":("action-button " + (_vm.isModalResourceInfoOpen ? '--double-modal-openned' : '')),"triggers":"focus"}})],1)]),(_vm.forceRenderResource)?_c('div',{class:['resource-list', _vm.filteredList.length >= 9 ? 'enable-scroll' : '']},[(_vm.filteredList.length === 0)?_c('div',{staticClass:"block-no-results"},[_c('div',{staticClass:"no-results"},[_c('SearchIcon'),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("NoResultsFound")))])],1)]):_c('div',_vm._l((_vm.filteredList),function(resource){return _c('div',{key:resource.id,class:[
                        'resource-row',
                        _vm.resourceInfo.id === resource.id && _vm.isModalResourceInfoOpen
                            ? '--is-viewing'
                            : ''
                    ],on:{"click":function($event){return _vm.openInfoResource(resource, $event)}}},[_c('div',{staticClass:"user-info-block"},[_c('div',{staticClass:"check-resource"},[_c('div',{class:[
                                    'checkbox-container-resources __no-action-default',
                                    resource.checked ? 'checked' : ''
                                ],on:{"click":function($event){return _vm.toggleResourceLinked(resource)}}},[_c('span',{staticClass:"icon-checkbox __no-action-default"},[_c('CheckIcon')],1)])]),_c('div',{staticClass:"text-block"},[_c('div',{staticClass:"resource-name"},[_vm._v(" "+_vm._s(resource.name)+" ")]),_c('div',{staticClass:"resource-code"},[_vm._v(_vm._s(resource.code))])])]),_c('div',{staticClass:"d-flex"},[(_vm.isModalResourceInfoOpen)?_c('div',{staticClass:"view-block"},[_c('EyeIcon'),_c('span',[_vm._v(_vm._s(_vm.$t("DepartmentPage.Viewing")))])],1):_vm._e(),(Array.isArray(resource.type))?_c('div',{staticClass:"roles-block"},[_c('div',{staticClass:"role-tag"},[_vm._v(" "+_vm._s(_vm.$t(resource.type[0]))+" ")]),(resource.type[1])?_c('div',{staticClass:"role-tag"},[_vm._v(" "+_vm._s(_vm.$t(resource.type[1]))+" ")]):_vm._e(),(resource.type.length > 2)?_c('div',{staticClass:"role-tag"},[_vm._v(" + "+_vm._s(resource.type.length - 2)+" ")]):_vm._e()]):_c('div',{staticClass:"roles-block"},[_c('div',{staticClass:"role-tag"},[_vm._v(" "+_vm._s(_vm.$t(resource.type))+" ")])]),_c('div',{staticClass:"unlink __no-action-default",on:{"click":function($event){return _vm.unLinkResource(resource)}}},[_c('UnLinkIcon')],1)])])}),0)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }