import calendarRefs from "@/views/pages/calendar/enums/calendarRefs";

export const calendarInitialState = () => ({
    helpers: {
        eventsFilter: {
            text: [],
            selectedYear: 2025
        },
        context: {
            years: []
        }
    },
    calendarSettings: {
        calendarId: null,
        general: {
            fields: {
                name: "Calendário Padrão",
                referenceDate: new Date(),
                duration: 7
            },
            states: {
                name: {
                    valid: true,
                    feedback: ""
                }
            }
        },
        generalApplied: {
            name: "Calendário Padrão",
            referenceDate: new Date(),
            duration: 7
        },
        events: {
            eventList: []
        },
        resources: {
            modal: {
                isModalLinkResourcesOpen: false,
                resourcesToLink: []
            },
            resourcesList: []
        },
        modalEvents: {
            eventId: null,
            origin: null,
            initialName: null,
            fields: {
                name: "",
                exceptionEventType: null,
                efficiency: "0%",
                date: new Date(),
                startTime: null,
                endTime: null,
                allDay: false,
                description: "",
                isRecurrent: false,
                recurrence: {
                    isOnThe: true,
                    isOn: false,
                    frequency: null,
                    quantity: "1",
                    daysOfWeek: [1],
                    daysOfMonth: [],
                    weeksOfMonth: [],
                    months: [],
                    startDate: new Date(),
                    endDate: null
                }
            },
            states: {
                name: {
                    valid: true,
                    feedback: ""
                },
                exceptionEventType: {
                    valid: true,
                    feedback: ""
                },
                efficiency: {
                    valid: true,
                    feedback: ""
                },
                date: {
                    valid: true,
                    feedback: ""
                },
                startTime: {
                    valid: true,
                    feedback: ""
                },
                endTime: {
                    valid: true,
                    feedback: ""
                },
                recurrence: {
                    startDate: {
                        valid: true,
                        feedback: ""
                    },
                    quantity: {
                        valid: true,
                        feedback: ""
                    },
                    frequency: {
                        valid: true,
                        feedback: ""
                    },
                    daysOfMonth: {
                        valid: true,
                        feedback: ""
                    }
                }
            }
        },
        modalPeriods: {
            periodId: null,
            fields: {
                day: 1,
                status: null,
                efficiency: "0%",
                startTime: null,
                endTime: null
            },
            states: {
                day: {
                    valid: true,
                    feedback: ""
                },
                status: {
                    valid: true,
                    feedback: ""
                },
                efficiency: {
                    valid: true,
                    feedback: ""
                },
                startTime: {
                    valid: true,
                    feedback: ""
                },
                endTime: {
                    valid: true,
                    feedback: ""
                }
            }
        }
    },
    periods: {
        isCompletedPeriods: false,
        pagination: {
            startIndex: 0,
            endIndex: 0
        },
        visualization: 7,
        pixelBasePerMinute: calendarRefs.initialPixelBasePerMinute,
        periodList: []
    },
    context: {
        resources: [],
        calendarEventTypes: [],
        status: [],
        durationUnits: [],
        days: Array.from({ length: 7 }, (_, i) => ({
            id: i + 1,
            text: String(i + 1)
        }))
    },
    permissions: [],
    isModalSettingsOpen: true,
    isModalEventsOpen: false,
    isModalPeriodsOpen: false,
    isModalResourceInfoOpen: false,
    isModalResourceOptions: false,
    bypassLeave: false
});

export default {
    namespaced: true,
    state: calendarInitialState(),
    mutations: {
        // general
        RESET_STATES(state) {
            Object.assign(state, calendarInitialState());
        },
        RESET_MODAL_GENERAL(state) {
            Object.assign(
                state.calendarSettings.general,
                calendarInitialState().calendarSettings.general
            );
        },
        RESET_MODAL_EVENTS(state) {
            Object.assign(
                state.calendarSettings.modalEvents,
                calendarInitialState().calendarSettings.modalEvents
            );
        },
        RESET_MODAL_PERIODS(state) {
            Object.assign(
                state.calendarSettings.modalPeriods,
                calendarInitialState().calendarSettings.modalPeriods
            );
        },
        RESET_RESOURCES(state) {
            Object.assign(
                state.calendarSettings.resources,
                calendarInitialState().calendarSettings.resources
            );
        },
        RESET_MODAL_RESOURCES(state) {
            Object.assign(
                state.calendarSettings.resources.modal,
                calendarInitialState().calendarSettings.resources.modal
            );
        },
        UPDATE_BYPASS_LEAVE(state, val) {
            state.bypassLeave = val;
        },
        UPDATE_GENERAL_APPLIED(state, val) {
            state.calendarSettings.generalApplied = val;
        },
        UPDATE_CALENDAR_ID(state, val) {
            state.calendarSettings.calendarId = val;
        },
        UPDATE_MODAL_GENERAL_FIELDS(state, val) {
            state.calendarSettings.general.fields = val;
        },
        UPDATE_MODAL_GENERAL_NAME_FIELD(state, val) {
            state.calendarSettings.general.fields.name = val;
        },
        UPDATE_MODAL_GENERAL_DATE_FIELD(state, val) {
            state.calendarSettings.general.fields.referenceDate = val;
        },
        UPDATE_MODAL_GENERAL_DURATION_FIELD(state, val) {
            state.calendarSettings.general.fields.duration = val;
        },
        UPDATE_MODAL_GENERAL_STATES(state, val) {
            state.calendarSettings.general.states = val;
        },
        UPDATE_EVENT_LIST(state, val) {
            state.calendarSettings.events.eventList = val;
        },
        UPDATE_RESOURCES_TO_LINK_LIST(state, val) {
            state.calendarSettings.resources.modal.resourcesToLink = val;
        },
        UPDATE_RESOURCES_LIST(state, val) {
            state.calendarSettings.resources.resourcesList = val;
        },
        UPDATE_MODAL_SETTINGS_TOGGLE(state, val) {
            state.isModalSettingsOpen = val;
        },

        // modal periods
        UPDATE_MODAL_PERIODS_TOGGLE(state, val) {
            state.isModalPeriodsOpen = val;
        },
        UPDATE_MODAL_PERIOD(state, val) {
            state.calendarSettings.modalPeriods = val;
        },
        UPDATE_MODAL_PERIODS_DAY_FIELD(state, val) {
            state.calendarSettings.modalPeriods.fields.day = val;
        },
        UPDATE_MODAL_PERIODS_STATUS_FIELD(state, val) {
            state.calendarSettings.modalPeriods.fields.status = val;
        },
        UPDATE_MODAL_PERIODS_EFFICIENCY_FIELD(state, val) {
            state.calendarSettings.modalPeriods.fields.efficiency = val;
        },
        UPDATE_MODAL_PERIODS_START_TIME_FIELD(state, val) {
            state.calendarSettings.modalPeriods.fields.startTime = val;
        },
        UPDATE_MODAL_PERIODS_END_TIME_FIELD(state, val) {
            state.calendarSettings.modalPeriods.fields.endTime = val;
        },
        UPDATE_MODAL_PERIODS_STATES(state, val) {
            state.calendarSettings.modalPeriods.states = val;
        },

        //modal events
        UPDATE_MODAL_EVENTS_TOGGLE(state, val) {
            state.isModalEventsOpen = val;
        },
        UPDATE_MODAL_EVENTS_NAME_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.name = val;
        },
        UPDATE_MODAL_EVENTS_EVENT_TYPE_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.exceptionEventType = val;
        },
        UPDATE_MODAL_EVENTS_EFFICIENCY_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.efficiency = val;
        },
        UPDATE_MODAL_EVENTS_DATE_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.date = val;
        },
        UPDATE_MODAL_EVENTS_START_TIME_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.startTime = val;
        },
        UPDATE_MODAL_EVENTS_END_TIME_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.endTime = val;
        },
        UPDATE_MODAL_EVENTS_ALL_DAY_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.allDay = val;
        },
        UPDATE_MODAL_EVENTS_DESCRIPTION_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.description = val;
        },
        UPDATE_MODAL_EVENTS_RECURRENCE_START_DATE_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence.startDate = val;
        },
        UPDATE_MODAL_EVENTS_RECURRENCE_END_DATE_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence.endDate = val;
        },
        UPDATE_MODAL_EVENTS_RECURRENCE_QUANTITY_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence.quantity = val;
        },
        UPDATE_MODAL_EVENTS_RECURRENCE_FREQUENCY_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence.frequency = val;
        },
        UPDATE_MODAL_EVENTS(state, val) {
            state.calendarSettings.modalEvents = val;
        },
        UPDATE_MODAL_EVENTS_RECURRENCE_DAYS_OF_WEEK_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence.daysOfWeek = val;
        },
        UPDATE_MODAL_EVENTS_RECURRENCE_DAYS_OF_MONTH_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence.daysOfMonth = val;
        },
        UPDATE_MODAL_EVENTS_RECURRENCE_WEEKS_OF_MONTH_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence.weeksOfMonth = val;
        },
        UPDATE_MODAL_EVENTS_RECURRENCE_MONTHS_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence.months = val;
        },
        UPDATE_MODAL_EVENTS_RECURRENCE_IS_ON(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence.isOn = val;
        },
        UPDATE_MODAL_EVENTS_RECURRENCE_IS_ON_THE(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence.isOnThe = val;
        },
        UPDATE_MODAL_EVENTS_IS_RECURRENT_FIELD(state, val) {
            state.calendarSettings.modalEvents.fields.isRecurrent = val;
        },
        UPDATE_MODAL_EVENT_RECURRENCE(state, val) {
            state.calendarSettings.modalEvents.fields.recurrence = val;
        },
        UPDATE_MODAL_EVENTS_STATES(state, val) {
            state.calendarSettings.modalEvents.states = val;
        },
        UPDATE_MODAL_RESOURCES_TOGGLE(state, val) {
            state.calendarSettings.resources.modal.isModalLinkResourcesOpen = val;
        },

        // context
        UPDATE_EXCEPTION_EVENT_TYPES_TOGGLE(state, val) {
            state.context.calendarEventTypes = val;
        },
        UPDATE_DURATION_UNITS_TOGGLE(state, val) {
            state.context.durationUnits = val;
        },
        UPDATE_STATUS_TOGGLE(state, val) {
            state.context.status = val;
        },
        UPDATE_CONTEXT_DAYS(state, val) {
            state.context.days = val;
        },
        UPDATE_CONTEXT_RESOURCES(state, val) {
            state.context.resources = val;
        },

        //periods
        UPDATE_PERIODS_PIXEL_BASE_PER_MINUTE(state, val) {
            state.periods.pixelBasePerMinute = val;
        },
        UPDATE_PERIODS_VISUALIZATION(state, val) {
            state.periods.visualization = val;
        },
        UPDATE_PERIODS_PAGINATION(state, val) {
            state.periods.pagination = val;
        },
        UPDATE_PERIODS_PERIOD_LIST(state, val) {
            state.periods.periodList = val;
        },
        UPDATE_PERIODS_IS_COMPLETED_PERIODS(state, val) {
            state.periods.isCompletedPeriods = val;
        },

        // permissions
        UPDATE_PERMISSIONS_LIST(state, val) {
            state.permissions = val;
        },

        // resources
        UPDATE_MODAL_RESOURCE_INFO_TOGGLE(state, val) {
            state.isModalResourceInfoOpen = val;
        },
        UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(state, val) {
            state.isModalResourceOptions = val;
        },

        //helpers
        UPDATE_HELPERS_CONTEXT_YEARS(state, val) {
            state.helpers.context.years = val;
        },
        UPDATE_HELPERS_EVENTS_FILTER_TEXT(state, val) {
            state.helpers.eventsFilter.text = val;
        },
        UPDATE_HELPERS_EVENTS_FILTER_SELECTED_YEAR(state, val) {
            state.helpers.eventsFilter.selectedYear = val;
        }
    },
    getters: {
        calendarId: (state) => {
            return state.calendarSettings.calendarId;
        },
        isModalLinkResourcesOpen: (state) => {
            return state.calendarSettings.resources.modal.isModalLinkResourcesOpen;
        },
        resourcesToLink: (state) => {
            return state.calendarSettings.resources.modal.resourcesToLink;
        }
    }
};
