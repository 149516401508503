<template>
    <div
        class="hour-block__container"
        :style="{
            'min-height': periods.pixelBasePerMinute * calendarRefs.hour + 'px',
            'max-height': periods.pixelBasePerMinute * calendarRefs.hour + 'px'
        }"
    >
        <span class="hour-block__label">
            {{ label }}
        </span>
    </div>
</template>

<script>
    import calendarRefs from "@/views/pages/calendar/enums/calendarRefs.js";
    import { mapState } from "vuex";

    export default {
        computed: {
            ...mapState("calendar", ["periods"])
        },
        props: {
            label: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                calendarRefs: calendarRefs
            };
        }
    };
</script>

<style lang="scss" scoped>
    .hour-block__container {
        background: #fff;
        user-select: none;
        display: flex;
        padding: 10px;
        min-height: 72px;
        max-height: 72px;
        min-width: 85px;
        max-width: 85px;
        align-items: flex-start;
        justify-content: center;
        border: 1px solid #e0e0e0;
        border-top: 0;

        .hour-block__label {
            color: #998f8a;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
        }
    }
</style>
