<template>
    <div id="general-settings">
        <b-form-group
            :label="$t('DepartmentPage.Name')"
            label-for="name"
            :invalid-feedback="$t(calendarSettings.general.states.name.feedback)"
        >
            <b-form-input
                id="name"
                type="text"
                :value="calendarSettings.general.fields.name"
                @input="(value) => UPDATE_MODAL_GENERAL_NAME_FIELD(value)"
                :state="
                    calendarSettings.general.states.name.valid
                        ? null
                        : calendarSettings.general.states.name.valid
                "
                :placeholder="$t('TypeHere')"
            />
        </b-form-group>

        <Datepicker
            :field="{
                id: 'reference-date-datepicker',
                label: $t('CalendarsPage.ReferenceDate2'),
                industrialStructParameterType: 'datetime',
                value: calendarSettings.general.fields.referenceDate
            }"
            @input="(value) => UPDATE_MODAL_GENERAL_DATE_FIELD(value)"
            class="reference-date"
        />
        <b-form-group
            :label="$t('CalendarsPage.Duration2')"
            label-for="duration"
            :invalid-feedback="$t('RequiredField')"
        >
            <div class="input-duration">
                <b-form-input
                    id="duration"
                    type="number"
                    :value="calendarSettings.general.fields.duration"
                    @input="(value) => UPDATE_MODAL_GENERAL_DURATION_FIELD(value)"
                    :placeholder="$t('TypeHere')"
                    @input.native="handleOnInputDuration"
                />
                <div class="days">
                    {{ $t("CalendarsPage.Days") }}
                </div>
            </div>
        </b-form-group>
    </div>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import { BFormGroup, BFormInput } from "bootstrap-vue";
    import Datepicker from "@core/components/customFields/index.vue";

    export default {
        props: {},
        components: { BFormGroup, BFormInput, Datepicker },
        mounted() {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_GENERAL_NAME_FIELD",
                "UPDATE_MODAL_GENERAL_DATE_FIELD",
                "UPDATE_MODAL_GENERAL_DURATION_FIELD"
            ]),
            handleOnInputDuration(event) {
                let value = event.target.value.replace(/[^0-9]/g, "");
                const min = 1;
                const max = 30;
                const formattedValueInNumber = Number(value);

                if (formattedValueInNumber < min) {
                    value = 1;
                }

                if (formattedValueInNumber > max) {
                    value = 30;
                }
                this.UPDATE_MODAL_GENERAL_DURATION_FIELD(Number(value));
                event.target.value = value;
            }
        },
        computed: {
            ...mapState("calendar", {
                calendarSettings: "calendarSettings"
            })
        }
    };
</script>

<style lang="scss">
    #general-settings {
        padding: 16px 16px 0px;
        overflow-y: auto;
        .form-group {
            .input-duration {
                display: flex;
                align-items: center;

                .days {
                    border-radius: 0px 5px 5px 0px;
                    padding: 8px 14px;
                    border: 1px solid #cfc4be;
                    border-left: unset;
                    background: #eee;
                }

                .form-control {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border: 1px solid #cfc4be;
                }
            }
            .form-control.is-invalid,
            .form-control.is-valid {
                background-image: none !important;
            }
            .form-control {
                &::placeholder {
                    color: #cfc4be;
                }
                &:focus {
                    border-color: #974900;
                }
            }
        }

        .reference-date {
            .datepicker {
                padding: 0 !important;
            }
        }
    }
</style>
