<template>
    <div class="sys-form-select__container">
        <label
            class="sys-form-select__label"
            v-if="!!label"
        >
            {{ label }}
        </label>

        <SingleSelect
            :placeholder="placeholder"
            :optionSelected="getSelectedOption(value, options)"
            :options="options"
            :searchable="true"
            :state="state"
            :isLocationSelect="location"
            :footerText="footerText"
            :showFooter="showFooter"
            :disabled="disabled"
            :translate="!translate"
            @input="(value) => $emit('input', value ? value.id : null)"
            @open="$emit('focus')"
            @close="$emit('blur')"
        />

        <p
            class="sys-form-select__feedback"
            v-if="state === false && !!feedback && !disabled"
        >
            {{ feedback }}
        </p>
    </div>
</template>

<script>
    import SingleSelect from "@/@core/components/multiselect/SingleSelect.vue";

    export default {
        components: { SingleSelect },
        props: {
            id: {
                type: String
            },
            label: {
                type: String,
                default: ""
            },
            options: {
                type: Array,
                default: []
            },
            value: {
                default: null
            },
            state: {
                type: Boolean,
                default: true
            },
            placeholder: {
                type: String,
                default: "Select an option"
            },
            searchable: {
                type: Boolean,
                default: false
            },
            location: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            footerText: {
                type: String,
                default: ""
            },
            showFooter: {
                type: Boolean,
                default: false
            },
            searchable: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            feedback: {
                type: String,
                default: ""
            },
            translate: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            getSelectedOption(value, options) {
                const option = options?.find((i) => i.id == value) ?? null;

                return option;
            }
        }
    };
</script>

<style lang="scss">
    .sys-form-select__container {
        width: 100%;
        display: flex;
        flex-direction: column;

        .single-select {
            display: flex;
        }

        .multiselect--disabled {
            background-color: #efefef !important;
            color: #7e7570 !important;

            .multiselect__tags {
                border: 1px solid #7e7570 !important;
            }
        }

        .multiselect-component-single {
            * {
                transition:
                    border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
            }

            .multiselect__tags {
                width: 100% !important;
                height: 100% !important;
                white-space: nowrap !important;
                display: flex !important;
                align-items: center !important;
                overflow-x: scroll;
                overflow-y: hidden;
                margin-top: 0;
                height: 100% !important;
                padding-top: 0 !important;
                cursor: grabbing;
                cursor: -webkit-grabbing;

                .multiselect__single {
                    margin: 0 !important;
                }

                .multiselect__placeholder {
                    padding-bottom: 0px !important;
                    color: #cfc4be;
                }

                .multiselect__tag {
                    background-color: #974900;
                    margin-right: 0.6rem;
                    font-size: 13px;

                    .multiselect__tag-icon {
                        line-height: 21px;

                        &:hover {
                            background-color: #974900;
                            text-decoration: none;
                            opacity: 0.75;

                            &:after {
                                color: #000;
                            }
                        }

                        &:after {
                            color: #fff;
                        }
                    }
                }
                &::-webkit-scrollbar {
                    display: none !important;
                }
            }

            .multiselect__select {
                height: 34px;
                z-index: 1;
            }
        }

        .multiselect__select,
        .multiselect-component-single,
        .multiselect__tags {
            min-height: 38px;
            max-height: 38px;
        }

        .sys-form-select__label {
            margin-bottom: 4px;
            color: #4c4541;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
        }

        .sys-form-select__feedback {
            margin: 1px 0 0 5px;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            color: #d32f2f;
        }
    }
</style>
