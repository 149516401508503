<template>
    <div>
        <b-sidebar
            id="calendar-resource-link-sidebar-right"
            class="calendar-link-resource"
            right
            bg-variant="white"
            width="407px"
            v-model="isOpen"
            @hidden="closeModal"
        >
            <template #header>
                <div
                    v-if="!isException"
                    class="header-sidebar d-flex justify-content-between"
                >
                    <span class="sidebar-title">
                        {{ $t(modalTitle) }}
                    </span>

                    <span
                        class="close-icon"
                        @click="closeModal()"
                    >
                        <CloseIcon />
                    </span>
                </div>
                <div
                    v-if="!isException"
                    class="separator"
                ></div>
            </template>
            <template>
                <div>
                    <div class="text-block">
                        <div
                            v-if="isException"
                            class="title"
                        >
                            {{ $t("StructurePage.LinkResource") }}
                        </div>
                        <div class="subtitle">
                            {{
                                $t(
                                    `CalendarsPage.${isException ? "SubTitleLinkModalEvent" : "SubTitleLinkModal"}`
                                )
                            }}
                        </div>
                    </div>
                    <div class="search-block">
                        <span class="text">{{ $t("WorkcenterList.Resources") }}</span>
                        <div class="search-input">
                            <div class="div-custom-input input__resource_calendar">
                                <div class="icon-block">
                                    <SearchIcon class="search-icon" />
                                </div>
                                <b-form-tags
                                    class="input-search-resources"
                                    ref="formTagResourcesCalendar"
                                    v-model="filter.textFilter"
                                    duplicate-tag-text=""
                                    :placeholder="
                                        filter.textFilter.length === 0
                                            ? $t('SearchPlaceholderDefault')
                                            : ''
                                    "
                                    @input="(e) => updateFilter(e)"
                                    remove-on-delete
                                    add-on-change
                                />
                            </div>
                        </div>
                    </div>
                    <div class="action-block">
                        <div class="select-all">
                            <div
                                :class="[
                                    'checkbox-container-resource',
                                    allResourcesChecked ? 'checked' : ''
                                ]"
                                @click="checkAllResource()"
                            >
                                <span class="icon-checkbox"><CheckIcon /></span>
                            </div>
                            <span class="text">
                                {{ $t("SelectAll") }}
                            </span>
                        </div>
                        <div
                            v-if="isException"
                            class="function-filter"
                        >
                            <span>{{ $t("StructurePage.Type") }}</span>
                            <div
                                id="filter-resource-select-link"
                                class="filter"
                                tabindex="0"
                            >
                                <span>{{ $t(selectedFilter) }}</span>
                                <ArrowDown class="arrow-down" />
                            </div>
                        </div>
                        <Popover
                            :items="typeList"
                            target="filter-resource-select-link"
                            placement="bottom"
                            custom-position="action-button"
                            triggers="focus"
                        />
                    </div>
                    <div
                        class="operation-block"
                        v-if="forceRenderLinkResource"
                    >
                        <div
                            v-if="filteredList.length === 0"
                            class="block-no-results"
                        >
                            <div class="no-results">
                                <SearchIcon />
                                <p class="text">{{ $t("NoResultsFound") }}</p>
                            </div>
                        </div>
                        <div v-else>
                            <div
                                v-for="resource in filteredList"
                                :key="resource.id"
                            >
                                <div class="user-info-block">
                                    <div class="check-resource">
                                        <div
                                            :class="[
                                                'checkbox-container-resource __no-action-default',
                                                resource.checked ? 'checked' : ''
                                            ]"
                                            @click="toggleCheckbox(resource)"
                                        >
                                            <span class="icon-checkbox __no-action-default"
                                                ><CheckIcon
                                            /></span>
                                        </div>
                                    </div>
                                    <div class="text-block">
                                        <div class="resource-name">
                                            {{ resource.name }}
                                        </div>
                                        <div class="resource-code">{{ resource.code }}</div>
                                    </div>
                                    <div
                                        v-if="Array.isArray(resource.type)"
                                        class="roles-block"
                                    >
                                        <div class="role-tag">
                                            {{ $t(resource.type[0]) }}
                                        </div>
                                        <div
                                            v-if="resource.type[1]"
                                            class="role-tag"
                                        >
                                            {{ $t(resource.type[1]) }}
                                        </div>
                                        <div
                                            v-if="resource.type.length > 2"
                                            class="role-tag"
                                        >
                                            + {{ resource.type.length - 2 }}
                                        </div>
                                    </div>
                                    <div
                                        v-else
                                        class="roles-block"
                                    >
                                        <div class="role-tag">
                                            {{ $t(resource.type) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="separator"></div>
                <b-col
                    md="12"
                    class="footer-sidebar"
                >
                    <div class="d-flex buttons">
                        <b-button
                            class="btn-cancel btn-footer btn"
                            @click="closeModal()"
                        >
                            {{ $t("RefuseStatus.Cancel") }}
                        </b-button>
                        <b-button
                            class="btn-save btn-footer btn"
                            @click="onLink()"
                        >
                            {{ $t("IntegrationPage.Link") }}
                        </b-button>
                    </div>
                </b-col>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { BSidebar, BCol, BButton, BFormInput, BFormTags } from "bootstrap-vue";
    import { mapMutations, mapGetters, mapState } from "vuex";
    import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
    import Popover from "@core/layouts/layout-horizontal/components/Menu/Popover.vue";
    import { saveCalendarService } from "@/views/pages/calendar/actionCalendar/services";

    export default {
        components: {
            BSidebar,
            BCol,
            BButton,
            BFormInput,
            BFormTags,
            Popover,
            ToastificationContent,
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            ArrowDown: () => import("@core/assets/icons/arrow-down-orange.svg"),
            CheckIcon: () => import("@core/assets/icons/check-icon.svg"),
            SearchIcon: () => import("@core/assets/icons/search-icon.svg")
        },
        props: {
            modalTitle: {
                type: String,
                default: ""
            },
            device: {
                type: Number,
                default: null
            },
            isException: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                forceRenderLinkResource: true,
                allResourcesChecked: false,
                isOpen: false,
                filter: {
                    textFilter: []
                },
                selectedFilter: "DepartmentPage.All",
                filteredList: [],
                typeList: [
                    {
                        title: "DepartmentPage.All",
                        click: () => {
                            this.selectedFilter = "DepartmentPage.All";
                        }
                    }
                ]
            };
        },
        mounted() {
            this.UPDATE_MODAL_RESOURCES_TOGGLE(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_RESOURCES_TOGGLE",
                "UPDATE_RESOURCES_TO_LINK_LIST",
                "UPDATE_RESOURCES_LIST",
                "RESET_MODAL_RESOURCES"
            ]),
            closeModal() {
                this.UPDATE_MODAL_RESOURCES_TOGGLE(false);
            },
            toggleCheckbox(elem) {
                const filteredListCopy = [...this.filteredList];

                filteredListCopy.forEach((e) => {
                    if (elem.id == e.id) {
                        e["checked"] = !e?.checked;
                    }
                });

                this.filteredList = filteredListCopy;
                this.verifyAllChecked();
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            },
            unCheckAllResource() {
                const filteredListCopy = [...this.filteredList];

                filteredListCopy.forEach((e) => {
                    e["checked"] = false;
                });

                this.filteredList = filteredListCopy;
                this.allResourcesChecked = false;
            },
            updateFilter(e) {
                this.isException && this.updateFilterByFunction(this.selectedFilter);
                this.filteredList = this.resourcesToLink;
                if (e.length === 0) return;

                const arr = this.filteredList;
                let filteredSet = new Set();

                e.forEach((filterValue) => {
                    const regex = new RegExp(filterValue.toLowerCase(), "i");
                    arr.forEach((item) => {
                        if (regex.test(item.name.toLowerCase())) {
                            filteredSet.add(item);
                        }
                    });
                });

                this.filteredList = Array.from(filteredSet);
            },
            checkAllResource() {
                const filteredListCopy = [...this.filteredList];

                if (this.allResourcesChecked) {
                    this.unCheckAllResource();
                    return;
                }

                filteredListCopy.forEach((e) => {
                    e["checked"] = true;
                });

                this.filteredList = filteredListCopy;
                this.verifyAllChecked();
            },
            verifyAllChecked() {
                this.allResourcesChecked = this.filteredList.every((e) => e.checked);
            },
            forceRenderLinkResourceList() {
                this.forceRenderLinkResource = false;
                this.$nextTick().then(() => {
                    this.forceRenderLinkResource = true;
                });
            },
            updateFilterByFunction(selectedType) {
                this.filteredList =
                    selectedType === "DepartmentPage.All"
                        ? this.resourcesToLink
                        : this.resourcesToLink.filter((resource) =>
                              Array.isArray(resource.type)
                                  ? resource.type.includes(selectedType)
                                  : resource.type === selectedType
                          );
            },
            getAllTypeFilter() {
                const getAllTypes = this.filteredList.flatMap((item) => item.type);
                const uniqueTypes = new Set(getAllTypes.flat());

                this.typeList = [
                    {
                        title: "DepartmentPage.All",
                        click: () => {
                            this.selectedFilter = "DepartmentPage.All";
                        }
                    },
                    ...Array.from(uniqueTypes).map((type) => ({
                        title: type,
                        click: () => {
                            this.selectedFilter = type;
                        }
                    }))
                ];
            },
            async getResourcesToLink() {
                const routeName = this.$route.name;

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                if (routeName === "newCalendar") {
                    await this.$http2
                        .get(`/api/myconfig/calendar/resources`)
                        .then((response) => {
                            this.UPDATE_RESOURCES_TO_LINK_LIST(
                                response?.data?.data?.filter(
                                    (item) =>
                                        !this.calendarSettings.resources.resourcesList.some(
                                            (resource) => resource.id == item.id
                                        )
                                )
                            );
                            this.filteredList = [...this.resourcesToLink];
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        })
                        .catch((error) => console.error(error));
                } else if (routeName === "editCalendar") {
                    await this.$http2
                        .get(
                            `/api/myconfig/calendar/${this.$router.currentRoute.params.id}/available-resources`
                        )
                        .then((response) => {
                            this.UPDATE_RESOURCES_TO_LINK_LIST(response.data.data);
                            this.filteredList = this.resourcesToLink;
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        })
                        .catch((error) => console.error(error));
                } else if (this.isException) {
                    this.UPDATE_RESOURCES_TO_LINK_LIST([
                        { id: 1, name: "teste", code: 1234, type: ["Máquina"] },
                        { id: 2, name: "teste 2", code: 1234, type: ["Teste"] },
                        { id: 3, name: "teste 3", code: 1234, type: ["Máquina"] },
                        { id: 4, name: "teste 4", code: 1234, type: ["Máquina"] },
                        { id: 5, name: "teste 5", code: 1234, type: ["Máquina"] },
                        { id: 6, name: "teste 6", code: 1234, type: ["Máquina"] },
                        { id: 7, name: "teste 7", code: 1234, type: ["Máquina"] },
                        { id: 8, name: "teste 8", code: 1234, type: ["Máquina"] },
                        { id: 9, name: "teste 9", code: 1234, type: ["Máquina"] },
                        { id: 10, name: "teste 10", code: 1234, type: ["Máquina"] },
                        { id: 11, name: "teste 11", code: 1234, type: ["Máquina"] }
                    ]);
                    this.updateFilterByFunction(this.selectedFilter);
                    this.getAllTypeFilter();
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            onLink() {
                const hasChecked = this.filteredList.find((e) => e.checked);
                if (!hasChecked) return;
                const checkedResources = this.resourcesToLink.filter((e) => !!e.checked);

                this.UPDATE_RESOURCES_LIST(checkedResources);

                this.$setToast({
                    title: this.$t("Success"),
                    icon: "SmileIcon",
                    text: this.$t("CalendarsPage.LinkedResources"),
                    variant: "success"
                });

                this.closeModal();

                saveCalendarService(this);
            }
        },
        computed: {
            ...mapState("calendar", ["calendarSettings"]),
            ...mapGetters("calendar", ["isModalLinkResourcesOpen", "resourcesToLink", "calendarId"])
        },
        watch: {
            isModalLinkResourcesOpen(v) {
                this.isOpen = v;
                this.unCheckAllResource();
                if (v) {
                    this.getResourcesToLink();
                } else {
                    this.RESET_MODAL_RESOURCES();
                    this.filter.textFilter = [];
                }
            },
            selectedFilter(v) {
                this.filter.textFilter = [];
                this.unCheckAllResource();
                this.updateFilterByFunction(v);
            }
        }
    };
</script>
<style lang="scss">
    .calendar-link-resource {
        z-index: calc(1030 + 6);
        .b-sidebar.b-sidebar-right {
            transition: all 0.5s ease-out;
            border-left: 1px solid #cfc4be;
            @media (max-width: 814px) {
                width: 50vw !important;
            }
            @media (max-width: 480px) {
                width: 100vw !important;
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }

        .b-sidebar {
            height: 100% !important;

            .b-sidebar-header {
                display: contents;

                .header-sidebar {
                    width: 100%;
                    padding: 16px;

                    .sidebar-title {
                        display: flex;
                        align-items: center;
                        color: #4c4541;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                    }

                    .close-icon {
                        border: 1px solid #974900;
                        border-radius: 5px;
                        width: 28px;
                        height: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        svg {
                            width: 12px;
                            height: 12px;
                            path {
                                fill: #974900;
                            }
                        }

                        &:hover {
                            background-color: #ffede2 !important;
                        }

                        &:active {
                            background-color: #ffdbc4 !important;
                        }
                    }
                }

                .separator {
                    width: 100%;
                    height: 1px;
                    background: #cfc4be;
                }
            }

            .b-sidebar-body {
                padding: 16px;
                overflow: hidden;

                .text-block {
                    .title {
                        color: #4c4541;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                        padding-bottom: 8px;
                    }

                    .subtitle {
                        color: #998f8a;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        padding-bottom: 16px;
                    }
                }

                .search-block {
                    .text {
                        color: #4c4541;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;

                        @media (max-width: 480px) {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                    .search-input {
                        display: flex;
                        align-items: center;
                        padding: 8px 0;

                        .div-custom-input {
                            display: flex;
                            align-items: center;
                            border-radius: 4px;
                            width: 100%;
                            overflow: hidden;
                            border: 1px solid #cfc4be;
                            .icon-block {
                                display: flex;
                                align-items: center;
                                padding-left: 14px;
                                height: 14px;
                                .search-icon {
                                    width: 14px;
                                    height: 14px;
                                    fill: #cfc4be;
                                }
                            }
                            .input-search-resources {
                                width: 100% !important;
                                border: none;
                                overflow: hidden;

                                &.focus {
                                    box-shadow: none;
                                }
                                &.b-form-tags {
                                    padding: 0 !important;
                                    display: flex;
                                    align-items: center !important;
                                    .b-form-tags-list {
                                        width: 100% !important;
                                        margin-left: 14px !important;
                                        margin-top: 0;
                                        height: 100% !important;
                                        flex-wrap: nowrap !important;
                                        display: flex !important;
                                        align-items: center !important;
                                        overflow: scroll;
                                        overflow-y: hidden;
                                        cursor: grabbing;
                                        cursor: -webkit-grabbing;

                                        &::-webkit-scrollbar {
                                            display: none !important;
                                        }
                                        .b-form-tags-field {
                                            margin: 9px 0;
                                        }
                                    }
                                }

                                .b-form-tags-button {
                                    display: none;
                                }

                                .b-form-tag {
                                    background-color: #974900;
                                    margin-right: 0.6rem;
                                    padding: 2px 8px !important;
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                    .label-title {
                        color: #4c4541;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                        padding-bottom: 8px;
                    }
                }

                .action-block {
                    display: flex;
                    justify-content: space-between;
                    padding: 8px 0;

                    .select-all {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 0 16px;

                        .text {
                            color: #4c4541;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                        }
                    }

                    .function-filter .filter {
                        color: #974900;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.15px;
                    }

                    .function-filter {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        color: #4c4541;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;

                        .filter {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            padding: 4px 10px;
                            border-radius: 5px;
                            cursor: pointer;

                            .arrow-down {
                                width: 12px;
                                height: 12px;
                            }

                            &:hover {
                                background: #ffede2;
                            }

                            &:active {
                                background: #ffdbc4;
                            }
                        }
                    }

                    @media (max-width: 480px) {
                        flex-wrap: wrap;
                    }
                }

                .operation-block {
                    padding-bottom: 16px;
                    overflow: auto;
                    height: calc(100vh - 340px);

                    &:has(.block-no-results) {
                        overflow-y: hidden;
                    }

                    .block-no-results {
                        height: 100%;
                        padding: 16px 0;
                        .no-results {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 16px;
                            border-radius: 6px;
                            border: 1px solid #cfc4be;

                            svg {
                                width: 16px;
                                height: 16px;
                                fill: #998f8a;
                            }

                            .text {
                                margin: 0;
                                color: #998f8a;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 26px;
                            }

                            @media (max-width: 480px) {
                                svg {
                                    width: 12px;
                                    height: 12px;
                                }
                                .text {
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }
                        }
                    }

                    .user-info-block {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 7.5px 16px;
                        overflow: hidden;
                        border-bottom: 1px solid #eee;
                        background: #fff;

                        .picture img {
                            width: 40px;
                            border-radius: 50%;

                            @media (max-width: 480px) {
                                width: 30px;
                            }
                        }

                        .check-resource {
                            display: flex;

                            .checkbox-container-resource {
                                margin: 0;
                            }
                        }

                        .text-block {
                            overflow: hidden;
                            .resource-name {
                                color: #4c4541;
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 20px;
                                white-space: nowrap;
                                word-break: break-all;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .resource-code {
                                color: #998f8a;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 16px;
                            }
                        }
                    }

                    .roles-block {
                        display: flex;
                        justify-content: flex-end;
                        gap: 8px;
                        padding-right: 16px;
                        margin-left: auto;
                        .role-tag {
                            white-space: nowrap;
                            border-radius: 4px;
                            background: #eceff1;
                            padding-inline: 9px;
                            color: #607d8b;
                            text-align: center;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 16px;
                        }
                    }

                    &::-webkit-scrollbar {
                        width: 7px;
                    }

                    &::-webkit-scrollbar-track {
                        background: #fff;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #cfc4be;
                        border-radius: 9px;
                    }
                }

                .checkbox-container-resource {
                    display: flex;
                    background-color: #fff;
                    border: 1px solid #cfc4be;
                    border-radius: 3px;
                    cursor: pointer;
                    width: 18px;
                    height: 18px;

                    .icon-checkbox {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 2px;
                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                    &.checked {
                        background-color: #974900;
                        display: block;
                    }
                }

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    background: #fff;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #cfc4be;
                    border-radius: 9px;
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }

        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 16px;

            .buttons,
            .edit-buttons {
                gap: 8px;
            }

            @media (max-width: 480px) {
                justify-content: center;
                .buttons {
                    width: 100%;

                    .btn {
                        width: 50%;
                    }
                }

                .btn-footer {
                    font-size: 12px !important;
                    padding: 3px 21px !important;
                }
            }

            .btn-footer {
                box-shadow: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                border-radius: 5px;

                &:hover {
                    box-shadow: none;
                }

                &.btn-cancel {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-previous,
                &.btn-cancel.btn-cancel-outline {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-save {
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    color: #ffff !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #a45f1f !important;
                    }
                }
            }
        }
    }
</style>
