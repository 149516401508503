<template>
    <div :class="['sys-form-datepicker__container', { '--has-icon': type !== 'range' }]">
        <label
            class="sys-form-datepicker__label"
            v-if="label"
        >
            {{ label }}
        </label>

        <DateTimePicker
            :datepicker-id="id"
            :value="value"
            position="bottom"
            :range="type === 'range'"
            :type="type === 'range' ? 'date' : type"
            :disabled="disabled"
            :weekDays="setWeekDays()"
            :month-names="setMonths()"
            :txt-cancel-button="$t('Datepicker.Cancel')"
            :txt-save-button="$t('Datepicker.Save')"
            :txt-time="$t('Datepicker.Time')"
            :txt-date="$t('Datepicker.Date')"
            @input="(value) => $emit('input', value)"
        >
            <template v-slot:input>
                <div class="sys-form-datepicker__datepicker">
                    <sys-form-input
                        :value="handleFormatInputDatepicker(value)"
                        :disabled="disabled"
                        :placeholder="handlePlaceholder(type)"
                        :feedback="feedback"
                        :state="state"
                        @blur="(event) => $emit('blur', event)"
                        @focus="(event) => $emit('focus', event)"
                        readonly
                    >
                        <template v-slot:prepend>
                            <TimeIcon
                                class="sys-form-datepicker__icon"
                                v-if="type === 'hour'"
                            />

                            <CalendarIcon
                                class="sys-form-datepicker__icon"
                                v-if="type === 'date' || type === 'datetime'"
                            />
                        </template>
                    </sys-form-input>
                </div>
            </template>
        </DateTimePicker>
    </div>
</template>

<script>
    import SysFormInput from "@/@core/components/sys-form-input";
    import DateTimePicker from "components-code/date-time-picker/DatePickerFull.vue";
    import moment from "moment";

    export default {
        components: {
            SysFormInput,
            DateTimePicker,
            CalendarIcon: () => import("@core/assets/icons/calendar-icon.svg"),
            TimeIcon: () => import("@core/assets/icons/time-icon.svg")
        },
        props: {
            id: {
                type: String,
                default: ""
            },
            type: {
                default: "datepicker"
            },
            feedback: {
                type: String,
                default: ""
            },
            state: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            value: {
                value: null
            },
            label: {
                type: String,
                default: ""
            }
        },
        methods: {
            handleFormatInputDatepicker(value) {
                if (!value) return "";

                try {
                    if (this.type === "range") {
                        return `${moment(value[0]).format("DD/MM/YYYY")} ${this.$t("Operator.Until")} ${moment(
                            value[1]
                        ).format("DD/MM/YYYY")}`;
                    }

                    if (this.type === "date") {
                        return moment(value).format("DD/MM/YYYY");
                    }

                    if (this.type === "hour") {
                        return moment(value).format("HH:mm");
                    }

                    if (this.type === "datetime") {
                        return moment(value).format("DD/MM/YYYY HH:mm");
                    }
                } catch {
                    console.error("Erro na conversão de data!");
                }
            },
            handlePlaceholder(type) {
                if (type === "range") {
                    return `00/00/0000 ${$t("Operator.Until")} 00/00/0000`;
                }

                if (type === "date") {
                    return "00/00/0000";
                }

                if (type === "hour") {
                    return "00:00";
                }

                if (type === "datetime") {
                    return "00/00/0000 00:00";
                }

                return "";
            },
            setWeekDays() {
                const weekdays = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday"
                ];

                return weekdays.map((day) => ({
                    name: this.$t(`Datepicker.Weekdays.Full.${day}`),
                    short: this.$t(`Datepicker.Weekdays.Short.${day}`)
                }));
            },
            setMonths() {
                const months = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ];

                return months.map((month) => ({
                    name: this.$t(`Datepicker.Months.Full.${month}`),
                    short: this.$t(`Datepicker.Months.Short.${month}`)
                }));
            }
        }
    };
</script>

<style lang="scss">
    .sys-form-datepicker__container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .sys-form-datepicker__label {
            color: #4c4541;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 4px;
        }

        &.--has-icon {
            .sys-form-datepicker__datepicker {
                position: relative;

                .sys-form-datepicker__icon {
                    position: absolute;
                    left: 14px;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;

                    svg {
                        fill: #cfc4be;
                    }
                }
                .sys-form-input__input {
                    padding: 8px 13px 8px 42px;
                }
            }
        }
    }
</style>
