<template>
    <div id="collapse-month">
        <div
            v-for="event in month.events"
            :key="event.id"
            class="event-block"
        >
            <div class="day-block">
                <div class="week-day">
                    {{ $t(`CalendarsPage.WeekDays.${getDayOfWeek(event.date)}`) }}
                </div>
                <div class="day">{{ getDayOfMonth(event.date) }}</div>
            </div>
            <div class="event-info">
                <div class="event-title">{{ event.name }}</div>
                <div class="event-description">
                    <EventIcon class="event-icon" /><span class="text">
                        {{ getEventType(event) }}
                    </span>
                </div>
                <div class="event-duration">
                    <DurationIcon class="duration-icon" />
                    <span class="text">
                        {{ getDurationEvent(event) }}
                    </span>
                </div>
            </div>
            <div class="control-block">
                <OptionIcon
                    class="option-svg"
                    @click="getEvent(event)"
                    :id="'event-' + event.id"
                    tabindex="0"
                />
                <RecurrentIcon
                    v-if="event.isRecurrent"
                    class="recurrent-icon"
                />
                <Popover
                    :items="handleOptionList(event.isRecurrent)"
                    :target="'event-' + event.id"
                    placement="bottomright"
                    custom-position="--max-content"
                    triggers="focus"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import DurationIcon from "@core/assets/icons/duration-icon.svg";
    import EventIcon from "@core/assets/icons/event-icon.svg";
    import OptionIcon from "@core/assets/icons/option-icon.svg";
    import Popover from "@core/layouts/layout-horizontal/components/Menu/Popover.vue";
    import EditIcon from "@core/assets/icons/edit-icon.svg";
    import DeleteIcon from "@core/assets/icons/delete-icon.svg";
    import RecurrentIcon from "@core/assets/icons/recurrent-icon.svg";
    import moment from "moment";
    import frequency from "@/views/pages/calendar/enums/frequency";
    import { saveCalendarService } from "@/views/pages/calendar/actionCalendar/services";
    import exceptionEventTypes from "@/views/pages/calendar/enums/exceptionEventTypes";

    export default {
        props: {
            month: {
                type: Object,
                default: null
            }
        },
        components: {
            DurationIcon,
            EventIcon,
            OptionIcon,
            Popover,
            EditIcon,
            DeleteIcon,
            RecurrentIcon
        },
        computed: {
            ...mapState("calendar", ["calendarSettings", "context"])
        },
        data() {
            return {
                currentEvent: {},
                frequency: frequency,
                optionList: [
                    {
                        id: 1,
                        title: "ResourcePage.Edit",
                        icon: EditIcon,
                        click: () => {
                            const localEvent = { ...this.currentEvent };

                            const formattedEvent = {
                                eventId: localEvent?.id,
                                origin: localEvent?.origin ?? "back",
                                initialName: localEvent?.name ?? null,
                                fields: {
                                    name: localEvent?.name ?? "",
                                    exceptionEventType: localEvent?.calendarEventTypeId ?? null,
                                    efficiency: localEvent?.efficiency?.toString()
                                        ? localEvent?.efficiency?.toString() + "%"
                                        : "0%",
                                    date: localEvent?.date ? new Date(localEvent.date) : new Date(),
                                    startTime: localEvent?.startTime
                                        ? new Date(
                                              moment().format("YYYY-MM-DD") +
                                                  "T" +
                                                  localEvent?.startTime
                                          )
                                        : null,
                                    endTime: localEvent?.endTime
                                        ? new Date(
                                              moment().format("YYYY-MM-DD") +
                                                  "T" +
                                                  localEvent?.endTime
                                          )
                                        : null,
                                    allDay: !!localEvent?.allDay,
                                    description: localEvent?.description ?? "",
                                    isRecurrent: !!localEvent?.isRecurrent,
                                    recurrence: localEvent.recurrence
                                        ? {
                                              isOnThe:
                                                  this.frequency.day ==
                                                      localEvent.recurrence?.frequencyId ||
                                                  this.frequency.week ==
                                                      localEvent.recurrence?.frequencyId ||
                                                  !!localEvent.recurrence?.daysOfMonth?.length,
                                              isOn:
                                                  (this.frequency.month ==
                                                      localEvent.recurrence?.frequencyId ||
                                                      this.frequency.year ==
                                                          localEvent.recurrence?.frequencyId) &&
                                                  !localEvent.recurrence?.daysOfMonth?.length,
                                              frequency: localEvent.recurrence?.frequencyId ?? null,
                                              quantity:
                                                  localEvent.recurrence?.quantity?.toString() ?? "",
                                              daysOfWeek: localEvent.recurrence?.daysOfWeek ?? [1],
                                              daysOfMonth: localEvent.recurrence?.daysOfMonth
                                                  ?.length
                                                  ? localEvent.recurrence?.daysOfMonth?.map(String)
                                                  : [],
                                              weeksOfMonth:
                                                  localEvent.recurrence?.weeksOfMonth ?? [],
                                              months: localEvent.recurrence?.months ?? [],
                                              startDate: localEvent.recurrence?.startDate
                                                  ? new Date(localEvent.recurrence.startDate)
                                                  : null,
                                              endDate: localEvent.recurrence?.endDate
                                                  ? new Date(localEvent.recurrence.endDate)
                                                  : null
                                          }
                                        : //default
                                          {
                                              isOnThe: true,
                                              isOn: false,
                                              frequency: null,
                                              quantity: "1",
                                              daysOfWeek: [1],
                                              daysOfMonth: [],
                                              weeksOfMonth: [],
                                              months: [],
                                              startDate: new Date(),
                                              endDate: null
                                          }
                                }
                            };

                            this.UPDATE_MODAL_EVENTS({
                                ...this.calendarSettings.modalEvents,
                                ...formattedEvent
                            });

                            this.UPDATE_MODAL_EVENTS_TOGGLE(true);
                        }
                    },
                    {
                        id: 2,
                        title: "CalendarsPage.DeleteEvent",
                        icon: DeleteIcon,
                        click: () => {
                            this.deleteEvent(this.currentEvent?.id);
                        }
                    },
                    {
                        id: 3,
                        title: "CalendarsPage.DeleteRecurrence",
                        icon: DeleteIcon,
                        click: () => {
                            this.deleteRecurrenceEvent(this.currentEvent);
                        }
                    }
                ]
            };
        },
        mounted() {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_EVENTS",
                "UPDATE_MODAL_EVENTS_TOGGLE",
                "UPDATE_EVENT_LIST"
            ]),
            getDayOfWeek(dateString) {
                const date = new Date(dateString);
                const daysOfWeek = [
                    "sunday",
                    "monday",
                    "tuesday",
                    "wednesday",
                    "thursday",
                    "friday",
                    "saturday"
                ];

                return daysOfWeek[date.getDay()];
            },
            getDay(dateString) {
                const date = new Date(dateString);
                return date.getDay();
            },
            getDayOfMonth(dateString) {
                const date = new Date(dateString);
                return date.getDate();
            },
            getEventType(event) {
                const eventType = this.context.calendarEventTypes.find(
                    (e) => e.id == event.calendarEventTypeId
                );

                if (
                    event.calendarEventTypeId != exceptionEventTypes.overtime &&
                    event.calendarEventTypeId != exceptionEventTypes.onShift
                ) {
                    return eventType?.name;
                }

                if (!event.efficiency) {
                    return eventType?.name;
                }

                return `${eventType?.name} ${event.efficiency}%`;
            },
            getDurationEvent(event) {
                return event.allDay
                    ? this.$t("CalendarsPage.AllDay")
                    : `${event.startTime.slice(0, 5)}-${event.endTime.slice(0, 5)}`;
            },
            getEvent(event) {
                this.currentEvent = event;
            },
            handleOptionList(isRecurrent) {
                if (isRecurrent) return this.optionList;

                return this.optionList.filter((option) => option.id != 3);
            },
            deleteEvent(eventId) {
                this.$swal({
                    title: this.$t("IntegrationPage.attention"),
                    html: `<div>${this.$t("CalendarsPage.DeleteEventMessage1")} <b>${this.currentEvent.name}</b>. ${this.$t("CalendarsPage.DeleteEventMessage2")}</div>`,
                    showCancelButton: true,
                    showCloseButton: true,
                    cancelButtonText: this.$t("IntegrationPage.cancel"),
                    confirmButtonText: this.$t("IntegrationPage.delete"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.value) {
                        const eventList = [...this.calendarSettings.events.eventList];

                        const eventIndex = eventList.findIndex((event) => event.id == event.id);

                        if (eventIndex == -1) return;

                        eventList.splice(eventIndex, 1);
                        this.UPDATE_EVENT_LIST([...eventList]);

                        this.$setToast({
                            title: this.$t("Success"),
                            icon: "SmileIcon",
                            text: this.$t("CalendarsPage.EventDeleted"),
                            variant: "success"
                        });

                        saveCalendarService(this);
                    }
                });
            },
            deleteRecurrenceEvent(event) {
                const dateToCheck = new Date(event.date);
                const currentDate = new Date();
                const hasDatePassed = dateToCheck < currentDate;

                this.$swal({
                    title: this.$t("IntegrationPage.attention"),
                    html: `<div>${this.$t("CalendarsPage.DeleteRecurrenceMessage1")} <b>${event.name}</b> ${hasDatePassed ? this.$t("CalendarsPage.DeleteRecurrenceMessage2") : this.$t("CalendarsPage.DeleteRecurrenceMessage3")}</div>`,
                    showCancelButton: true,
                    showCloseButton: true,
                    cancelButtonText: this.$t("IntegrationPage.cancel"),
                    confirmButtonText: this.$t("CalendarsPage.DeleteRecurrence"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.value) {
                        const eventList = [...this.calendarSettings.events.eventList];

                        const eventIndex = eventList.findIndex((event) => event.id == event.id);

                        if (eventIndex == -1) return;

                        eventList[eventIndex].recurrence = null;
                        eventList[eventIndex].isRecurrent = false;
                        this.UPDATE_EVENT_LIST([...eventList]);

                        this.$setToast({
                            title: this.$t("Success"),
                            icon: "SmileIcon",
                            text: this.$t("CalendarsPage.RecurrenceDeleted"),
                            variant: "success"
                        });

                        saveCalendarService(this);
                    }
                });
            }
        }
    };
</script>

<style lang="scss">
    #collapse-month {
        .event-block {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            margin-top: 16px;
            border-radius: 6px;
            border: 1px solid #e0e0e0;
            background: #fff;
            padding: 16px;
        }

        .day-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding-right: 16px;
            border-right: 1px solid #e0e0e0;
            max-width: 68px;
            width: 100%;

            .week-day {
                color: #998f8a;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
            }

            .day {
                color: #4c4541;
                font-size: 22px;
                font-weight: 600;
                line-height: 30px;
            }
        }

        .event-info {
            width: 100%;
            min-width: 100px;

            .event-title {
                overflow: hidden;
                color: #4c4541;
                text-overflow: ellipsis;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                padding-bottom: 8px;
            }

            .event-description {
                padding-bottom: 4px;
            }

            .event-description,
            .event-duration {
                display: flex;
                align-items: center;
                gap: 8px;

                .text {
                    color: #998f8a;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .event-icon,
                .duration-icon {
                    width: 12px;
                    height: 12px;
                    overflow: initial;
                }
            }
        }

        .control-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            width: 100%;
            max-width: 28px;

            .option-svg {
                fill: #974900;
                width: 28px;
                height: 28px;
                cursor: pointer;

                &:hover {
                    background-color: #ffede2;
                    border-radius: 5px;
                }

                &:active {
                    background-color: #ffdbc4;
                    border-radius: 5px;
                }

                &:focus-within,
                &:focus {
                    background-color: #ffdbc4;
                    border-radius: 5px;
                    border: none;
                    outline: none;
                }
            }

            .recurrent-icon {
                width: 12px;
                height: 12px;
            }
        }
    }
</style>
