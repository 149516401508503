<template>
    <div class="d-flex">
        <b-sidebar
            id="settings-sidebar-right"
            :class="[
                'sidebar-modal-settings',
                calendarSettings.resources.modal.isModalLinkResourcesOpen && device > 480
                    ? '--role-openned'
                    : '--role-closed'
            ]"
            right
            bg-variant="white"
            :width="device > 480 ? '60vw' : '100vw'"
            v-model="isOpen"
            :aria-hidden="false"
            @hidden="closeModal"
        >
            <template #header>
                <div class="header-sidebar d-flex justify-content-between">
                    <span class="sidebar-title"> {{ $t("CalendarsPage.ConfigureCalendar") }} </span>

                    <span
                        class="close-icon"
                        @click="closeModal()"
                    >
                        <CloseIcon />
                    </span>
                </div>
                <div class="separator"></div>
            </template>
            <template>
                <b-col
                    md="12"
                    class="h-100"
                >
                    <Settings
                        :removeFooter="true"
                        @currentBody="(val) => (currentBody = val)"
                        @updateList="(val) => (hasCheckedResource = val)"
                        ref="settingsComponent"
                    />
                </b-col>
            </template>

            <template #footer>
                <div class="separator"></div>
                <b-col
                    md="12"
                    :class="[
                        'footer-sidebar',
                        (currentBody === 'events' && !calendarSettings.events.eventList.length) ||
                        (currentBody === 'resources' &&
                            !calendarSettings.resources.resourcesList.length)
                            ? '--no-events'
                            : ''
                    ]"
                >
                    <b-button
                        v-if="currentBody === 'general'"
                        class="btn-apply"
                        @click="onApply()"
                    >
                        <SaveIcon /> {{ $t("CalendarsPage.Apply") }}
                    </b-button>
                    <div
                        v-if="currentBody === 'events'"
                        class="footer-events"
                    >
                        <div class="year-block">
                            <CalendarIcon class="calendar-icon" />
                            <b-dropdown
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                class="years-dropdown"
                                ref="dropdownModal"
                                @shown="focusSelectedYear"
                            >
                                <template v-slot:button-content>
                                    {{ helpers.eventsFilter.selectedYear }}
                                </template>
                                <div
                                    class="custom-dropdown-menu"
                                    ref="dropdownMenu"
                                >
                                    <b-dropdown-item
                                        v-for="year in helpers.context.years"
                                        :key="year"
                                        @click="selectYear(year)"
                                        :class="{
                                            'selected-year':
                                                year === helpers.eventsFilter.selectedYear
                                        }"
                                    >
                                        {{ year }}
                                    </b-dropdown-item>
                                </div>
                            </b-dropdown>
                            <ArrowDownIcon
                                class="arrow-icon cursor-pointer"
                                @click="toggleDropdown"
                            />
                        </div>
                        <b-button
                            class="btn-new-event"
                            @click="newEvent()"
                        >
                            <AddIcon /> {{ $t("CalendarsPage.NewEvent") }}
                        </b-button>
                    </div>
                    <div
                        v-if="currentBody === 'resources'"
                        class="footer-resources"
                    >
                        <div
                            v-if="hasCheckedResource"
                            class="block-checked"
                        >
                            <b-button
                                class="btn-clear"
                                @click="clearCheckedResources()"
                            >
                                {{ $t("Clear") }}
                            </b-button>
                            <b-button
                                class="btn-action"
                                @click="onUnlinkResource()"
                            >
                                <UnLinkIcon /> {{ $t("StructurePage.UnlinkResource") }}
                            </b-button>
                        </div>
                        <b-button
                            v-else
                            class="btn-action"
                            @click="UPDATE_MODAL_RESOURCES_TOGGLE(true)"
                        >
                            <LinkIcon />
                            {{ $t("CalendarsPage.NewResource") }}
                        </b-button>
                    </div>
                </b-col>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import { BButton, BCol, BSidebar, BDropdown, BDropdownItem } from "bootstrap-vue";
    import Settings from "./index.vue";
    import {
        checkCompletedPeriods,
        createYearsContext
    } from "@/views/pages/calendar/actionCalendar/utils";
    import { saveCalendarService } from "@/views/pages/calendar/actionCalendar/services";

    export default {
        data() {
            return {
                isOpen: false,
                currentBody: "general",
                years: [2025],
                hasCheckedResource: false
            };
        },
        components: {
            Settings,
            BButton,
            BCol,
            BSidebar,
            BDropdown,
            BDropdownItem,
            AddIcon: () => import("@core/assets/icons/add-icon.svg"),
            CalendarIcon: () => import("@/assets/images/icons/calendar-icon.svg"),
            ArrowDownIcon: () => import("@core/assets/icons/arrow-down.svg"),
            SaveIcon: () => import("@core/assets/icons/save-icon.svg"),
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            UnLinkIcon: () => import("@core/assets/icons/resource-unlink.svg"),
            LinkIcon: () => import("@core/assets/icons/resource-link.svg")
        },
        props: {
            device: {
                type: Number,
                default: null
            }
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_SETTINGS_TOGGLE",
                "UPDATE_MODAL_RESOURCES_TOGGLE",
                "UPDATE_GENERAL_APPLIED",
                "UPDATE_CONTEXT_DAYS",
                "UPDATE_PERIODS_PERIOD_LIST",
                "UPDATE_MODAL_GENERAL_STATES",
                "UPDATE_MODAL_EVENTS_TOGGLE",
                "UPDATE_HELPERS_EVENTS_FILTER_SELECTED_YEAR"
            ]),
            closeModal() {
                this.UPDATE_MODAL_SETTINGS_TOGGLE(false);
            },
            validateFields() {
                const fields = { ...this.calendarSettings.general.fields };
                const states = { ...calendarInitialState().calendarSettings.general.states };

                const validations = {
                    name: () => {
                        if (!fields.name) {
                            states.name = {
                                valid: false,
                                feedback: "RequiredField"
                            };

                            return false;
                        }

                        states.name = {
                            valid: true,
                            feedback: ""
                        };

                        return true;
                    }
                };

                let isValid = true;

                for (const action of Object.values(validations)) {
                    const resultValidation = action?.();

                    if (isValid) {
                        isValid = resultValidation;
                    }
                }

                this.UPDATE_MODAL_GENERAL_STATES({ ...states });

                return isValid;
            },
            onApply() {
                const resultValidation = this.validateFields();

                if (!resultValidation) return;

                const payload = {
                    name: this.calendarSettings.general.fields.name,
                    referenceDate: this.calendarSettings.general.fields.referenceDate,
                    duration: this.calendarSettings.general.fields.duration
                };

                const filteredPeriodList = [
                    ...this.periods.periodList.filter(
                        (period) => period.day <= Number(payload.duration)
                    )
                ];
                this.UPDATE_PERIODS_PERIOD_LIST([...filteredPeriodList]);

                this.UPDATE_GENERAL_APPLIED({ ...payload });
                this.UPDATE_CONTEXT_DAYS(
                    payload.duration > 0
                        ? Array.from({ length: Number(payload.duration) }, (_, i) => ({
                              id: i + 1,
                              text: String(i + 1)
                          }))
                        : []
                );

                const isCompletedPeriods = checkCompletedPeriods(
                    filteredPeriodList,
                    Number(payload.duration)
                );

                this.UPDATE_PERIODS_IS_COMPLETED_PERIODS(isCompletedPeriods);

                this.closeModal();
                saveCalendarService(this);
            },
            newEvent() {
                this.UPDATE_MODAL_EVENTS_TOGGLE(true);
            },
            toggleDropdown() {
                const dropdown = this.$refs.dropdownModal;
                if (dropdown) {
                    dropdown.show();
                }
            },
            generateYears() {
                const startYear = 2000;
                const endYear = 2200;
                const years = [];

                for (let year = startYear; year <= endYear; year++) {
                    years.push(year);
                }

                return years;
            },
            selectYear(year) {
                this.UPDATE_HELPERS_EVENTS_FILTER_SELECTED_YEAR(year);
            },
            focusSelectedYear() {
                this.$nextTick(() => {
                    const menu = this.$refs.dropdownMenu;
                    const selectedItem = menu.querySelector(".selected-year");

                    if (selectedItem) {
                        selectedItem.scrollIntoView({
                            behavior: "auto",
                            block: "start"
                        });
                    }
                });
            },
            clearCheckedResources() {
                if (this.$refs.settingsComponent) {
                    this.$refs.settingsComponent.clearCheckedResources();
                    this.hasCheckedResource = false;
                }
            },
            onUnlinkResource() {
                if (this.$refs.settingsComponent) {
                    this.$refs.settingsComponent.onUnlinkResource();
                }
            }
        },
        computed: {
            ...mapState("calendar", {
                calendarSettings: "calendarSettings",
                isModalSettingsOpen: "isModalSettingsOpen",
                periods: "periods",
                helpers: "helpers"
            })
        },
        watch: {
            isModalSettingsOpen(v) {
                this.isOpen = v;
            },
            "calendarSettings.resources.resourcesList": {
                handler(newValue) {
                    this.hasCheckedResource = newValue?.some((resource) => !!resource?.checked);
                },
                deep: true,
                immediate: true
            },
            currentBody(v) {
                if (v !== "resources") this.UPDATE_MODAL_RESOURCES_TOGGLE(false);
            }
        }
    };
</script>

<style lang="scss">
    .sidebar-modal-settings {
        &.--role-openned {
            .b-sidebar.b-sidebar-right {
                right: 407px;
                transition: all 0.5s ease-out;
                @media (max-width: 814px) {
                    left: 0;
                    width: 50vw !important;
                }
                @media (max-width: 480px) {
                    left: 0;
                    width: 100vw !important;
                }
            }
        }
        &.--role-closed {
            .b-sidebar.b-sidebar-right {
                right: 0;
                transition: all 0.5s ease-out;

                @media (max-width: 480px) {
                    left: 0;
                    width: 100vw !important;
                }
            }
        }

        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                width: 100%;
                padding: 16px;

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;

                    @media (max-width: 480px) {
                        font-size: 16px;
                    }
                }

                .close-icon {
                    border: 1px solid #974900;
                    border-radius: 5px;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    svg {
                        width: 12px;
                        height: 12px;
                        path {
                            fill: #974900;
                        }
                    }

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }
            }
        }

        .b-sidebar {
            box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
            transition: all 0.5s ease-out;
            height: 100% !important;
            .b-sidebar-body {
                padding: 0;

                &:has(.--no-events) {
                    overflow-y: hidden;
                }

                .col-md-12 {
                    padding: 0;
                }

                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
        }

        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;

            .btn-apply {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                box-shadow: none;
                width: 100%;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.175px;
                padding: 8px 0;
                border: 1px solid #974900 !important;
                background-color: #974900 !important;
                color: #ffff !important;

                svg {
                    width: 14px;
                    height: 14px;
                    fill: #fff;
                }

                &:hover {
                    box-shadow: none;
                }

                @media (max-width: 480px) {
                    font-size: 12px;
                    padding: 3px 0;
                }
            }

            .footer-events {
                display: flex;
                justify-content: space-between;
                gap: 8px;
                width: 100%;

                .year-block {
                    display: flex;
                    align-items: center;
                    padding: 4px 10px;
                    border-radius: 5px;
                    border: 1px solid #974900;

                    .years-dropdown {
                        padding: 0 8px 0 6px;
                    }

                    .arrow-icon,
                    .calendar-icon {
                        width: 14px;
                        height: 14px;
                        fill: #974900;
                    }

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }

                    @media (max-width: 480px) {
                        font-size: 12px;
                        padding: 0 10px;

                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                .btn-new-event {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.175px;
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    padding: 8px 14px;

                    svg {
                        width: 14px;
                        height: 14px;
                        fill: #fff;
                    }

                    &:hover {
                        box-shadow: none;
                    }

                    @media (max-width: 480px) {
                        font-size: 12px;
                        padding: 3px 14px;

                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                .dropdown-toggle {
                    padding: 0;
                    color: #974900;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.175px;
                }

                .custom-dropdown-menu {
                    max-height: 200px;
                    overflow-y: auto;

                    .selected-year {
                        background-color: #974900;

                        .dropdown-item {
                            color: #fff;
                        }

                        .dropdown-item:hover,
                        .dropdown-item:focus {
                            color: #fff;
                        }

                        .dropdown-item:active {
                            background: #974900;
                        }
                    }

                    .dropdown-item:hover,
                    .dropdown-item:focus {
                        color: #974900;
                    }

                    .dropdown-item:active {
                        background: #ffdbc4;
                    }
                }
            }

            .footer-resources {
                display: flex;
                justify-content: space-between;
                gap: 8px;
                width: 100%;
                .block-checked {
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;
                    width: 100%;

                    .btn-clear {
                        border-radius: 5px;
                        border: 1px solid #974900;
                        width: 100%;
                        color: #974900 !important;
                        text-align: center;
                        max-width: 90px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.175px;
                        max-height: 38px;
                        background-color: #fff !important;
                        padding: 9px 21px;

                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #fff;
                        }

                        &:hover {
                            box-shadow: none;
                            background-color: #ffede2 !important;
                        }

                        &:active {
                            background-color: #ffdbc4 !important;
                        }

                        @media (max-width: 480px) {
                            max-height: 28px;
                            max-width: 69px;
                            padding: 2px 14px;
                            line-height: 20px;
                            letter-spacing: 0.15px;
                            font-size: 12px;
                        }
                    }
                }

                .btn-action {
                    width: 100%;
                    max-height: 38px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;
                    color: #fff;
                    font-weight: 600;
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.175px;
                    padding: 9px 21px;

                    svg {
                        width: 14px;
                        height: 14px;
                        fill: #fff;
                    }

                    &:hover {
                        box-shadow: none;
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #9f5714 !important;
                    }

                    @media (max-width: 480px) {
                        max-height: 28px;
                        padding: 2px 14px;
                        line-height: 20px;
                        letter-spacing: 0.15px;
                        font-size: 12px;

                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }

            &.--no-events {
                border: none;
                padding: 0;

                .footer-events,
                .footer-resources {
                    display: none;
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }
    }
</style>
