export function calculateMinutes(startTime, endTime) {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const startMinutes = startHour * 60 + startMinute;
    const endMinutes = endHour * 60 + endMinute;

    let timeDifference = endMinutes - startMinutes;
    if (timeDifference < 0) {
        timeDifference = 24 * 60 - startMinutes;
    }

    return timeDifference;
}
