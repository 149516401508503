<template>
    <div :class="['sys-radio__container', size && `normal-${size}`]">
        <div
            :class="[
                'sys-radio',
                {
                    checked: value
                }
            ]"
            @click="$emit('input', !value)"
        >
            <span class="sys-radio__icon"></span>
        </div>

        <label
            class="sys-radio__label"
            v-if="label"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                default: ""
            },
            value: {
                type: Boolean,
                default: true
            },
            size: {
                type: String,
                default: "md"
            }
        }
    };
</script>

<style lang="scss" scoped>
    .sys-radio__container {
        display: flex;
        align-items: center;
        gap: 5px;
        height: 100%;

        .sys-radio {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            background-color: #fff;
            border: 1px solid #974900;
            border-radius: 50%;
            cursor: pointer;

            .sys-radio__icon {
                border-radius: 50%;
                background-color: transparent;
            }

            &.checked {
                .sys-radio__icon {
                    background-color: #974900;
                }
            }
        }

        .sys-radio__label {
            color: #4c4541;
            margin: 0;
            white-space: nowrap;
        }

        &.normal-md {
            .sys-radio {
                min-width: 18px;
                max-width: 18px;
                min-height: 18px;
                max-height: 18px;
            }
            .sys-radio__icon {
                min-width: 12px;
                max-width: 12px;
                min-height: 12px;
                max-height: 12px;
            }

            .sys-radio__label {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
</style>
