<template>
    <div id="standard-calendar">
        <div
            class="h-100"
            v-if="calendarList.length === 0 && !noItemsFound"
        >
            <div class="no-items h-100">
                <EmptyBoxIcon class="empty-box" />
                <p class="title">{{ $t("CalendarsPage.NoCalendarsTitle") }}</p>
                <p class="subtitle">
                    {{ $t("CalendarsPage.NoCalendarsSubtitle") }}
                </p>
                <b-button
                    class="btn-new-calendar"
                    @click="permissions.find((e) => e.type === 'create') && newCalendar()"
                >
                    <AddIcon class="add-icon" />
                    {{ $t("CalendarsPage.NewCalendar") }}
                </b-button>
            </div>
        </div>
        <div
            v-else
            class="h-100"
        >
            <div class="header-block">
                <div class="first-line">
                    <div class="title">
                        {{ $t("Breadcrumbs.Calendars") }}
                    </div>
                    <div class="add-calendars">
                        <b-button
                            class="btn-new-calendar"
                            @click="permissions.find((e) => e.type === 'create') && newCalendar()"
                        >
                            <AddIcon class="add-icon" />
                            {{ $t("CalendarsPage.NewCalendar") }}
                        </b-button>
                    </div>
                </div>
                <div class="second-line">
                    <div class="search-input">
                        <div class="div-custom-input input__materials">
                            <div class="icon-block">
                                <SearchIcon class="search-icon" />
                            </div>
                            <b-form-tags
                                class="input-search-calendars"
                                ref="formTagMaterials"
                                v-model="filter.textFilter"
                                duplicate-tag-text=""
                                :placeholder="
                                    filter.textFilter.length === 0
                                        ? $t('SearchPlaceholderDefault')
                                        : ''
                                "
                                @input="getCalendars()"
                                remove-on-delete
                                add-on-change
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-block">
                <table-default
                    id="table-calendars-standard"
                    :rows="calendarList"
                    :fields="fields"
                    :stickyHeader="false"
                    :striped="true"
                    :searchFilter="false"
                    :filter="filter"
                    @updatePageNumber="updatePageNumber"
                    @updatepageSize="updatepageSize"
                >
                    <template v-slot:head(name)="data">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>
                                {{ $t(data.label) }}
                            </span>
                            <div class="d-flex flex-column sortable-icons align-items-end">
                                <ArrowDown
                                    @click="updateFieldAndSort(data.field.key, 'asc')"
                                    class="rotate"
                                    :style="
                                        filter.sort === 'asc' && filter.fieldSort === data.field.key
                                            ? 'fill: #998F8A'
                                            : 'opacity: 0.5'
                                    "
                                />
                                <ArrowDown
                                    @click="updateFieldAndSort(data.field.key, 'desc')"
                                    :style="
                                        filter.sort === 'desc' &&
                                        filter.fieldSort === data.field.key
                                            ? 'fill: #998F8A'
                                            : 'opacity: 0.5'
                                    "
                                />
                            </div>
                        </div>
                    </template>
                    <template v-slot:head(duration)="data">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>
                                {{ $t(data.label) }}
                            </span>
                            <div class="d-flex flex-column sortable-icons align-items-end">
                                <ArrowDown
                                    @click="updateFieldAndSort(data.field.key, 'asc')"
                                    class="rotate"
                                    :style="
                                        filter.sort === 'asc' && filter.fieldSort === data.field.key
                                            ? 'fill: #998F8A'
                                            : 'opacity: 0.5'
                                    "
                                />
                                <ArrowDown
                                    @click="updateFieldAndSort(data.field.key, 'desc')"
                                    :style="
                                        filter.sort === 'desc' &&
                                        filter.fieldSort === data.field.key
                                            ? 'fill: #998F8A'
                                            : 'opacity: 0.5'
                                    "
                                />
                            </div>
                        </div>
                    </template>
                    <template v-slot:head(referenceDate)="data">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>
                                {{ $t(data.label) }}
                            </span>
                            <div class="d-flex flex-column sortable-icons align-items-end">
                                <ArrowDown
                                    @click="updateFieldAndSort(data.field.key, 'asc')"
                                    class="rotate"
                                    :style="
                                        filter.sort === 'asc' && filter.fieldSort === data.field.key
                                            ? 'fill: #998F8A'
                                            : 'opacity: 0.5'
                                    "
                                />
                                <ArrowDown
                                    @click="updateFieldAndSort(data.field.key, 'desc')"
                                    :style="
                                        filter.sort === 'desc' &&
                                        filter.fieldSort === data.field.key
                                            ? 'fill: #998F8A'
                                            : 'opacity: 0.5'
                                    "
                                />
                            </div>
                        </div>
                    </template>
                    <template v-slot:head(resourcesCount)="data">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>
                                {{ $t(data.label) }}
                            </span>
                            <div class="d-flex flex-column sortable-icons align-items-end">
                                <ArrowDown
                                    @click="updateFieldAndSort(data.field.key, 'asc')"
                                    class="rotate"
                                    :style="
                                        filter.sort === 'asc' && filter.fieldSort === data.field.key
                                            ? 'fill: #998F8A'
                                            : 'opacity: 0.5'
                                    "
                                />
                                <ArrowDown
                                    @click="updateFieldAndSort(data.field.key, 'desc')"
                                    :style="
                                        filter.sort === 'desc' &&
                                        filter.fieldSort === data.field.key
                                            ? 'fill: #998F8A'
                                            : 'opacity: 0.5'
                                    "
                                />
                            </div>
                        </div>
                    </template>
                    <template v-slot:cell(action)="data">
                        <div class="action-block">
                            <OptionIcon
                                class="action-svg"
                                @click="getItem(data.item)"
                                :id="'action-' + data.index"
                                tabindex="0"
                            />
                            <Popover
                                :items="actionList"
                                :target="'action-' + data.index"
                                placement="bottomright"
                                custom-position="action-button"
                                triggers="focus"
                            />
                        </div>
                    </template>
                    <template v-slot:cell(duration)="data">
                        <div class="duration-block">
                            {{ ` ${$t(data.item.duration)} ${$t("ProductionList.Days")}` }}
                        </div>
                    </template>
                    <template v-slot:cell(referenceDate)="data">
                        <div class="duration-block">
                            {{ dateFormatter(data.item.referenceDate) }}
                        </div>
                    </template>
                </table-default>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import EmptyBoxIcon from "@core/assets/icons/empty-box-icon.svg";
    import AddIcon from "@core/assets/icons/add-icon.svg";
    import SearchIcon from "@core/assets/icons/search-icon.svg";
    import { BButton, BFormTags } from "bootstrap-vue";
    import TableDefault from "@/@core/components/table-default/TableDefault.vue";
    import Popover from "@core/layouts/layout-horizontal/components/Menu/Popover.vue";
    import OptionIcon from "@core/assets/icons/option-icon.svg";
    import ArrowDown from "@core/assets/icons/arrow-down.svg";
    import EditIcon from "@core/assets/icons/edit-icon.svg";
    import DeleteIcon from "@core/assets/icons/delete-icon.svg";
    import moment from "moment";
    import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

    export default {
        components: {
            EmptyBoxIcon,
            AddIcon,
            SearchIcon,
            BButton,
            BFormTags,
            TableDefault,
            Popover,
            OptionIcon,
            ArrowDown,
            EditIcon,
            DeleteIcon,
            ToastificationContent
        },
        data() {
            return {
                currentItem: null,
                calendarList: [],
                noItemsFound: true,
                filter: {
                    pageNumber: 1,
                    pageSize: 10,
                    totalItems: 0,
                    sort: "",
                    fieldSort: "",
                    textFilter: []
                },
                fields: [
                    {
                        label: "IntegrationPage.Actions",
                        key: "action",
                        sortable: false,
                        thClass: "col-action-type th",
                        tdClass: "td"
                    },
                    {
                        label: "ResourcesList.Name",
                        key: "name",
                        sortable: false,
                        thClass: "col-name th",
                        tdClass: "td"
                    },
                    {
                        label: "CalendarsPage.Duration",
                        key: "duration",
                        sortable: false,
                        thClass: "col-duration th",
                        tdClass: "td"
                    },
                    {
                        label: "CalendarsPage.referenceDate",
                        key: "referenceDate",
                        sortable: false,
                        thClass: "col-ref-date th",
                        tdClass: "td"
                    },
                    {
                        label: "CalendarsPage.ResourcesQuantity",
                        key: "resourcesCount",
                        sortable: false,
                        thClass: "col-resources-quantity th",
                        tdClass: "td"
                    }
                ],
                actionList: [
                    {
                        title: "ResourcePage.Edit",
                        icon: EditIcon,
                        click: () => {
                            this.permissions.find((e) => e.type === "update") &&
                                this.$router.push({
                                    name: "editCalendar",
                                    params: {
                                        id: this.currentItem.id
                                    }
                                });
                        }
                    },
                    {
                        title: "ResourcePage.Delete",
                        icon: DeleteIcon,
                        click: () => {
                            this.permissions.find((e) => e.type === "delete") &&
                                this.deleteCalendar();
                        }
                    }
                ]
            };
        },
        async mounted() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            await this.getContext();
            await this.getCalendars();
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_CALENDAR_ID",
                "UPDATE_PERMISSIONS_LIST",
                "UPDATE_EXCEPTION_EVENT_TYPES_TOGGLE",
                "UPDATE_DURATION_UNITS_TOGGLE",
                "UPDATE_STATUS_TOGGLE"
            ]),
            activeCarousel() {
                const vm = this;
                $(function () {
                    document
                        .getElementsByClassName("input-search-calendars")[0]
                        .setAttribute("tabIndex", "-1");

                    const slider = document.querySelector(".b-form-tags-list");
                    let mouseDown = false;
                    let startX, scrollLeft;

                    let startDragging = function (e) {
                        mouseDown = true;
                        startX = e.pageX - slider.offsetLeft;
                        scrollLeft = slider.scrollLeft;
                    };
                    let stopDragging = function () {
                        mouseDown = false;
                    };

                    slider.addEventListener("mousemove", (e) => {
                        e.preventDefault();
                        if (!mouseDown) {
                            return;
                        }
                        const x = e.pageX - slider.offsetLeft;
                        const scroll = x - startX;
                        slider.scrollLeft = scrollLeft - scroll;
                    });

                    slider.addEventListener("mousedown", startDragging, false);
                    slider.addEventListener("mouseup", stopDragging, false);
                    slider.addEventListener("mouseleave", stopDragging, false);

                    $(".b-form-tags-input").blur((event) => {
                        let input = vm.$refs.formTagMaterials;
                        input.addTag(event.target.value);
                    });
                });
            },
            getItem(item) {
                this.currentItem = item;
                this.UPDATE_CALENDAR_ID(item.id);
            },
            dateFormatter(value) {
                return moment(value).format("DD/MM/YYYY HH:mm");
            },
            newCalendar() {
                this.$router.push({ name: "newCalendar" });
                this.UPDATE_CALENDAR_ID(null);
            },
            async getContext() {
                const currentLanguage = this.$cookies.get("userInfo").language;
                await this.$http2
                    .get(`/api/myconfig/calendar/context?lang=${currentLanguage}`)
                    .then((response) => {
                        const eventTypes = response.data.data.exceptionEventTypes;
                        const durationUnits = response.data.data.durationUnits;
                        const statusList = response.data.data.status.map((e) => ({
                            ...e,
                            text: e.name
                        }));
                        const permissionsList = response.data.data.permissions;
                        this.UPDATE_PERMISSIONS_LIST(permissionsList);
                        this.UPDATE_EXCEPTION_EVENT_TYPES_TOGGLE(eventTypes);
                        this.UPDATE_DURATION_UNITS_TOGGLE(durationUnits);
                        this.UPDATE_STATUS_TOGGLE(statusList);
                    });
            },
            async getCalendars() {
                const textFilterQuery = this.filter.textFilter;
                if (!this.permissions.find((e) => e.type === "show")) {
                    this.noItemsFound = !!textFilterQuery.length;
                    return;
                }
                await this.$http2
                    .get(
                        `/api/myconfig/calendar?TextFilter=${textFilterQuery}&PageSize=${this.filter.pageSize}&PageNumber=${this.filter.pageNumber}&PageOrder=${this.filter.sort}&PageOrderField=${this.filter.fieldSort}`
                    )
                    .then((response) => {
                        this.noItemsFound = !!textFilterQuery.length;
                        this.calendarList = response.data.data.calendars;
                        this.filter.totalItems = response.data.data.totalItems;
                        this.activeCarousel();
                    });
            },
            updatePageNumber() {},
            updatepageSize() {},
            updateFieldAndSort(e, text) {
                this.filter.sort = this.filter.sort = text;
                this.filter.fieldSort = e;
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            },
            deleteCalendar() {
                this.$swal({
                    title: this.$t("IntegrationPage.attention"),
                    html: `<div>${this.$t("CalendarsPage.DeleteMessage1")} <b>${this.currentItem.name}</b>. ${this.$t("CalendarsPage.DeleteMessage2")}</div>`,
                    showCancelButton: true,
                    showCloseButton: true,
                    cancelButtonText: this.$t("IntegrationPage.cancel"),
                    confirmButtonText: this.$t("IntegrationPage.delete"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.value) {
                        this.callDeleteRoute();
                    }
                });
            },
            async callDeleteRoute() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                await this.$http2
                    .delete(`/api/myconfig/calendar/${this.currentItem.id}`)
                    .then(() => {
                        this.showToast(
                            this.$t("IntegrationPage.success"),
                            "SmileIcon",
                            this.$t("CalendarsPage.CalendarDeleted"),
                            "success"
                        );
                        this.getCalendars();
                    })
                    .catch((error) => {
                        this.showToast(
                            this.$t("Error"),
                            "FrownIcon",
                            error.response.data.message,
                            "danger"
                        );
                    })
                    .finally(() => {
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            }
        },
        computed: {
            ...mapState("calendar", {
                permissions: "permissions"
            })
        },
        watch: {
            "filter.pageNumber"() {
                this.getCalendars();
            },
            "filter.pageSize"() {
                this.getCalendars();
            },
            "filter.sort"() {
                this.getCalendars();
            }
        }
    };
</script>

<style lang="scss">
    #standard-calendar {
        padding: 16px 0;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

        @media (max-width: 1024px) {
            margin-top: 24px;
        }

        &:has(.no-items) {
            height: calc(100vh - 136px);
        }

        &:has(.body-block .block-no-results) {
            height: calc(100vh - 135px);
        }

        &:has(.body-block .no-items) {
            height: calc(100vh - 135px);
        }

        .no-items {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            margin: 0 16px;
            border: 1px solid #cfc4be;

            .empty-box {
                width: 80px;
                height: 80px;
            }

            .title {
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                color: #4c4541;
                margin: 0;
            }

            .subtitle {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #998f8a;
                padding: 4px 0 12px;
                margin: 0;
            }

            .btn-new-calendar {
                border-color: #974900 !important;
                background-color: #974900 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                padding: 8px 21px;
                line-height: 20px;
                letter-spacing: 0.175px;

                .add-icon {
                    width: 14px;
                    height: 14px;
                    fill: #fff;
                }
            }

            @media (max-width: 480px) {
                .empty-box {
                    width: 40px;
                    height: 40px;
                }

                .title {
                    font-size: 14px;
                }

                .subtitle {
                    font-size: 12px;
                    text-align: center;
                    padding: 4px 48px 12px;
                    line-height: 16px;
                }

                .btn-new-calendar {
                    padding: 3px 14px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.15px;

                    .add-icon {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }

        .header-block {
            padding: 0 16px;
            .first-line {
                display: flex;
                justify-content: space-between;
                gap: 16px;

                .title {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                }
                .add-calendars {
                    display: flex;
                    gap: 16px;

                    .btn-new-calendar {
                        border-color: #974900 !important;
                        background-color: #974900 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        width: 100% !important;
                        min-width: 136px;
                        padding: 3px 14px !important;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.0125em;

                        .add-icon {
                            width: 12px;
                            height: 12px;
                            fill: #fff;
                        }
                    }
                }

                @media (max-width: 480px) {
                    flex-direction: column;
                }
            }
            .second-line {
                .search-input {
                    display: flex;
                    align-items: center;
                    padding: 16px 0 8px;

                    .div-custom-input {
                        display: flex;
                        align-items: center;
                        border-radius: 4px;
                        width: 100%;
                        overflow: hidden;
                        border: 1px solid #cfc4be;
                        .icon-block {
                            display: flex;
                            align-items: center;
                            padding-left: 14px;
                            height: 14px;
                            .search-icon {
                                width: 14px;
                                height: 14px;
                                fill: #cfc4be;
                            }
                        }
                        .input-search-calendars {
                            width: 100% !important;
                            border: none;
                            overflow: hidden;

                            &.focus {
                                box-shadow: none;
                            }
                            &.b-form-tags {
                                padding: 0 !important;
                                display: flex;
                                align-items: center !important;
                                .b-form-tags-list {
                                    width: 100% !important;
                                    margin-left: 14px !important;
                                    margin-top: 0;
                                    height: 100% !important;
                                    flex-wrap: nowrap !important;
                                    display: flex !important;
                                    align-items: center !important;
                                    overflow: scroll;
                                    overflow-y: hidden;
                                    cursor: grabbing;
                                    cursor: -webkit-grabbing;

                                    &::-webkit-scrollbar {
                                        display: none !important;
                                    }
                                    .b-form-tags-field {
                                        margin: 9px 0;
                                    }
                                }
                            }

                            .b-form-tags-button {
                                display: none;
                            }

                            .b-form-tag {
                                background-color: #974900;
                                margin-right: 0.6rem;
                                padding: 2px 8px !important;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }

        .body-block {
            padding: 0 16px;
            margin: 0 12px;
            overflow-x: auto;

            .col-action-type {
                min-width: 57px !important;
            }

            table {
                border-radius: 6px;
                border: 1px solid #e0e0e0;
                margin: 0 1px !important;
            }

            .th {
                padding: 11px 5px 11px 7px;
                color: #4c4541;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                vertical-align: middle;

                .sortable-icons {
                    min-width: 20%;
                    .rotate {
                        transform: rotate(180deg);
                    }
                }

                svg {
                    cursor: pointer;
                    width: 12px;
                    height: 12px;
                    fill: #998f8a;
                }
            }

            .td {
                padding: 14.5px 10px;
            }

            tr {
                &:last-child {
                    border-bottom: 1px solid #e0e0e0;
                }
            }

            .col-name {
                width: 25%;
                min-width: 180px !important;
            }

            .col-duration {
                min-width: 120px !important;
            }
            .col-ref-date {
                min-width: 150px !important;
            }
            .col-resources-quantity {
                min-width: 135px !important;
            }

            .action-block {
                display: flex;
                position: relative;
                .action-svg {
                    fill: #974900;
                    width: 28px;
                    height: 28px;
                    cursor: pointer;

                    &:hover {
                        background-color: #ffede2;
                        border-radius: 5px;
                    }

                    &:active {
                        background-color: #ffdbc4;
                        border-radius: 5px;
                    }

                    &:focus-within,
                    &:focus {
                        background-color: #ffdbc4;
                        border-radius: 5px;
                        border: none;
                        outline: none;
                    }
                }
            }

            &::-webkit-scrollbar {
                height: 3px;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #cfc4be;
                border-radius: 9px;
            }
        }
    }
</style>
