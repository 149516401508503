<template>
    <div>
        <b-sidebar
            id="calendar-resources-options-sidebar-right"
            class="calendar-sidebar-options-resource-modal"
            right
            bg-variant="white"
            width="407px"
            v-model="isOpen"
            @hidden="closeModal"
        >
            <template #header>
                <div class="header-sidebar d-flex justify-content-end">
                    <span
                        v-if="device !== 'mobile'"
                        class="close-icon"
                        @click="closeModal()"
                    >
                        <CloseIcon />
                    </span>
                </div>

                <div class="separator"></div>
            </template>
            <template>
                <div class="option-modal-body">
                    <div class="unlink-block">
                        <div class="unlink-collapse not-collapsed">
                            {{ $t("StructurePage.UnlinkResources") }}
                        </div>
                        <div id="unlink-collapse">
                            <div class="text">
                                {{ $t("MaterialsPage.UnlinkResourceText") }}
                            </div>
                            <b-button
                                @click="onUnlinkResource()"
                                class="unlink-button"
                                >{{ $t("IntegrationPage.Unlink") }}</b-button
                            >
                        </div>
                    </div>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { BSidebar, BButton, BCollapse, BFormInput, BCol } from "bootstrap-vue";
    import { mapState, mapMutations } from "vuex";

    export default {
        components: {
            BSidebar,
            BCollapse,
            BFormInput,
            BButton,
            BCol,
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            ArrowRight: () => import("@core/assets/icons/arrow-right.svg"),
            SearchIcon: () => import("@core/assets/icons/search-icon.svg")
        },
        props: {
            device: {
                type: String,
                default: "desktop"
            },
            isToUnlink: {
                type: Boolean,
                default: false
            },
            currentId: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                isOpen: false,
                isUnlinkVisible: false
            };
        },
        mounted() {
            this.UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("calendar", [
                "UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE",
                "UPDATE_RESOURCES_LIST"
            ]),
            closeModal() {
                this.UPDATE_MODAL_OPTIONS_RESOURCE_TOGGLE(false);
                this.$emit("close");
            },
            openUnlinkBlock() {
                this.isUnlinkVisible = !this.isUnlinkVisible;
            },
            onUnlinkResource() {
                this.$emit("onUnlinkResource");
            }
        },
        computed: {
            ...mapState("calendar", {
                isModalResourceOptions: "isModalResourceOptions",
                calendarSettings: "calendarSettings"
            })
        },
        watch: {
            isModalResourceOptions(v) {
                this.isOpen = v;
                this.isUnlinkVisible = this.isToUnlink;
            }
        }
    };
</script>
<style lang="scss">
    .calendar-sidebar-options-resource-modal {
        .b-sidebar.b-sidebar-right {
            transition: all 0.5s ease-out;
            border-left: 1px solid #cfc4be;
            @media (max-width: 814px) {
                width: 50vw !important;
            }
            @media (max-width: 480px) {
                width: 100vw !important;
            }
        }

        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                gap: 8px;
                padding: 16px;

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;

                    @media (max-width: 480px) {
                        font-size: 16px;
                    }
                }
            }

            .close-icon {
                border: 1px solid #974900;
                border-radius: 5px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    width: 12px;
                    height: 12px;
                    path {
                        fill: #974900;
                    }
                }

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }

        .b-sidebar {
            height: 100% !important;
            .b-sidebar-body {
                padding-bottom: 16px;
                overflow: hidden;

                .option-modal-body {
                    height: calc(100% - 70px);

                    .unlink-block {
                        padding: 16px;
                        .unlink-collapse {
                            cursor: pointer;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            color: #4c4541;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 26px;

                            svg {
                                width: 16px;
                                height: 16px;
                                fill: #974900;
                            }

                            &.not-collapsed {
                                svg {
                                    transform: rotate(90deg);
                                    transition: all 0.3s;
                                }
                            }

                            &.collapsed {
                                svg {
                                    transform: rotate(0);
                                    transition: all 0.3s;
                                }
                            }
                        }

                        .text {
                            color: #998f8a;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: normal;
                            padding: 8px 0 16px;
                        }

                        .unlink-button {
                            width: 100%;
                            border-color: #d32f2f !important;
                            background-color: #d32f2f !important;
                            padding: 8px 21px !important;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.175px;
                        }
                    }

                    .department-block {
                        padding-top: 8px;
                        height: calc(100vh - 370px);

                        &:has(:not(.block-no-results)) {
                            overflow: auto;
                            &::-webkit-scrollbar {
                                width: 7px;
                            }

                            &::-webkit-scrollbar-track {
                                margin: 7px 0;
                                background: #fff;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: #cfc4be;
                                border-radius: 9px;
                            }
                        }

                        .block-no-results {
                            padding: 8px 0 16px;
                            height: 100%;
                            .no-results {
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 16px;
                                border-radius: 6px;
                                border: 1px solid #cfc4be;

                                svg {
                                    width: 16px;
                                    height: 16px;
                                    fill: #998f8a;
                                }

                                .text {
                                    margin: 0;
                                    color: #998f8a;
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 26px;
                                }
                            }
                        }

                        .item {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            padding: 8px 16px;
                            border-bottom: 1px solid #eee;
                            color: #4c4541;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;

                            input {
                                cursor: pointer;
                                width: 18px;
                                height: 18px;
                                accent-color: #974900;
                            }
                        }
                    }
                }

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    background: #fff;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #cfc4be;
                    border-radius: 9px;
                }
            }

            .footer-sidebar {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 16px;
                border-top: 1px solid #cfc4be;
                background: #fff;

                .btn-footer {
                    box-shadow: none;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    border-radius: 5px;

                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }
    }
</style>
