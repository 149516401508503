import vue from "vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

const vm = vue.prototype;

function setToast({ title, icon, text, variant }) {
    vm.$toast({
        component: ToastificationContent,
        props: {
            title: title,
            icon: icon,
            text: text,
            variant
        }
    });
}

function useToast() {
    return {
        setToast
    };
}

export { useToast };
